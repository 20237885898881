import {
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    Modal,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
    bodegaType,
    DimLineaType,
    SateliteProveeduriaResponsableType,
    VSateliteUsuarioType,
} from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "@emotion/styled";



const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const ResponsablesProveduriaPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getListaResponsables();
            getBodegas();
            getUsuarios();

        } else {
            setName("");
        }
    }, [account]);
    const [listaResponsables, setListaResponsables] = useState<SateliteProveeduriaResponsableType[]>([]);
    const [bodegas, setBodegas] = useState<bodegaType[]>([]);
    const [usuarios, setUsuarios] = useState<VSateliteUsuarioType[]>([]);
    const [lineas, setLineas] = useState<DimLineaType[]>([{ id: 1, linea: "ADMINISTRATIVO" }, { id: 2, linea: "COMERCIAL" }, { id: 3, linea: "LATONERIA" }, { id: 4, linea: "REPUESTOS" }, { id: 5, linea: "TALLER" }, { id: 9, linea: "TODAS" }]);
    const [linea, setLinea] = React.useState(0);
    const [esNuevo, setEsNuevo] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [bodega, setBodega] = useState<bodegaType>({
        bodId: 0,
        agencia: "",
        descripcion: "",
        agency: "",
    });
    const [usuario, setUsuario] = useState<VSateliteUsuarioType>({
        id: 0,
        nombre: "",
        correo: "",
        idMarca: 0,
        idBodega: 0,
        usuSupervisor: 0,
        usuJefeAgencia: 0,
        agenciaCrm: "",
        marca: "",
        bodega: "",
    });
    const [responsable, setResponsable] = useState<SateliteProveeduriaResponsableType>({
        prrId: 0,
        prrNombre: "",
        prrCorreo: "",
        prrNotifica: "",
        idBodega: 0,
        prrLinea: "",
        prrPresupuestoMensual: 0,
        prrObservaciones: "",
        prrDiaHasta: 27,
        prrDiaEntrega: 10
    });

    const handleChangeResponsable = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value)
        setResponsable({
            ...responsable,
            [e.target.name]: e.target.value,
        });

    };

    function seteoResponsable(
        prrId: number,
        prrNombre: string,
        prrCorreo: string,
        prrNotifica: string,
        idBodega: number,
        prrLinea: string,
        prrPresupuestoMensual: number,
        prrObservaciones: string,
        prrDiaHasta: number,
        prrDiaEntrega: number
    ) {

        if (esNuevo) {
            const nuevoRegistro: SateliteProveeduriaResponsableType = {
                prrNombre: prrNombre,
                prrCorreo: prrCorreo,
                prrNotifica: prrNotifica,
                idBodega: idBodega,
                prrLinea: prrLinea,
                prrPresupuestoMensual: prrPresupuestoMensual,
                prrObservaciones: prrObservaciones,
                prrDiaHasta: prrDiaHasta,
                prrDiaEntrega: prrDiaEntrega
            };
            console.log(
                "nuevo a guardar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        } else {
            const nuevoRegistro: SateliteProveeduriaResponsableType = {
                prrId: prrId,
                prrNombre: prrNombre,
                prrCorreo: prrCorreo,
                prrNotifica: prrNotifica,
                idBodega: idBodega,
                prrLinea: prrLinea,
                prrPresupuestoMensual: prrPresupuestoMensual,
                prrObservaciones: prrObservaciones,
                prrDiaHasta: prrDiaHasta,
                prrDiaEntrega: prrDiaEntrega
            };
            console.log(
                "vamos a actualizar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        }


    }

    async function postGuardarRegistro(registro: SateliteProveeduriaResponsableType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/sateliteproveeduriaresponsable", registro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("id guardado es >> ", response);
                    getListaResponsables()

                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getResponsableEdit(_id: number) {
        try {
            await axios
                .get<SateliteProveeduriaResponsableType>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriaresponsable/" +
                    String(_id)
                )
                .then((response) => {
                    console.log("mi ruta seleccionada es >>", response.data);
                    setResponsable(response.data);
                    setOpenDialog(true);
                    setEsNuevo(false);
                    getBodega(response.data.idBodega);
                    getUsuarioCorreo(response.data.prrCorreo);
                    if (response.data.prrLinea.toUpperCase().includes("ADMIN")) {
                        setLinea(1)
                    }
                    if (response.data.prrLinea.toUpperCase().includes("COMERCIAL")) {
                        setLinea(2)
                    }
                    if (response.data.prrLinea.toUpperCase().includes("LATONERIA")) {
                        setLinea(3)
                    }
                    if (response.data.prrLinea.toUpperCase().includes("REPUESTO")) {
                        setLinea(4)
                    }
                    if (response.data.prrLinea.toUpperCase().includes("TALLER")) {
                        setLinea(5)
                    }
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListaResponsables() {
        try {
            await axios
                .get<SateliteProveeduriaResponsableType[]>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriaresponsable"
                )
                .then((response) => {
                    console.log("mi listado es >>", response.data);
                    setListaResponsables(response.data);
                    setOpenDialog(false);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getUsuario(id: number) {
        try {
            await axios
                .get<VSateliteUsuarioType>(
                    "https://apisatelite.azurewebsites.net/vsateliteusuario/" +
                    String(id)
                )
                .then((response) => {
                    console.log("mi user es >>>>>>", response.data);
                    setUsuario(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(usuario, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getUsuarioCorreo(correo: string) {
        try {
            await axios
                .get<VSateliteUsuarioType>(
                    "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" + correo
                )
                .then((response) => {
                    console.log("mi user es >>>>>>", response.data);
                    setUsuario(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(usuario, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getUsuarios() {
        try {
            await axios
                .get<VSateliteUsuarioType[]>(
                    "https://apisatelite.azurewebsites.net/vsateliteusuario"
                )
                .then((response) => {
                    setUsuarios(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getBodegas() {
        try {
            await axios
                .get<bodegaType[]>(
                    "https://apisatelite.azurewebsites.net/dimbodega"
                )
                .then((response) => {
                    setBodegas(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getBodega(id: number) {
        try {
            await axios
                .get<bodegaType>(
                    "https://apisatelite.azurewebsites.net/dimbodega/" + String(id)
                )
                .then((response) => {
                    setBodega(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const columns: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "prrNombre",
            headerName: "Solicitante",
            editable: false,
            width: 220,
        },
        {
            headerClassName: "theme-header",
            field: "prrNotifica",
            headerName: "Aprobador",
            editable: false,
            width: 220,
        },
        {
            headerClassName: "theme-header",
            field: "prrLinea",
            headerName: "Area / Línea",
            editable: false,
            width: 220,
        },
        {
            headerClassName: "theme-header",
            field: "prrPresupuestoMensual",
            headerName: "Presupuesto Mensual",
            editable: false,
            width: 125,
            align: "right",
            valueGetter: ({ row }) => {
                return formatter.format(Number(row.prrPresupuestoMensual)).replace("$", "$  ");
            },
        },
        {
            headerClassName: "theme-header",
            field: "prrObservaciones",
            headerName: "Observaciones",
            editable: false,
            width: 225,
        },
        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Detalle",
            sortable: false,
            width: 100,
            renderCell: (params) => {
                //{params.row.Id}
                return (
                    <Button
                        onClick={function (event) {
                            getResponsableEdit(params.row.prrId);
                        }}
                    >
                        Abrir
                    </Button>
                );
            },
        },
    ];

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Solicitantes de proveeduria
                            </Typography>
                        </Paper>

                        {/* insertar validacion para mostrar */}
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                                mb: 4,
                            }}
                        >

                            <>
                                <Box
                                    sx={{
                                        "& .theme-header": {
                                            backgroundColor: "rgba(21,57,105,0.75)",
                                            color: "rgba(255,255,255,1)",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.prrId}
                                        sx={{ height: 650 }}
                                        editMode="row"
                                        rows={listaResponsables}
                                        columns={columns}
                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector
                                        disableRowSelectionOnClick
                                        slots={{ toolbar: GridToolbarQuickFilter }}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                            },
                                            pagination: {
                                                labelRowsPerPage: "Registros por página:",
                                            },
                                        }}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[10, 25, 50]}
                                        localeText={{
                                            footerTotalRows: false,
                                            noRowsLabel: "No se ha encontrado datos.",
                                            noResultsOverlayLabel:
                                                "No se ha encontrado ningún resultado",
                                            toolbarColumns: "Columnas",
                                            toolbarColumnsLabel: "Seleccionar columnas",
                                            toolbarFilters: "Filtros",
                                            toolbarFiltersLabel: "Ver filtros",
                                            toolbarFiltersTooltipHide: "Quitar filtros",
                                            toolbarFiltersTooltipShow: "Ver filtros",
                                            toolbarQuickFilterPlaceholder: "Buscar",
                                        }}
                                    />
                                </Box>
                                <Button variant="contained" sx={{ ml: "80%", width: "12%", mt: 2 }} onClick={(e) => {
                                    setEsNuevo(true); setOpenDialog(true); setResponsable({
                                        ...responsable,
                                        prrId: 0,
                                        prrNombre: "",
                                        prrCorreo: "",
                                        prrNotifica: username,
                                        idBodega: 0,
                                        prrLinea: "",
                                        prrPresupuestoMensual: 0,
                                        prrObservaciones: "",
                                    });

                                    setUsuario({
                                        ...usuario,
                                        id: 0,
                                        nombre: "",
                                        correo: "",
                                        idMarca: 0,
                                        idBodega: 0,
                                        usuSupervisor: 0,
                                        usuJefeAgencia: 0,
                                        agenciaCrm: "",
                                        marca: "",
                                        bodega: "",
                                    });

                                    setBodega({
                                        ...bodega,
                                        bodId: 0,
                                        agencia: "",
                                        descripcion: "",
                                        agency: "",
                                    });

                                    setLinea(0);
                                }}>Nuevo</Button>
                            </>
                        </Paper>

                        {/* modal */}
                        <Modal
                            open={openDialog}
                            onClose={function (event) {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleModal}>
                                {/* Datos */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Solicitante:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    value={usuario.id}
                                    onChange={(e) => getUsuario(Number(e.target.value))}
                                    size="small"
                                    margin="normal"
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {usuarios.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Aprobador:
                                </Typography>
                                <TextField
                                    name="prrNotifica"
                                    value={responsable.prrNotifica}
                                    onChange={handleChangeResponsable}
                                    size="small"
                                    margin="normal"
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Agencia:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    value={bodega.bodId}
                                    onChange={(e) => getBodega(Number(e.target.value))}
                                    size="small"
                                    margin="normal"
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {bodegas.map((option) => (
                                        <MenuItem key={option.bodId} value={option.bodId}>
                                            {option.agencia}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Area/Línea:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    value={linea}
                                    onChange={(e) => setLinea(Number(e.target.value))}
                                    size="small"
                                    margin="normal"
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                >
                                    {lineas.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.linea}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "50%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Presupuesto mensual:
                                </Typography>
                                <TextField
                                    name="prrPresupuestoMensual"
                                    size="small"
                                    margin="normal"
                                    type="number"
                                    value={responsable.prrPresupuestoMensual}
                                    onChange={handleChangeResponsable}
                                    sx={{ mt: 1, mb: 1, width: "50%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "50%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    {"Permitido subir hasta (día):"}
                                </Typography>
                                <TextField
                                    name="prrDiaHasta"
                                    size="small"
                                    margin="normal"
                                    value={responsable.prrDiaHasta}
                                    type="number"
                                    onChange={handleChangeResponsable}
                                    sx={{ mt: 1, mb: 1, width: "50%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "50%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    {"Pedido se entrega hasta (día):"}
                                </Typography>
                                <TextField
                                    name="prrDiaEntrega"
                                    size="small"
                                    margin="normal"
                                    value={responsable.prrDiaEntrega}
                                    onChange={handleChangeResponsable}
                                    sx={{ mt: 1, mb: 1, width: "50%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Observaciones:
                                </Typography>
                                <TextField
                                    name="prrObservaciones"
                                    size="small"
                                    margin="normal"
                                    value={responsable.prrObservaciones}
                                    onChange={handleChangeResponsable}
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 1,
                                        marginLeft: "40%",
                                        width: "20%",
                                        backgroundColor: "rgba(21,57,105,0.75)",
                                    }}
                                    disabled={responsable.prrDiaEntrega > 0 && responsable.prrDiaEntrega < 31 && responsable.prrDiaHasta > 0 && responsable.prrDiaHasta < 31 && linea > 0 && bodega.bodId > 0 && usuario.id > 0 && responsable.prrPresupuestoMensual > 0 ? false : true}
                                    onClick={function (event) {
                                        seteoResponsable(Number(responsable.prrId), usuario.nombre, usuario.correo, responsable.prrNotifica, bodega.bodId, linea === 1 ? "Administrativo - " + bodega.agencia : linea === 2 ? "Vehículos - " + bodega.agencia : linea === 3 ? "Latoneria - " + bodega.agencia : "Repuestos - " + bodega.agencia, Number(responsable.prrPresupuestoMensual), responsable.prrObservaciones, responsable.prrDiaHasta, responsable.prrDiaEntrega)
                                    }}
                                >
                                    Guardar
                                </Button>

                            </Box>
                        </Modal>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
