import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    SateliteTestDrive,
    VSateliteUsuarioType,
} from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

export const ListaPendientesTestPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const [mostrar, setMostrar] = React.useState<boolean>(false);
    const listaListado: SateliteTestDrive[] = [];
    const [dataLista, setDataLista] = useState<SateliteTestDrive[]>(listaListado);
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getUsuario(account.username);

            // Verifica el tipo de dispositivo al cargar la página
            checkDeviceType();
        } else {
            setName("");
        }
    }, [account]);

    const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
        id: 0,
        nombre: "",
        correo: "",
        idMarca: 0,
        idBodega: 0,
        usuSupervisor: 0,
        usuJefeAgencia: 0,
        agenciaCrm: "",
        marca: "",
        bodega: "",
    });


    async function getUsuario(correo: string) {
        try {
            await axios
                .get<VSateliteUsuarioType>(
                    "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
                    correo
                )
                .then((response) => {
                    console.log("mi user es >>>>>>", response.data);
                    getListado(String(response.data.idBodega));
                    setSateliteUsuario(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(sateliteUsuario, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListado(_idbodega: string) {
        try {
            await axios
                .get<SateliteTestDrive[]>(
                     "https://apisatelite.azurewebsites.net/testdrive/todo/" + _idbodega + "/0"
                   //  "https://apisatelite.azurewebsites.net/testdrive/todo/1181/0"
                )
                .then((response) => {
                    setDataLista(response.data);
                    setMostrar(true);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataLista, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const columns: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "idCrm",
            headerName: "ID CRM",
            editable: false,
            width: 125,
        },
        {
            headerClassName: "theme-header",
            field: "stdFecha",
            headerName: "Fecha",
            editable: false,
            width: 125,
            valueGetter: ({ row }) => {
                return String(row.stdFecha).substring(0, 10);
            },
        },
        {
            headerClassName: "theme-header",
            field: "stdVinPlaca",
            headerName: "Vehículo",
            editable: false,
            width: 400,
        },
        {
            headerClassName: "theme-header",
            field: "stdUsuNombreResponsable",
            headerName: "Responsable",
            editable: false,
            width: 300,
        },        
        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Detalle",
            sortable: false,
            width: 125,
            renderCell: (params) => {
                //{params.row.Id}
                return (
                    <Button
                        href={`/testdrive/entrada/${params.row.stdId}`}
                    >
                        Abrir{" "}
                    </Button>
                );
            },
        },
    ];

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Test drive pendientes de entrada
                            </Typography>
                        </Paper>

                        {/* insertar validacion para mostrar */}
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                                mb: 4,
                            }}
                        >
                            {mostrar ? (
                                <>
                                    <Box
                                        sx={{
                                            "& .theme-header": {
                                                backgroundColor: "rgba(21,57,105,0.75)",
                                                color: "rgba(255,255,255,1)",
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            getRowId={(row) => row.stdId}
                                            sx={{ height: 650 }}
                                            editMode="row"
                                            rows={dataLista}
                                            columns={columns}
                                            disableRowSelectionOnClick
                                            disableColumnFilter
                                            disableColumnSelector
                                            disableDensitySelector
                                            slots={{ toolbar: GridToolbarQuickFilter, }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                },
                                                pagination: {
                                                    labelRowsPerPage: "Registros por página:",
                                                },
                                            }}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            pageSizeOptions={[10, 25, 50]}
                                            localeText={{
                                                footerTotalRows: false,
                                                noRowsLabel: "No se ha encontrado datos.",
                                                noResultsOverlayLabel:
                                                    "No se ha encontrado ningún resultado",
                                                toolbarColumns: "Columnas",
                                                toolbarColumnsLabel: "Seleccionar columnas",
                                                toolbarFilters: "Filtros",
                                                toolbarFiltersLabel: "Ver filtros",
                                                toolbarFiltersTooltipHide: "Quitar filtros",
                                                toolbarFiltersTooltipShow: "Ver filtros",
                                                toolbarQuickFilterPlaceholder: "Buscar",
                                            }}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <></>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
