import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    IconButton,
    Input,
    Modal,
    DialogContentText,
    CircularProgress,
    Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { MesType, NotificacionWPPType, SateliteTestDrive, SateliteTestDriveImagen, SateliteTestDriveImagenMostrar, SateliteTestDriveRuta, VSateliteTestDriveRuta, vventassatelitecrmType } from "../../types";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const EntradaTestDrivePage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const params = useParams();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [mostrar, setMostrar] = useState<boolean>(false);
    const [rutas, setRutas] = useState<SateliteTestDriveRuta[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openDialogGuardado, setOpenDialogGuardado] = useState<boolean>(false);
    const [openDialogGuardadoError, setOpenDialogGuardadoError] = useState<boolean>(false);
    const [estaGuardando, setEstaGuardando] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const idGrupos: Record<number, string> = {
        123: '123',
        456: '',
        789: '',

    };
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            checkDeviceType();
            getDatosTestDrive();
            getTestDriveImages(Number(params.id));
        } else {
            setName("");
        }
    }, [account]);

    const fechaActual = new Date();
    fechaActual.setHours(fechaActual.getHours() - 5);

    const [dataTestDrive, setDataTestDrive] = useState<SateliteTestDrive>({
        stdId: 0,
        stdFecha: fechaActual,
        stdFechaIni: fechaActual,
        stdFechaFin: fechaActual,
        stdFinalizado: 0,
        idBodega: 0,
        idCrm: "",
        sruId: 0,
        stdEsInventario: 0,
        stdVinPlaca: "",
        stdUsuCorreoCrea: "",
        stdUsuNombreCrea: "",
        stdUsuCorreoResponsable: "",
        stdUsuNombreResponsable: "",
        stdUsuCorreoFin: "",
        stdUsuNombreFin: "",
        stdObservacion1: "",
        stdObservacion2: "",
        stdKmInicio: 0,
        stdKmFin: 0,
    });

    const [dataNegocio, setDataNegocio] = useState<vventassatelitecrmType>({
        id: 0,
        idcrm: "",
        createdate: "",
        cedula: "",
        cliente: "",
        asesor: "",
        dealname: "",
        agencia: "",
        modelo: "",
        correo: "",
        ageDireccion: "",
    });

    const [dataVRuta, setDataVRuta] = useState<VSateliteTestDriveRuta>({
        sruId: 0,
        idBodega: 0,
        idZona: 0,
        agencia: "",
        zona: "",
        sruDescripcion: "",
        sruMetrosDistancia: 0,
        sruObservacion: "",
        sruMetrosDistanciaTolerancia: 0,
        sruMinutos: 0,
        sruMinutosTolerancia: 0,
        sruIdGrupoWpp: ""
    });


    const [images, setImages] = useState<SateliteTestDriveImagenMostrar[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const handleImageClick = (index: number) => {
        setCurrentIndex(index);
        setOpenDialog(true);
    };

    async function getDatosTestDrive() {
        try {
            await axios
                .get<SateliteTestDrive>("https://apisatelite.azurewebsites.net/testdrive/buscar/" + params.id)
                .then((response) => {
                    setDataTestDrive(response.data);
                    getDatosNegocio(String(response.data.idCrm));
                    getDatosRuta(Number(response.data.sruId));
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosNegocio(idcrm: string) {
        try {
            await axios
                .get<vventassatelitecrmType[]>("https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" + idcrm)
                .then((response) => {
                    setDataNegocio(response.data[0]);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosRuta(id: number) {
        try {
            await axios
                .get<VSateliteTestDriveRuta>("https://apisatelite.azurewebsites.net/vsatelitetestdriveruta/buscar/" + String(id))
                .then((response) => {
                    setDataVRuta(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getTestDriveImages(id: number) { //: Promise<SateliteTestDriveImagen[]>
        try {
            const response = await axios.get<{ data: SateliteTestDriveImagen[] }>(
                `https://apisatelite.azurewebsites.net/testdriveimagen/testdrive/${id}`
            );
            if (response.status === 200) {
                // Procesamos cada objeto para transformar stiImagen (Buffer) a base64 URL
                const images = response.data.data.map((item) => ({
                    ...item,
                    stiImagen: `data:image/jpeg;base64,${item.stiImagen}`, // O PNG según corresponda
                }));
                setImages(images);
                setMostrar(true);
            } else {
                console.error("Error al obtener las imágenes:", response.statusText);
                return [];
            }
        } catch (error) {
            console.error("Error de Axios:", error);
            return [];
        }
    }



    const handlePrevious = () => {
        if (currentIndex !== null && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex !== null && currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    function stringToBlob(string: string) {
        try {
            // Tu string en formato Base64 que representa la imagen
            const base64Image = "data:image/png;" + string; // Recorta tu base64 aquí

            // Convertir el Base64 a un Blob
            const base64Data = base64Image.split(',')[1]; // Separar el encabezado
            const byteCharacters = atob(base64Data); // Decodificar Base64
            const byteNumbers = new Uint8Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const blob = new Blob([byteNumbers], { type: 'image/png' });

            // Crear un objeto File a partir del Blob
            const file = new File([blob], "example.png", { type: 'image/png' });

            console.log('Blob:', blob);
            console.log('File:', file);
            return file
            // Ahora puedes usar este archivo para cargarlo en un servidor o realizar otras operaciones.
        } catch (error) {
            return ""
        }
    }
    const mensajeWpp = "🚨 *¡Alerta! Novedades Test Drive* 🚨" +
        "\nEl vehículo utilizado para Test Drive" +
        "\n*Modelo*: " + dataTestDrive.stdVinPlaca +
        "\n*Ruta*: " + dataVRuta.sruDescripcion +
        "\nHa excedido el kilometraje de su ruta en " + String(((Number(dataTestDrive.stdKmFin) * 1000) - (Number(dataTestDrive.stdKmInicio) * 1000)) - (dataVRuta.sruMetrosDistancia + dataVRuta.sruMetrosDistanciaTolerancia)) + " metros. " +
        "\nEl Test Drive fue utilizado por" +
        "\n*Asesor*: " + dataNegocio.asesor +
        "\n*Cliente*: " + dataNegocio.cliente +
        "\n*Fecha y hora de Salida*: " + dataTestDrive.stdFechaIni?.toLocaleString().replace("T", " ").substring(0, 16) +
        "\n*Fecha y Hora de Entrada*: " + dataTestDrive.stdFechaFin?.toLocaleString().replace("T", " ").substring(0, 16) +
        "\nSi deseas revisar más a detalle: https://satelite.corporacionproauto.com.ec/testdrive/resumen/" + String(dataTestDrive.stdId) +
        "\nSi deseas hablar con el responsable: https://wa.me/593" + dataNegocio.telefonoAsesor?.substring(1, 10)

    async function postGuardarRegistro(registro: SateliteTestDrive, notifica: boolean, mensaje: string) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/testdrive", registro, {
                    headers: headers,
                })
                .then(async (response) => {
                    console.log("id guardado es: >> ", response);
                    handleSaveAll(String(registro.stdId));

                    if (notifica) {
                        await EnviarNotificacionWPP(dataVRuta.sruIdGrupoWpp, mensaje)
                        // await EnviarNotificacionWPP("593964009927", mensaje)
                    }

                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function EnviarNotificacionWPP(
        to: string,
        body: string,
    ) {
        const nuevoRegistro: NotificacionWPPType = {
            typing_time: 0,
            to: to,
            body: body,
        };
        console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
        postNotificarWPP(nuevoRegistro);
    }

    async function postNotificarWPP(nuevoRegistro: NotificacionWPPType) {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + "gMlELccOPZC3Uwy9zIyvQoe8feZ0xRjD",
        };
        try {
            axios
                .post<any>("https://gate.whapi.cloud/messages/text", nuevoRegistro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("mi wpp: ", response);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error peticion crear cli dms: ", error.message);
                return error.message;
            } else {
                console.log("error peticion crear cli dms: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    // const GuardarImagenes = async (images: string[]) => {
    //     try {
    //         for (const image of images) {
    //             const formData = new FormData();
    //             formData.append("file", stringToBlob(image)); // Base64 como string
    //             formData.append("stdId", "0");
    //             formData.append("stiNombre", "Pruebas APP");
    //             formData.append("stiFecha", new Date().toString());
    //             //console.log("mi json de imagenes es >>", formData.values);
    //             //console.log("mi imagen >>", image.stiImagen);
    //             await axios.post(
    //                 "https://apisatelite.azurewebsites.net/testdriveimagen/upload", // Ajusta la URL según tu configuración
    //                 formData,
    //                 { headers: { "Content-Type": "multipart/form-data" } }
    //             );
    //         }
    //         alert("Todas las imágenes fueron guardadas con éxito.");
    //         //setTempImages([]);
    //     } catch (error) {
    //         console.error("Error al guardar imágenes:", error);
    //         alert("Error al guardar las imágenes.");
    //     }
    // };


    const [tempImagesTablero, setTempImagesTablero] = useState<SateliteTestDriveImagen[]>([]);
    const [tempImagesVehiculo, setTempImagesVehiculo] = useState<SateliteTestDriveImagen[]>([]);
    const [currentImageIndexTablero, setCurrentImageIndexTablero] = useState<number | null>(null);
    const [currentImageIndexVehiculo, setCurrentImageIndexVehiculo] = useState<number | null>(null);


    const handleFileChangeTablero = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setTempImagesTablero((prev) => [
                ...prev,
                {
                    stiId: 0, // Provisional
                    stdId: 123, // ID de ejemplo
                    stiNombre: file.name,
                    stiImagen: file, // Guarda el archivo original
                    stiFecha: fechaActual.toISOString(),
                },
            ]);
        }
    };
    const handleFileChangeVehiculo = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setTempImagesVehiculo((prev) => [
                ...prev,
                {
                    stiId: 0, // Provisional
                    stdId: 123, // ID de ejemplo
                    stiNombre: file.name,
                    stiImagen: file, // Guarda el archivo original
                    stiFecha: fechaActual.toISOString(),
                },
            ]);
        }
    };



    const handleSaveAll = async (_idTest: string) => {

        try {
            for (const image of tempImagesTablero) {
                const formData = new FormData();
                formData.append("file", image.stiImagen); // Archivo binario
                formData.append("stdId", _idTest);
                formData.append("stiNombre", "Entrada: tablero");
                formData.append("stiFecha", image.stiFecha);

                await axios.post(
                    "https://apisatelite.azurewebsites.net/testdriveimagen/upload",
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
            }
            for (const image of tempImagesVehiculo) {
                const formData = new FormData();
                formData.append("file", image.stiImagen); // Archivo binario
                formData.append("stdId", _idTest);
                formData.append("stiNombre", "Entrada: vehículo");
                formData.append("stiFecha", image.stiFecha);

                await axios.post(
                    "https://apisatelite.azurewebsites.net/testdriveimagen/upload",
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
            }
            setOpenDialogGuardado(true);
            setTempImagesTablero([]);
            setTempImagesVehiculo([]);
        } catch (error) {
            console.error("Error al guardar imágenes:", error);
            setOpenDialogGuardadoError(true);
        }
    };

    const handleDeleteImageTablero = (stiNombre: string) => {
        setTempImagesTablero((prev) => prev.filter((img) => img.stiNombre !== stiNombre));
        if (currentImageIndexTablero !== null && tempImagesTablero[currentImageIndexTablero]?.stiNombre === stiNombre) {
            setCurrentImageIndexTablero(null);
        }
    };
    const handleDeleteImageVehiculo = (stiNombre: string) => {
        setTempImagesVehiculo((prev) => prev.filter((img) => img.stiNombre !== stiNombre));
        if (currentImageIndexVehiculo !== null && tempImagesVehiculo[currentImageIndexVehiculo]?.stiNombre === stiNombre) {
            setCurrentImageIndexVehiculo(null);
        }
    };

    const handleOpenModalTablero = (index: number) => {
        setCurrentImageIndexTablero(index);
    };
    const handleOpenModalVehiculo = (index: number) => {
        setCurrentImageIndexVehiculo(index);
    };

    const handleCloseModalTablero = () => {
        setCurrentImageIndexTablero(null);
    };
    const handleCloseModalVehiculo = () => {
        setCurrentImageIndexVehiculo(null);
    };

    const handleNextImageTablero = () => {
        if (currentImageIndexTablero !== null) {
            setCurrentImageIndexTablero((prevIndex) => (prevIndex! + 1) % tempImagesTablero.length);
        }
    };
    const handleNextImageVehiculo = () => {
        if (currentImageIndexVehiculo !== null) {
            setCurrentImageIndexVehiculo((prevIndex) => (prevIndex! + 1) % tempImagesVehiculo.length);
        }
    };

    const handlePrevImageTablero = () => {
        if (currentImageIndexTablero !== null) {
            setCurrentImageIndexTablero(
                (prevIndex) => (prevIndex! - 1 + tempImagesTablero.length) % tempImagesTablero.length
            );
        }
    };
    const handlePrevImageVehiculo = () => {
        if (currentImageIndexVehiculo !== null) {
            setCurrentImageIndexVehiculo(
                (prevIndex) => (prevIndex! - 1 + tempImagesVehiculo.length) % tempImagesVehiculo.length
            );
        }
    };


    if (isAuthenticated) {
        if (mostrar) {

            return (
                <Container maxWidth="lg" sx={{ mt: 10 }}>
                    <Grid alignItems={"center"} justifyContent={"center"}>
                        <Grid item>
                            <Paper
                                sx={{
                                    padding: "1.2em",
                                    borderRadius: "0.5em",
                                    backgroundColor: "rgb(255,255,255,0.95)",
                                    mt: 2,
                                }}
                            >
                                <Typography variant="h5" textAlign={"center"}>
                                    Test Drive: Registro de Entrada
                                </Typography>
                            </Paper>
                            <Paper
                                sx={{
                                    padding: "1.2em",
                                    borderRadius: "0.5em",
                                    backgroundColor: "rgb(255,255,255,0.95)",
                                    mt: 1,
                                }}
                            >
                                {isMobile ? <>
                                </> : <>

                                    <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                        <strong>Información general</strong>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Identificacion:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataNegocio.cedula}
                                        // onChange={HandleChangeParametroCotizador}
                                        sx={{ mt: 1, mb: 1, width: "15%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Cliente:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataNegocio.cliente}
                                        // onChange={HandleChangeParametroCotizador}
                                        sx={{ mt: 1, mb: 1, width: "35%" }}
                                    />


                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Teléfono:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataNegocio.contactphone}
                                        sx={{ mt: 1, mb: 1, width: "15%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Correo:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataNegocio.contactemail}
                                        sx={{ mt: 1, mb: 1, width: "35%" }}
                                    />
                                    <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 2 }} />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Ruta:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataVRuta.sruDescripcion}
                                        sx={{ mt: 1, mb: 1, width: "35%" }}
                                    />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "15%",
                                            ml: "8%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Distancia de la ruta:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={`${(dataVRuta.sruMetrosDistancia / 1000)} KM`}
                                        sx={{ mt: 1, mb: 1, width: "12%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Vehículo:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataTestDrive.stdVinPlaca}
                                        sx={{ mt: 1, mb: 1, width: "35%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "15%",
                                            ml: "8%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        KM de inicio:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataTestDrive.stdKmInicio}
                                        sx={{ mt: 1, mb: 1, width: "12%" }}
                                    />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Responsable:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataNegocio.asesor}
                                        sx={{ mt: 1, mb: 1, width: "35%" }}
                                    />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "8%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Hora de inicio:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={dataTestDrive.stdFechaIni?.toLocaleString().substring(0, 10) + ", " + dataTestDrive.stdFechaIni?.toLocaleString().substring(11, 16)}
                                        sx={{ mt: 1, mb: 1, width: "17%" }}
                                    />

                                    <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />

                                    <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                        <strong>Fotos del registro de salida</strong>
                                    </Typography>

                                    <Box sx={{ ml: "10%", mr: "10%" }}>
                                        <Grid container spacing={2}>
                                            {images.map((image, index) => (
                                                <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                    <Card>
                                                        <CardActionArea onClick={() => handleImageClick(index)}>
                                                            <CardMedia
                                                                component="img"
                                                                height="200"
                                                                image={String(image.stiImagen)}
                                                                alt={image.stiNombre}
                                                            />
                                                            <CardContent>
                                                                <Typography variant="h6">{image.stiNombre}</Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Fecha: {new Date(image.stiFecha).toLocaleDateString()}
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>


                                        {/* Modal para mostrar imagen ampliada */}
                                        {currentIndex !== null && (
                                            <Dialog open={openDialog} maxWidth="md">
                                                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
                                                        <ArrowBack />
                                                    </IconButton>
                                                    {images[currentIndex].stiNombre}
                                                    <IconButton onClick={handleNext} disabled={currentIndex === null || currentIndex === images.length - 1}>
                                                        <ArrowForward />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    {currentIndex !== null && (
                                                        <Box
                                                            component="img"
                                                            src={String(images[currentIndex].stiImagen)}
                                                            alt="Imagen ampliada"
                                                            sx={{
                                                                width: "100%",
                                                                maxHeight: "80vh",
                                                                objectFit: "contain",
                                                                borderRadius: 2,
                                                            }}
                                                        />
                                                    )}
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={(e) => setOpenDialog(false)}>Cerrar</Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                    </Box>
                                    <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                    <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                        <strong>Fotos del registro de entrada</strong>
                                    </Typography>
                                    <Tooltip title="Mínimo 1 foto">
                                        <Typography sx={{ ml: "10%", mt: 2, mb: 3, width: "20%" }} textAlign={"left"} >
                                            <strong>Fotos del tablero {String(tempImagesTablero.length)}/1💡 </strong>
                                        </Typography>
                                    </Tooltip>

                                    <Input sx={{ ml: "10%", mb: 2 }}
                                        type="file"
                                        inputProps={{ accept: "image/jpeg, image/png" }}
                                        onChange={handleFileChangeTablero}
                                    />

                                    <Box sx={{ ml: "10%", mr: "10%", mb: 2 }}>

                                        <Grid container spacing={2}>
                                            {tempImagesTablero.map((image, index) => (
                                                <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                    <Card
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() => handleOpenModalTablero(index)}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            image={typeof image.stiImagen === "string"
                                                                ? image.stiImagen
                                                                : URL.createObjectURL(image.stiImagen)} // Convierte File en URL
                                                            alt={image.stiNombre}
                                                        />
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                    <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                    <Tooltip title="Máximo 8 fotos">
                                        <Typography sx={{ ml: "10%", mt: 2, mb: 3, width: "30%" }} textAlign={"left"}>
                                            <strong>Fotos del vehículo {String(tempImagesVehiculo.length)}/8 💡</strong>
                                        </Typography>
                                    </Tooltip>



                                    <Input sx={{ ml: "10%", mb: 2 }}
                                        type="file"
                                        inputProps={{ accept: "image/jpeg, image/png" }}
                                        onChange={handleFileChangeVehiculo}
                                    />

                                    <Box sx={{ ml: "10%", mr: "10%", mb: 2 }}>

                                        <Grid container spacing={2}>
                                            {tempImagesVehiculo.map((image, index) => (
                                                <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                    <Card
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() => handleOpenModalVehiculo(index)}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            image={typeof image.stiImagen === "string"
                                                                ? image.stiImagen
                                                                : URL.createObjectURL(image.stiImagen)} // Convierte File en URL
                                                            alt={image.stiNombre}
                                                        />
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>

                                    <Modal open={currentImageIndexTablero !== null} onClose={handleCloseModalTablero}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "100%",
                                                bgcolor: "background.paper",
                                                p: 4,
                                                position: "relative",
                                            }}
                                        >
                                            {currentImageIndexTablero !== null && (
                                                <>
                                                    <IconButton
                                                        sx={{ position: "absolute", left: 20, top: "50%" }}
                                                        onClick={handlePrevImageTablero}
                                                    >
                                                        <ArrowBack />
                                                    </IconButton>
                                                    <img
                                                        src={URL.createObjectURL(tempImagesTablero[currentImageIndexTablero].stiImagen)} // Si es un File, crea una URL temporal
                                                        alt={tempImagesTablero[currentImageIndexTablero].stiNombre}
                                                        style={{ maxHeight: "80%", maxWidth: "80%" }}
                                                    />
                                                    <IconButton
                                                        sx={{ position: "absolute", right: 20, top: "50%" }}
                                                        onClick={handleNextImageTablero}
                                                    >
                                                        <ArrowForward />
                                                    </IconButton>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                            handleDeleteImageTablero(tempImagesTablero[currentImageIndexTablero].stiNombre)
                                                        }
                                                        sx={{ mt: 2 }}
                                                    >
                                                        Eliminar Imagen
                                                    </Button>
                                                </>
                                            )}
                                        </Box>
                                    </Modal>
                                    <Modal open={currentImageIndexVehiculo !== null} onClose={handleCloseModalVehiculo}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "100%",
                                                bgcolor: "background.paper",
                                                p: 4,
                                                position: "relative",
                                            }}
                                        >
                                            {currentImageIndexVehiculo !== null && (
                                                <>
                                                    <IconButton
                                                        sx={{ position: "absolute", left: 20, top: "50%" }}
                                                        onClick={handlePrevImageVehiculo}
                                                    >
                                                        <ArrowBack />
                                                    </IconButton>
                                                    <img
                                                        src={URL.createObjectURL(tempImagesVehiculo[currentImageIndexVehiculo].stiImagen)} // Si es un File, crea una URL temporal
                                                        alt={tempImagesVehiculo[currentImageIndexVehiculo].stiNombre}
                                                        style={{ maxHeight: "80%", maxWidth: "80%" }}
                                                    />
                                                    <IconButton
                                                        sx={{ position: "absolute", right: 20, top: "50%" }}
                                                        onClick={handleNextImageVehiculo}
                                                    >
                                                        <ArrowForward />
                                                    </IconButton>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                            handleDeleteImageVehiculo(tempImagesVehiculo[currentImageIndexVehiculo].stiNombre)
                                                        }
                                                        sx={{ mt: 2 }}
                                                    >
                                                        Eliminar Imagen
                                                    </Button>
                                                </>
                                            )}
                                        </Box>
                                    </Modal>

                                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "7%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        KM final:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name="stdKmFin"
                                        type="number"
                                        onChange={(e) => {
                                            setDataTestDrive({
                                                ...dataTestDrive,
                                                stdKmFin: Number(e.target.value),
                                                stdFinalizado: 1,
                                                stdFechaFin: fechaActual,
                                                stdUsuCorreoFin: username,
                                                stdUsuNombreFin: name,
                                            })
                                        }}
                                        value={dataTestDrive.stdKmFin}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        sx={{ mt: 1, mb: 1, width: "11%" }}
                                    />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "10%",
                                            ml: "3%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Hora final:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={new Date().toLocaleString()}
                                        sx={{ mt: 1, mb: 1, width: "17%" }}
                                    />
                                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "15%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Observaciones:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        onChange={(e) => {
                                            setDataTestDrive({
                                                ...dataTestDrive,
                                                stdObservacion2: e.target.value,
                                            })
                                        }}
                                        value={dataTestDrive.stdObservacion2}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        sx={{ mt: 1, mb: 1, width: "65%" }}
                                    />
                                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />


                                    <Button
                                        variant="contained"
                                        onClick={(e) => {
                                            setEstaGuardando(true);
                                            postGuardarRegistro(dataTestDrive, (((Number(dataTestDrive.stdKmFin) * 1000) - (Number(dataTestDrive.stdKmInicio) * 1000)) > (Number(dataVRuta.sruMetrosDistancia) + Number(dataVRuta.sruMetrosDistanciaTolerancia)) ? true : false), mensajeWpp
                                            )
                                        }}
                                        sx={{ ml: "42%", width: "16%" }}
                                        disabled={estaGuardando ? true : tempImagesTablero.length !== 1 ? true : tempImagesVehiculo.length > 8 ? true : dataTestDrive.stdKmFin === 0 ? true : false}
                                    >
                                        {estaGuardando ?
                                            <>
                                                <CircularProgress size={24} sx={{ position: 'absolute' }} /> {"Espere por favor"}
                                            </>
                                            : "Guardar"}
                                    </Button>
                                    <Dialog
                                        open={openDialogGuardado}
                                        //onClose={handleCloseDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Guardado correctamente!
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                href={`/testdrive/pendientes`}
                                                onClick={function (event) {
                                                    setOpenDialogGuardado(false);
                                                }}
                                                autoFocus
                                            >
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={openDialogGuardadoError}
                                        //onClose={handleCloseDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Error al guardar los datos!
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={function (event) {
                                                    setOpenDialogGuardadoError(false);
                                                }}
                                                autoFocus
                                            >
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            );
        } else {
            return (

                <Container maxWidth="lg" sx={{ mt: 10 }}>
                    <Paper>
                        <Grid
                            //container
                            alignItems={"center"}
                            justifyContent={"center"}
                        >

                            <Typography
                                sx={{ mt: 2, mb: 1.5 }}
                                variant="h6"
                                textAlign={"center"}
                            >
                                <CircularProgress size={24} sx={{ position: 'absolute' }} />{" "}
                            </Typography>
                            <Divider sx={{ ml: "45%", mr: "45%" }} />
                            <Typography
                                sx={{ mt: 2, mb: 1.5 }}
                                variant="h6"
                                textAlign={"center"}
                            >
                                Cargando imagenes
                            </Typography>
                        </Grid>
                    </Paper>
                </Container>



            )
        }

    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
