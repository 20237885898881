import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography, FormControlLabel, Checkbox, CircularProgress, MenuItem, Alert, List, ListItem, ListItemText,
    Modal,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { NotificacionWPPType, SateliteCotizacionType, SateliteParametroCotizadorType, ValorCuotaPostType, ValorCuotasType, VSateliteCotizadorAutorizaType, vventassatelitecrmType } from "../../types";


interface Descuento {
    nombre: string;
    valor: number;
}

type Extra = {
    nombre: string;
    valor: number;
    checked: boolean;
};
export const CotizadorNewPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const [nuevoId, setNuevoId] = React.useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogEntrada, setOpenDialogEntrada] = React.useState(false);
    const [openDialogTelefono, setOpenDialogTelefono] = React.useState(false);
    const [openDialogEnviadoOk, setOpenDialogEnviadoOk] = React.useState(false);
    const [openDialogEnviadoError, setOpenDialogEnviadoError] = React.useState(false);
    const params = useParams();

    const [validarCambios, setValidarCambios] = React.useState(false);
    const [precio, setPrecio] = useState(0);
    const [descuento, setDescuento] = useState('');
    const [porcentaje, setPorcentaje] = useState('');
    const [seguro, setSeguro] = useState<string>('');
    const [cuota, setCuota] = useState<string>('');
    const [entrada, setEntrada] = useState<string>('');
    const [montoAFinanciar, setMontoAFinanciar] = useState<string>('');
    const [matricula, setMatricula] = useState<string>('');
    const [modelos, setModelos] = useState<SateliteParametroCotizadorType[]>([]); // Estado tipado
    const [selectedModelo, setSelectedModelo] = useState('');
    const [descuentoTotal, setDescuentoTotal] = useState(0);
    const [specifications, setSpecifications] = useState<SateliteParametroCotizadorType | null>(null);
    const [precioEspecial, setPrecioEspecial] = useState<number>(0);
    const [modelo, setModelo] = useState<string>('');
    const [descuentos, setDescuentos] = useState<Descuento[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [esNuevo, setEsNuevo] = useState<boolean>(false);
    const [mesesRestantes, setMesesRestantes] = React.useState<number>(0);
    const [esDescuentoAutorizacion, setEsDescuentoAutorizacion] = useState<boolean>(false);
    const [esFIAutorizacion, setEsFIAutorizacion] = useState<boolean>(false);
    const [openModalFIAutorizacion, setOpenModalFIAutorizacion] = useState<boolean>(false);
    const [openModalComercialAutorizacion, setOpenModalComercialAutorizacion] = useState<boolean>(false);
    const userAgent = navigator.userAgent;


    const [listaAutorizaRows, setListaAutorizaRows] = useState<VSateliteCotizadorAutorizaType[]>([]);

    const styleModal = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1050,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    const [extrasAdicionales, setExtrasAdicionales] = useState([
        { nombre: "", valor: 0, checked: false, obligado: false },
        //{ nombre: "Extra 2", valor: 150, checked: true }
    ]);


    const colores = [
        { id: 1, color: "Rojo", r: 0.56, g: 0.12, b: 0.1 },
        { id: 2, color: "Blanco", r: 0.50, g: 0.50, b: 0.50 },
        { id: 3, color: "Plateado", r: 0.45, g: 0.45, b: 0.45 },
        { id: 4, color: "Café", r: 0.38, g: 0, b: 0 },
        { id: 5, color: "Gris", r: 0.40, g: 0.40, b: 0.40 },
        { id: 6, color: "Negro", r: 0.1, g: 0.1, b: 0.1 },
        { id: 7, color: "Azul", r: 0, g: 0.2, b: 0.38 }
    ];
    const [selectedColor, setSelectedColor] = useState({
        color: 0,
        colorN: "",
        rgbColor: { r: 0, g: 0, b: 0 }
    });
    const [parametroCotizador, setParametroCotizador] = React.useState<SateliteParametroCotizadorType>({
        spcId: 0,
        spcImagen1: "",
        spcImagen2: "",
        spcNombre: "",
        spcText2: "",
        spcMarcaModeloVersion: "",
        spcCaracteristica1: "",
        spcDetalle1: "",
        spcCaracteristica2: "",
        spcDetalle2: "",
        spcCaracteristica3: "",
        spcDetalle3: "",
        spcCaracteristica4: "",
        spcDetalle4: "",
        spcCaracteristica5: "",
        spcDetalle5: "",
        spcCaracteristica6: "",
        spcDetalle6: "",
        spcCaracteristica7: "",
        spcDetalle7: "",
        spcCaracteristica8: "",
        spcDetalle8: "",
        spcDispositivo: "",
        spcDispositivoA1: 0,
        spcDispositivoA2: 0,
        spcDispositivoA3: 0,
        spcDispositivoA4: 0,
        spcDispositivoA5: 0,
        spcPlan1Tipo: "",
        spcPlan1Modelo: "",
        spcPlan1Descripcion: "",
        spcPlan1PvpNormal: 0,
        spcPlan1PvpMpc: 0,
        spcPlan1Cobertura: "",
        spcPlan2Tipo: "",
        spcPlan2Modelo: "",
        spcPlan2Descripcion: "",
        spcPlan2PvpNormal: 0,
        spcPlan2PvpMpc: 0,
        spcPlan2Cobertura: "",
        spcLink1: "",
        spcFamilia: "",
        spcDescuento1: "",
        spcDescuento1Valor: 0,
        spcDescuento2: "",
        spcDescuento2Valor: 0,
        spcDescuento3: "",
        spcDescuento3Valor: 0,
        spcDescuento4: "",
        spcDescuento4Valor: 0,
        spcDescuento5: "",
        spcDescuento5Valor: 0,
        spcPvp: 0,
        idCotItem: 0,
        spcIdDispositivo: 0,
        spcModeloEspecifico: "",
        spcGarantia: 0,
        spcExtra1Descripcion: "",
        spcExtra1Valor: 0,
        spcExtra2Descripcion: "",
        spcExtra2Valor: 0,
        spcExtra3Descripcion: "",
        spcExtra3Valor: 0,
        spcExtra4Descripcion: "",
        spcExtra4Valor: 0,
        spcSeguroPorcen: 0,
        spcSeguroSuperbanco: 0,
       spcSeguroCampesino: 0,
        spcSeguroDerechosEmision: 0,
        spcSeguroIva: 0,
    });

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemValue = parseInt(event.target.value, 10);
        const selected = colores.find((item) => item.id === itemValue);
        if (selected) {
            setSelectedColor({
                color: selected.id,
                colorN: selected.color,
                rgbColor: { r: selected.r, g: selected.g, b: selected.b }
            });
        }

        setRegistroCotizador({
            ...registroCotizador,
            idColor: Number(event.target.value),
        });
    };

    const validarCampos = () => {
        for (const extra of extrasAdicionales) {
            if (!String(extra.nombre).trim() || !String(extra.valor).trim()) {
                alert("Por favor, completa todos los campos nombre y valor de extras.");
                return false;
            }
        }
        return true;
    };



    const fetchModelos = async () => {
        try {
            const response = await axios.get("https://apisatelite.azurewebsites.net/sateliteparametrocotizador/app");
            console.log(response.data);
            setModelos(response.data); // Guardar los modelos en el estado
        } catch (error) {
            console.error("Error al cargar los modelos:", error);
        } finally {
            setLoading(false);
        }
    };

    const [dataNegocio, setDataNegocio] = useState<vventassatelitecrmType>({
        id: 0,
        idcrm: "",
        createdate: "",
        cedula: "",
        cliente: "",
        asesor: "",
        dealname: "",
        agencia: "",
        modelo: "",
        correo: "",
        ageDireccion: "",
    });

    async function setearDatosCuotas(
        valorVh: number,
        entrada: number,
    ) {
        const nuevoRegistro: ValorCuotaPostType = {
            valorVh: valorVh,
            entrada: entrada,
        };
        console.log(
            "datos a consultar >> ",
            JSON.stringify(nuevoRegistro, null, 4)
        );
        postDatosCuota(nuevoRegistro);
        setValidarCambios(true);
    }


    async function getvListaAprueba(zona: number, bodega: number, marca: number, perfil: string, idLinea: number) {
        try {
            await axios
                .get<VSateliteCotizadorAutorizaType[]>(
                    "https://apisatelite.azurewebsites.net/vsatelitecotizadorautoriza/buscar/" +
                    String(zona) +
                    "/" +
                    String(bodega) +
                    "/" +
                    String(marca) +
                    "/" + perfil +
                    "/" +
                    String(idLinea)
                )
                .then((response) => {
                    setListaAutorizaRows(response.data);
                    if (perfil === "COMERCIAL") {
                        setOpenModalComercialAutorizacion(true);
                    } else {
                        setOpenModalFIAutorizacion(true);
                    }
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(listaAutorizaRows, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function postDatosCuota(registro: ValorCuotaPostType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post(
                    "https://apisatelite.azurewebsites.net/cotizador/valorcuota",
                    registro,
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    console.log("id guardado es: >>>>>>>> ", response);
                    setRegistroCotizador({
                        ...registroCotizador,
                        scoValor12Meses: response.data.valorCuota12,
                        scoValor24Meses: response.data.valorCuota24,
                        scoValor36Meses: response.data.valorCuota36,
                        scoValor48Meses: response.data.valorCuota48,
                        scoValor60Meses: response.data.valorCuota60,
                        scoValor72Meses: response.data.valorCuota72,
                    });
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosNegocio() {
        try {
            await axios
                .get<vventassatelitecrmType[]>("https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" + params.idcrm)
                .then((response) => {
                    setDataNegocio(response.data[0]);
                    setRegistroCotizador({
                        ...registroCotizador,
                        scoClienteNit: response.data[0].cedula,
                        scoClienteNombre: String(response.data[0].nombreCliente),
                        scoClienteCorreo: String(response.data[0].contactemail),
                        scoClienteTelefono: String(response.data[0].contactphone),
                        scoAgencia: response.data[0].agencia,
                        idCrm: response.data[0].idcrm,
                        scoAsesorCorreo: username,
                        scoFecha: new Date(),
                        scoEnviado: 0,
                    });
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosCotizacion() {
        try {
            await axios
                .get<SateliteCotizacionType>("https://apisatelite.azurewebsites.net/satelitecotizacion/" + params.id)
                .then((response) => {
                    setRegistroCotizador(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const handleModeloChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPorcentaje("15");
        setValidarCambios(false);
        const itemValue = event.target.value as string;
        setSelectedModelo(itemValue);

        const selectedSpecification = modelos.find(
            (item) => item.spcMarcaModeloVersion === itemValue
        );

        setRegistroCotizador({
            ...registroCotizador,
            spcId: Number(itemValue)
        });

        if (selectedSpecification) {
            setParametroCotizador(selectedSpecification)

            setExtrasAdicionales([
                { nombre: selectedSpecification.spcExtra1Descripcion, valor: selectedSpecification.spcExtra1Valor, checked: false, obligado: true },
                { nombre: selectedSpecification.spcExtra2Descripcion, valor: selectedSpecification.spcExtra2Valor, checked: false, obligado: true },
                { nombre: selectedSpecification.spcExtra3Descripcion, valor: selectedSpecification.spcExtra3Valor, checked: false, obligado: true },
                { nombre: selectedSpecification.spcExtra4Descripcion, valor: selectedSpecification.spcExtra4Valor, checked: false, obligado: true },
            ].filter(item => item.valor > 0));

            console.log("mis especificaciones son >>", selectedSpecification)
            setSpecifications(selectedSpecification);
            setPrecio(selectedSpecification.spcPvp || 0);

            const availableDiscounts = [
                { nombre: selectedSpecification.spcDescuento1, valor: selectedSpecification.spcDescuento1Valor },
                { nombre: selectedSpecification.spcDescuento2, valor: selectedSpecification.spcDescuento2Valor },
                // Agrega más descuentos si es necesario
            ].filter((discount) => discount.nombre);

            setDescuentos(availableDiscounts);
            const entradaCalculada = (parseFloat("15") / 100) * selectedSpecification.spcPvp;
            setEntrada(entradaCalculada.toFixed(2));
            setMontoAFinanciar((selectedSpecification.spcPvp - entradaCalculada).toFixed(2));
            setRegistroCotizador({
                ...registroCotizador,
                spcId: selectedSpecification.spcId,
                scoPvpBase: selectedSpecification.spcPvp,
                scoEntradaPorcen: 15,
                scoEntradaMonto: entradaCalculada,
                scoFinanciarMonto: selectedSpecification.spcPvp - entradaCalculada,
                scoMatriculaMonto: (selectedSpecification.spcPvp * 0.04) / 12 * mesesRestantes,
            });
        }

    };


    const [pvValues, setPvValues] = useState(
        descuentos.map(() => ({ valor: '', checkPv: false, checkEntrada: false }))
    );
    const [loading, setLoading] = useState(false);
    const convertirTelefono = (telefono: string) => {
        if (telefono.startsWith('0')) {
            return `593${telefono.slice(1)}`; // Reemplazar el primer 0 por 593
        }
        return telefono; // Si no empieza con 0, se retorna igual
    };

    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const handleInputChange = (index: number, value: string) => {
        setValidarCambios(false);
        // Filtrar caracteres no numéricos
        const numericValue = value.replace(/[^0-9.]/g, '');

        // Convertir el valor a número
        const parsedValue = Number(numericValue);

        // Obtener el valor máximo permitido desde la API y convertirlo a número
        const maxValue = Number(descuentos[index].valor);

        // Validar que el valor no exceda el máximo permitido
        if (parsedValue > maxValue) {
            alert(`El valor ingresado no puede ser mayor a ${maxValue}.`);
            return;
        }

        // Actualizar el estado si pasa la validación
        const updatedValues = [...pvValues];
        updatedValues[index] = { ...updatedValues[index], valor: numericValue };
        setPvValues(updatedValues);
    };

    const handleChangeCuotas = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value)
        setRegistroCotizador({
            ...registroCotizador,
            [e.target.name]: e.target.value,
        });

        if (esFIAutorizacion === false) {
            setRegistroCotizador({
                ...registroCotizador,
                scoFiNombre: "",
                scoFiTelefono: "",
                scoFiCorreo: "",
                scoFiObservacion: "",
                scoFiEstado: 0,
            });
        }

        setEsFIAutorizacion(true);
    };

    // Función para calcular los meses restantes hasta diciembre
    function calcularMesesRestantes() {
        const fechaActual = new Date();
        const mesActual = fechaActual.getMonth(); // 0 - 11 (0 = enero, 11 = diciembre)
        const diaActual = fechaActual.getDate();

        if (mesActual === 11) {
            return 1; // Si es diciembre, falta un mes para el nuevo año
        }

        const mesesRestantes = 11 - mesActual; // Meses restantes hasta diciembre
        return mesesRestantes + 1; // Contamos el mes actual como completo
    };



    // Calcula los meses restantes una vez

    // Calcula el valor de la matrícula usando `useMemo`
    const valorMatricula = useMemo(() => {
        const valor = (precioEspecial * 0.04) / 12 * mesesRestantes;
        return valor.toLocaleString("en-US", { style: "currency", currency: "USD" });
    }, [precioEspecial, mesesRestantes]);


    const handleCheckPvChange = (index: number, value: boolean) => {
        setValidarCambios(false);
        const updatedValues = [...pvValues];

        // Verificar si el objeto en la posición index existe, si no lo creamos con valores por defecto
        if (!updatedValues[index]) {
            updatedValues[index] = { valor: '', checkPv: false, checkEntrada: false };
        }

        // Si se selecciona PV, desmarcamos Entrada
        updatedValues[index].checkPv = value;
        if (value) {
            updatedValues[index].checkEntrada = false; // Desmarcamos Entrada

            // Llenar automáticamente el valor con item.valor si se selecciona PV
            updatedValues[index].valor = descuentos[index].valor.toString();
        } else if (!updatedValues[index].checkEntrada) {
            // Si se deselecciona PV y Entrada también está desmarcado, limpiar el campo de texto
            updatedValues[index].valor = '';
        }


        setPvValues(updatedValues);
    };

    const handleCheckEntradaChange = (index: number, value: boolean) => {
        setValidarCambios(false);
        const updatedValues = [...pvValues];

        // Verificar si el objeto en la posición index existe, si no lo creamos con valores por defecto
        if (!updatedValues[index]) {
            updatedValues[index] = { valor: '', checkPv: false, checkEntrada: false };
        }

        // Si se selecciona Entrada, desmarcamos PV
        updatedValues[index].checkEntrada = value;
        if (value) {
            updatedValues[index].checkPv = false; // Desmarcamos PV

            // Llenar automáticamente el valor con item.valor si se selecciona Entrada
            updatedValues[index].valor = descuentos[index].valor.toString();
        } else if (!updatedValues[index].checkPv) {
            // Si se deselecciona Entrada y PV también está desmarcado, limpiar el campo de texto
            updatedValues[index].valor = '';
        }

        setPvValues(updatedValues);
    };

    // Función para limpiar el formato de la moneda (eliminar caracteres no numéricos)
    const limpiarFormatoMoneda = (value: string) => {
        return value.replace(/[^0-9.-]+/g, "");
    };

    // Función para calcular el seguro vehicular
    async function calcularValores() {
        const precioVenta = parseFloat(limpiarFormatoMoneda(String(precio)));
        if (isNaN(precioVenta)) return;

        // Calcular el valor del seguro base
        const valorSeguro = precioVenta * 0.03;
        // Calcular los valores adicionales en base al valor del seguro
        const valorSuperintendencia = valorSeguro * 0.035; // 3.5% Superintendencia de Banco
        const valorSeguroCampesino = valorSeguro * 0.005; // 0.5% Seguro Campesino
        const derechosEmision = 0.45; // Derechos de Emisión en valor fijo
        const iva = (valorSeguro + valorSuperintendencia + valorSeguroCampesino + derechosEmision) * 0.15; // 15% IVA

        // Sumar todos los valores para obtener el valor total del seguro
        const valorSeguroTotal = valorSeguro + valorSuperintendencia + valorSeguroCampesino + derechosEmision + iva;
        // Calcular el valor de la cuota mensual
        const valorCuota = valorSeguroTotal / 12;

        // Actualizar los valores en la UI
        setSeguro(valorSeguroTotal.toFixed(2)); // Mostrar como número
        setCuota(valorCuota.toFixed(2)); // Mostrar como número
    };
    async function seteoTodoRegistro() {
        setRegistroCotizador({
            ...registroCotizador,
            scoFecha: new Date(),
            scoEnviado: 0,
            scoComercialEstado: 0,
            scoFiEstado: 0,
            scoObservacionesCotizacion: "",
            scoFiNombre: "",
            scoFiTelefono: "",
            scoFiCorreo: "",
            scoFiObservacion: "",
            //filename
        });
    }



    const calcularPrecioEspecial = (): number => {
        // Asegurarse de que `descuentoTotal` es un número
        const descuentoTotalNumber = typeof descuentoTotal === 'number' ? descuentoTotal : parseFloat(descuentoTotal) || 0;

        // Calcular el total de los extras
        const totalExtras = extrasAdicionales
            .filter(extra => !extra.checked) // Solo cuenta los extras que no son cortesía
            .reduce((total, extra) => total + (typeof extra.valor === 'number' ? extra.valor : parseFloat(extra.valor) || 0), 0); // Convierte `extra.valor` a número si es necesario

        // Asegurarse de que `precio` es un número
        const precioNumber = typeof precio === 'number' ? precio : parseFloat(precio) || 0; // Convierte `precio` a número si es necesario

        // Realiza el cálculo final (precio + extras - descuento)
        const precioFinal = precioNumber + totalExtras - descuentoTotalNumber;

        return precioFinal;
    };

    // Actualiza el precio especial cada vez que cambian los valores de `precio`, `extrasAdicionales` o `descuentoTotal`
    useEffect(() => {
        setPrecioEspecial(calcularPrecioEspecial());
        // Asigna el número calculado
    }, [precio, extrasAdicionales, descuentoTotal]);

    const calcularDescuento = (index: number, value: string) => {
        // Actualiza el valor directamente como string
        const updatedPvValues = [...pvValues];
        updatedPvValues[index].valor = value;

        setPvValues(updatedPvValues);
    };

    // Calcula el descuento total cada vez que los valores cambian
    useEffect(() => {
        const totalDescuento = pvValues.reduce((total, item) => {
            // Convierte el valor a número para los cálculos
            const itemValor = parseFloat(item.valor) || 0;  // Si no es un número, usa 0
            return total + itemValor;
        }, 0);

        // Asigna el valor calculado directamente como número
        setDescuentoTotal(totalDescuento);
    }, [pvValues]);





    // Función para agregar un extra
    const agregarExtra = () => {
        if (extrasAdicionales.length < 8) {
            setExtrasAdicionales([
                ...extrasAdicionales,
                { nombre: '', valor: 0, checked: false, obligado: false } // Inicializa `valor` como número
            ]);
        } else {
            alert("No se puede agregar más de 8 items."); // Muestra un mensaje si se intenta agregar más de 4
        }
    };


    // Función para convertir string en número
    const parseCurrency = (value: string) => {
        return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
    };
    // Función para eliminar un extra
    const eliminarExtra = (index: number) => {
        if (extrasAdicionales[index].obligado === false) {
            if (extrasAdicionales[index].checked) {
                setRegistroCotizador({
                    ...registroCotizador,
                    scoDesc2Valor: registroCotizador.scoDesc2Valor - extrasAdicionales[index].valor
                });
            }

            const updatedExtras = extrasAdicionales.filter((_, i) => i !== index);
            setExtrasAdicionales(updatedExtras);
        }
    };
    const formatCurrency = (value: string): string => {
        // Convierte la cadena a un número eliminando símbolos de dólar y comas
        const number = parseFloat(value.replace(/[$,]/g, ''));

        // Devuelve $0.00 si el número es inválido
        if (isNaN(number)) return '$0.00';

        // Convierte el número a una cadena con dos decimales
        const parts = number.toFixed(2).split('.');

        // Separa los miles y los decimales
        const integerPart = parts[0];
        const decimalPart = parts[1];

        // Agrega la separación de miles
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Devuelve el resultado en formato deseado
        return `$${formattedIntegerPart}.${decimalPart}`;
    };
    // Función para manejar el cambio de texto en los campos
    const handleChange = (index: number, field: 'nombre' | 'valor', value: string) => {
        setValidarCambios(false);
        const updatedExtras = [...extrasAdicionales];
        updatedExtras[index] = { ...updatedExtras[index], [field]: field === 'valor' ? Number(value) : value };
        setExtrasAdicionales(updatedExtras);
    };

    // Función para manejar el check de cortesía
    const manejarCheck = (index: number) => {
        setValidarCambios(false);
        const updatedExtras = [...extrasAdicionales];
        updatedExtras[index].checked = !updatedExtras[index].checked;
        setExtrasAdicionales(updatedExtras);
        if (updatedExtras[index].checked) {
            setRegistroCotizador({
                ...registroCotizador,
                scoDesc2Valor: updatedExtras[index].valor + registroCotizador.scoDesc2Valor
            });
        } else {
            setRegistroCotizador({
                ...registroCotizador,
                scoDesc2Valor: registroCotizador.scoDesc2Valor - updatedExtras[index].valor
            });
        }
    };


    const [extras, setExtras] = useState([
        { valor: '0' }, { valor: '0' }, { valor: '0' }, { valor: '0' }
    ]);

    useEffect(() => {

        // Calcula el precio especial cada vez que cambian precio, descuento o extras
        const precioVenta = parseCurrency(String(precio));
        const totalDescuentoNumerico = parseCurrency(descuento);

        // Sumar los valores de los extras adicionales
        const valorExtrasAdicionales = extras.reduce((total, extra) => {
            const extraValor = extra.valor ? parseCurrency(extra.valor) : 0;
            return total + extraValor;
        }, 0);

        // Calculamos el precio especial sumando precio de venta + extras - descuento
        const precioEspecialCalculado = precioVenta + valorExtrasAdicionales - totalDescuentoNumerico - registroCotizador.scoDesc1Valor;
        setPrecioEspecial(precioEspecialCalculado);

    }, [precio, descuento, extras]); // Ejecuta el cálculo cuando cambian estas dependencias

    // Función para manejar el cambio de porcentaje y calcular la entrada y monto a financiar
    const handlePorcentajeBlur = () => {
        if (porcentaje) {
            if (Number(porcentaje) < 15) {
                setOpenDialogEntrada(true)
            } else {
                const entradaCalculada = (parseFloat(porcentaje) / 100) * precioEspecial;
                setEntrada(entradaCalculada.toFixed(2));
                setMontoAFinanciar((precioEspecial - entradaCalculada).toFixed(2));
                setRegistroCotizador({
                    ...registroCotizador,
                    scoEntradaMonto: entradaCalculada,
                    scoFinanciarMonto: precioEspecial - entradaCalculada,
                    scoEntradaPorcen: Number(porcentaje),
                });
            }
        }
    };

    // Función para calcular el porcentaje y monto a financiar cuando se ingresa la entrada
    const handleEntradaBlur = () => {
        if (entrada) {
            const porcentajeCalculado = (parseFloat(entrada) / precioEspecial) * 100;
            setPorcentaje(parseFloat(porcentajeCalculado.toFixed(2)).toString()); // Formatea sin ceros innecesarios
            setMontoAFinanciar((precioEspecial - parseFloat(entrada)).toFixed(2));
            setRegistroCotizador({
                ...registroCotizador,
                scoEntradaMonto: Number(entrada),
                scoFinanciarMonto: precioEspecial - Number(entrada),
                scoEntradaPorcen: porcentajeCalculado,
            });
        }
    };


    // Generar el filename para la imagen // Asegúrate de que el correo esté en el cliente
    const obtenerFechaFormateada = () => {
        const meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
        const fechaActual = new Date();
        const dia = fechaActual.getDate().toString().padStart(2, '0');
        const mes = meses[fechaActual.getMonth()];
        const año = fechaActual.getFullYear();

        return `${dia}.${mes}.${año}`;
    };


    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            checkDeviceType();
            getDatosNegocio();
            fetchModelos();
            setLoading(true);
            setMesesRestantes(calcularMesesRestantes())
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            if (Number(params.id) > 0) {
                getDatosCotizacion();
                setEsNuevo(false);
            } else {
                setEsNuevo(true);
            }
            setOpenDialogTelefono(true);
        } else {
            setName("");
        }
    }, [account]);


    const [registroCotizador, setRegistroCotizador] = useState<SateliteCotizacionType>({
        scoId: 0,
        scoFecha: new Date(),
        scoEnviado: 0,

        scoComercialNombre: "",
        scoComercialTelefono: "",
        scoComercialCorreo: "",
        scoComercialObservacion: "",
        scoComercialEstado: 0,

        scoFiNombre: "",
        scoFiTelefono: "",
        scoFiCorreo: "",
        scoFiObservacion: "",
        scoFiEstado: 0,


        scoAsesorCorreo: "",
        scoAsesorNombre: "",
        scoAsesorTelefono: "",
        scoAsesorDireccion: "",
        idZona: 0,
        scoFilename: "",
        idCrm: "",
        scoClienteNombre: "",
        scoClienteNit: "",
        scoClienteCorreo: "",
        scoClienteTelefono: "",
        scoAgencia: "",
        spcId: 0,
        idColor: 0,
        scoColor: "",
        scoColorR: 0,
        scoColorG: 0,
        scoColorB: 0,
        scoPvpBase: 0,
        scoPvpEspecial: 0,
        scoExt1Descripcion: "",
        scoExt1Pvp: 0,
        scoExt1Cortesia: 0,
        scoExt2Descripcion: "",
        scoExt2Pvp: 0,
        scoExt2Cortesia: 0,
        scoExt3Descripcion: "",
        scoExt3Pvp: 0,
        scoExt3Cortesia: 0,
        scoExt4Descripcion: "",
        scoExt4Pvp: 0,
        scoExt4Cortesia: 0,
        scoExt5Descripcion: "",
        scoExt5Pvp: 0,
        scoExt5Cortesia: 0,
        scoExt6Descripcion: "",
        scoExt6Pvp: 0,
        scoExt6Cortesia: 0,
        scoExt7Descripcion: "",
        scoExt7Pvp: 0,
        scoExt7Cortesia: 0,
        scoExt8Descripcion: "",
        scoExt8Pvp: 0,
        scoExt8Cortesia: 0,
        scoDesc1Descripcion: "",
        scoDesc1Valor: 0,
        scoDesc2Descripcion: "",
        scoDesc2Valor: 0,
        scoDesc3Descripcion: "",
        scoDesc3Valor: 0,
        scoDesc4Descripcion: "",
        scoDesc4Valor: 0,
        scoTerminos: "",
        scoMatriculaMonto: 0,
        scoEntradaMonto: 0,
        scoEntradaPorcen: 0,
        scoFinanciarMonto: 0,
        scoSeguroVeh: 0,
        scoSeguroCuotas: 0,
        scoMuestra12Meses: 1,
        scoValor12Meses: 0,
        scoMuestra24Meses: 1,
        scoValor24Meses: 0,
        scoMuestra36Meses: 1,
        scoValor36Meses: 0,
        scoMuestra48Meses: 1,
        scoValor48Meses: 0,
        scoMuestra60Meses: 1,
        scoValor60Meses: 0,
        scoMuestra72Meses: 1,
        scoValor72Meses: 0,
        scoObservacionesCotizacion: "",
    });


    async function seteoRegistro(
        newregistro: SateliteCotizacionType
    ) {
        setLoading(true);

        const nuevoRegistro: SateliteCotizacionType = {
            scoFecha: newregistro.scoFecha,
            scoEnviado: newregistro.scoEnviado,

            scoComercialNombre: newregistro.scoComercialNombre,
            scoComercialTelefono: newregistro.scoComercialTelefono,
            scoComercialCorreo: newregistro.scoComercialCorreo,
            scoComercialObservacion: newregistro.scoComercialObservacion,
            scoComercialEstado: newregistro.scoComercialEstado,

            scoFiNombre: newregistro.scoFiNombre,
            scoFiTelefono: newregistro.scoFiTelefono,
            scoFiCorreo: newregistro.scoFiCorreo,
            scoFiObservacion: newregistro.scoFiObservacion,
            scoFiEstado: newregistro.scoFiEstado,


            scoAsesorCorreo: dataNegocio.correo,
            scoAsesorNombre: dataNegocio.asesor,
            scoAsesorDireccion: String(dataNegocio.ageDireccion),
            scoAsesorTelefono: String(dataNegocio.telefonoAsesor),
            scoFilename: "",
            idZona: Number(dataNegocio.idZona),
            idCrm: dataNegocio.idcrm,
            scoClienteCorreo: String(dataNegocio.contactemail),
            scoClienteNit: dataNegocio.cedula,
            scoClienteNombre: dataNegocio.cliente,
            scoClienteTelefono: String(dataNegocio.contactphone),
            scoAgencia: dataNegocio.agencia,
            spcId: parametroCotizador.spcId,
            idColor: selectedColor.color,
            scoColor: selectedColor.colorN,
            scoColorR: selectedColor.rgbColor.r,
            scoColorG: selectedColor.rgbColor.g,
            scoColorB: selectedColor.rgbColor.b,
            scoPvpBase: Number(precio),
            scoPvpEspecial: Number(precioEspecial), // - (newregistro.scoDesc1Valor + newregistro.scoDesc2Valor + newregistro.scoDesc3Valor + newregistro.scoDesc4Valor) + (extrasAdicionales[0]?.valor + extrasAdicionales[1]?.valor + extrasAdicionales[2]?.valor + extrasAdicionales[3]?.valor + extrasAdicionales[4]?.valor + extrasAdicionales[5]?.valor + extrasAdicionales[6]?.valor + extrasAdicionales[7]?.valor),
            scoExt1Descripcion: extrasAdicionales[0]?.nombre,
            scoExt1Pvp: extrasAdicionales[0]?.valor,
            scoExt1Cortesia: extrasAdicionales[0]?.checked ? 1 : 0,
            scoExt2Descripcion: extrasAdicionales[1]?.nombre,
            scoExt2Pvp: extrasAdicionales[1]?.valor,
            scoExt2Cortesia: extrasAdicionales[1]?.checked ? 1 : 0,
            scoExt3Descripcion: extrasAdicionales[2]?.nombre,
            scoExt3Pvp: extrasAdicionales[2]?.valor,
            scoExt3Cortesia: extrasAdicionales[2]?.checked ? 1 : 0,
            scoExt4Descripcion: extrasAdicionales[3]?.nombre,
            scoExt4Pvp: extrasAdicionales[3]?.valor,
            scoExt4Cortesia: extrasAdicionales[3]?.checked ? 1 : 0,
            scoExt5Descripcion: extrasAdicionales[4]?.nombre,
            scoExt5Pvp: extrasAdicionales[4]?.valor,
            scoExt5Cortesia: extrasAdicionales[4]?.checked ? 1 : 0,
            scoExt6Descripcion: extrasAdicionales[5]?.nombre,
            scoExt6Pvp: extrasAdicionales[5]?.valor,
            scoExt6Cortesia: extrasAdicionales[5]?.checked ? 1 : 0,
            scoExt7Descripcion: extrasAdicionales[6]?.nombre,
            scoExt7Pvp: extrasAdicionales[6]?.valor,
            scoExt7Cortesia: extrasAdicionales[6]?.checked ? 1 : 0,
            scoExt8Descripcion: extrasAdicionales[7]?.nombre,
            scoExt8Pvp: extrasAdicionales[7]?.valor,
            scoExt8Cortesia: extrasAdicionales[7]?.checked ? 1 : 0,
            scoDesc1Descripcion: newregistro.scoDesc1Descripcion,
            scoDesc1Valor: newregistro.scoDesc1Valor,
            scoDesc2Descripcion: newregistro.scoDesc2Descripcion,
            scoDesc2Valor: newregistro.scoDesc2Valor,
            scoDesc3Descripcion: newregistro.scoDesc3Descripcion,
            scoDesc3Valor: newregistro.scoDesc3Valor,
            scoDesc4Descripcion: newregistro.scoDesc4Descripcion,
            scoDesc4Valor: newregistro.scoDesc4Valor,
            scoTerminos: newregistro.scoTerminos,
            scoMatriculaMonto: newregistro.scoMatriculaMonto,
            scoEntradaMonto: newregistro.scoEntradaMonto,
            scoEntradaPorcen: newregistro.scoEntradaPorcen,
            scoFinanciarMonto: newregistro.scoFinanciarMonto,
            scoSeguroVeh: Number(seguro),
            scoSeguroCuotas: Number(cuota),
            scoMuestra12Meses: newregistro.scoMuestra12Meses,
            scoValor12Meses: newregistro.scoValor12Meses,
            scoMuestra24Meses: newregistro.scoMuestra24Meses,
            scoValor24Meses: newregistro.scoValor24Meses,
            scoMuestra36Meses: newregistro.scoMuestra36Meses,
            scoValor36Meses: newregistro.scoValor36Meses,
            scoMuestra48Meses: newregistro.scoMuestra48Meses,
            scoValor48Meses: newregistro.scoValor48Meses,
            scoMuestra60Meses: newregistro.scoMuestra60Meses,
            scoValor60Meses: newregistro.scoValor60Meses,
            scoMuestra72Meses: newregistro.scoMuestra72Meses,
            scoValor72Meses: newregistro.scoValor72Meses,
            scoObservacionesCotizacion: newregistro.scoObservacionesCotizacion,

        };
        console.log(
            "ruta a guardar >> ",
            JSON.stringify(nuevoRegistro, null, 4)
        );
        await postGuardarRegistro(nuevoRegistro);
        
        setLoading(false);
    }

    async function postGuardarRegistro(registro: SateliteCotizacionType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/satelitecotizacion", registro, {
                    headers: headers,
                })
                .then(async(response) => {
                    if (registro.scoComercialTelefono.length > 0) {
                         await EnviarNotificacionWPP(registro.scoComercialTelefono, "📄 Hola *" + registro.scoComercialNombre + "*, tienes una cotización pendiente de aprobar. \nEnlace: https://appsatelitepruebas.azurewebsites.net/cotizador/cotizacion/"+  String(Number(response.data.scoId)))
                    }
                    if (registro.scoFiTelefono.length > 0) {
                         await EnviarNotificacionWPP(registro.scoFiTelefono, "📄 Hola *" + registro.scoFiNombre + "*, tienes una cotización pendiente de aprobar. \nEnlace: https://appsatelitepruebas.azurewebsites.net/cotizador/cotizacion/"+  String(Number(response.data.scoId)))
                    }
                    setNuevoId(Number(response.data.scoId));
                    setOpenDialogEnviadoOk(true);    
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function EnviarNotificacionWPP(
        to: string,
        body: string,
    ) {
        const nuevoRegistro: NotificacionWPPType = {
            typing_time: 0,
            to: to,
            body: body,
        };
        console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
        postNotificarWPP(nuevoRegistro);
    }

    async function postNotificarWPP(nuevoRegistro: NotificacionWPPType) {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + "gMlELccOPZC3Uwy9zIyvQoe8feZ0xRjD",
        };
        try {
            axios
                .post<any>("https://gate.whapi.cloud/messages/text", nuevoRegistro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("mi wpp: ", response);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error peticion crear cli dms: ", error.message);
                return error.message;
            } else {
                console.log("error peticion crear cli dms: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Cotizador
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >

                            {isMobile ? <>
                                <Typography textAlign={"left"} variant="h6" >
                                    <strong>Datos cliente</strong>
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Cliente"
                                    value={dataNegocio.cliente}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Identificación"
                                    value={dataNegocio.cedula}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Correo"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={dataNegocio.contactemail}
                                    onChange={(e) => setDataNegocio({
                                        ...dataNegocio,
                                        contactemail: e.target.value,
                                    })}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Teléfono"
                                    value={dataNegocio.contactphone}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    onChange={(e) => setDataNegocio({
                                        ...dataNegocio,
                                        contactphone: e.target.value,
                                    })}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} /> <Typography textAlign={"left"} variant="h6" >
                                    <strong>Datos vehículo</strong>
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    margin="normal"
                                    label="Modelo"
                                    value={selectedModelo}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    onChange={handleModeloChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "100%" }}
                                    SelectProps={{
                                        MenuProps: { disableScrollLock: true, style: { maxHeight: 300 } },
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Seleccione un modelo</em>
                                    </MenuItem>
                                    {modelos.map((item) => (
                                        <MenuItem key={item.spcId} value={item.spcMarcaModeloVersion}>
                                            {item.spcModeloEspecifico}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "50%",
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Color:
                                </Typography>
                                <TextField
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    id="outlined-select-color"
                                    select
                                    size="small"
                                    margin="normal"
                                    value={selectedColor.color}
                                    onChange={handleColorChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "50%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={0} disabled>
                                        Seleccione un color
                                    </MenuItem>
                                    {colores.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.color}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "50%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Precio de venta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="$"
                                    value={precio}
                                    onChange={(e) => { setPrecio(Number(e.target.value)) }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: "50%" }}
                                />
                                {/* Título "Extras" */}
                                <Divider sx={{ ml: '3%', mr: '3%', mt: 2, mb: 1 }} />
                                <Typography textAlign="left" variant="h6" style={{ display: 'inline-block' }}>
                                    <strong>Extras</strong>
                                </Typography>

                                {/* Mapeo de los extras */}
                                {extrasAdicionales.map((extra, index) => (
                                    <Box key={index}>
                                        <TextField
                                            style={{ backgroundColor: "rgb(255,255,255)" }}
                                            size="small"
                                            margin="normal"
                                            label={"Extra " + String(index + 1)}
                                            value={extra.nombre}
                                            onChange={(e) => handleChange(index, 'nombre', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '60%', mr: '2%', }}
                                            disabled={extra.obligado ? true : false}
                                            placeholder={`Extra ${index + 1}`}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={extra.valor}
                                            onChange={(e) => handleChange(index, 'valor', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '38%' }}
                                            disabled={extra.checked || extra.obligado}
                                            style={{
                                                backgroundColor:
                                                    extra.checked
                                                        ? "rgb(242,242,242)"
                                                        : "rgb(255,255,255)",
                                            }}
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        // Deshabilitar el campo de valor si el extra es de cortesía
                                        />
                                        {/* CheckBox para Cortesía */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={extra.checked}
                                                    onChange={() => manejarCheck(index)}
                                                    color="primary"
                                                />
                                            }
                                            label="Cortesía"
                                            sx={{ width: "35%", ml: "15%" }}
                                        />

                                        {/* Botón de eliminar */}
                                        <Button
                                            size="large"
                                            color="error"
                                            onClick={() => eliminarExtra(index)}
                                            sx={{ width: "45%" }}
                                        >
                                            Eliminar
                                        </Button>
                                        <Divider sx={{ ml: "15%", mr: "15%", mt: 2, mb: 2 }} />
                                    </Box>
                                ))}

                                <Button variant="contained" sx={{ ml: "50%", width: "50%" }} onClick={agregarExtra}>
                                    Agregar extra
                                </Button>
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} />
                                <Typography textAlign={"left"} variant="h6"  >
                                    <strong>Descuentos disponibles</strong>
                                </Typography>
                                {descuentos.map((item, index) => (
                                    <div key={item.nombre} >
                                        <Typography sx={{ width: "100%", mt: 2, mb: 1 }} style={{ display: "inline-block" }}>
                                            {item.nombre} (${item.valor})
                                        </Typography>
                                        <label style={{ marginTop: 2, marginBottom: 1 }}>
                                            PV
                                            <input
                                                style={{ marginTop: 2, marginBottom: 1 }}
                                                type="checkbox"
                                                checked={pvValues[index]?.checkPv || false}
                                                onChange={(e) => handleCheckPvChange(index, e.target.checked)}
                                            />
                                        </label>
                                        {/* Checkbox Entrada */}
                                        <label style={{ marginTop: 2, marginBottom: 1 }}>
                                            EN
                                            <input
                                                style={{ marginTop: 2, marginBottom: 1 }}
                                                type="checkbox"
                                                checked={pvValues[index]?.checkEntrada || false}
                                                onChange={(e) => handleCheckEntradaChange(index, e.target.checked)}
                                            />
                                        </label>
                                        <TextField
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={pvValues[index]?.valor || ''}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            sx={{ mt: 1, mb: 1, ml: "3%", width: "40%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        {/* Checkbox PV */}
                                        <Divider sx={{ ml: "15%", mr: "15%" }} />
                                    </div>
                                ))}

                                {/* Campos para Descuento, Precio Especial y Matrícula en una línea */}


                                {/* Descuento */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%',
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Descuento:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(descuentoTotal).replace("$", "$  ")}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                {/* Precio Especial */}
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%',
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Precio Especial:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(precioEspecial).replace("$", "$  ")}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                />

                                {/* Matrícula */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Matrícula:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={valorMatricula.replace("$", "$  ")}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography variant="h6" >
                                    <strong>Formas de pago</strong>
                                </Typography>

                                {/* Campo para el Precio Especial en una línea */}

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                    variant="h6"
                                >
                                    Contado:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(precioEspecial)).replace("$", "$  ")}
                                    onChange={(e) => setPrecioEspecial(parseFloat(e.target.value))}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    variant="h6"
                                >
                                    Crédito:
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Porcentaje de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={porcentaje}
                                    onChange={(e) => { setPorcentaje(e.target.value); setValidarCambios(false); }}
                                    onBlur={handlePorcentajeBlur}
                                    label="%"
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={entrada}
                                    label="$"
                                    onChange={(e) => {
                                        setValidarCambios(false);
                                        setEntrada(e.target.value);
                                    }}
                                    onBlur={handleEntradaBlur}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    InputProps={{

                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto a Financiar:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(montoAFinanciar)).replace("$", "$  ")}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />


                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography variant="h6" >
                                    <strong>Cuotas mensuales</strong>
                                </Typography>


                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="$"
                                    onChange={handleChangeCuotas}
                                    name="scoValor12Meses"
                                    value={registroCotizador.scoValor12Meses}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    onChange={handleChangeCuotas}
                                    name="scoValor24Meses"
                                    value={registroCotizador.scoValor24Meses}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    onChange={handleChangeCuotas}
                                    name="scoValor36Meses"
                                    value={registroCotizador.scoValor36Meses}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    onChange={handleChangeCuotas}
                                    name="scoValor48Meses"
                                    value={registroCotizador.scoValor48Meses}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    onChange={handleChangeCuotas}
                                    name="scoValor60Meses"
                                    value={registroCotizador.scoValor60Meses}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    onChange={handleChangeCuotas}
                                    name="scoValor72Meses"
                                    value={registroCotizador.scoValor72Meses}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />

                                {/* Título */}
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} />
                                <Typography textAlign={"left"} variant="h6" >
                                    <strong>Seguro vehicular</strong>
                                </Typography>

                                <Typography style={{ display: 'inline-block' }} sx={{ width: "50%", mt: 2, mb: 1 }}>Vehículo</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(seguro)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '45%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />


                                <Typography style={{ display: 'inline-block' }} sx={{ width: "50%", mt: 2, mb: 1 }}>12 Cuotas</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(cuota)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '45%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={async () => { await calcularValores(); await seteoTodoRegistro(); await setearDatosCuotas(precioEspecial, Number(entrada)) }}
                                    sx={{ ml: "50%", width: "45%" }}
                                    disabled={precioEspecial > 0 ? false : true}
                                >
                                    Calcular
                                </Button>
                                <div>
                                    <Button
                                        onClick={async () => {
                                            setLoading(true);
                                            await seteoRegistro(registroCotizador);
                                            setLoading(false);
                                        }}
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 1,
                                            width: "80%",
                                            marginLeft: "10%",
                                            position: 'relative',
                                        }}
                                        disabled={loading || validarCambios ? false : true}
                                    >
                                        {loading ? (
                                            <>
                                                <CircularProgress size={24} sx={{ position: 'absolute' }} />
                                                {"Espere por favor"}
                                            </>
                                        ) : (
                                            "Enviar Cotización"
                                        )}
                                    </Button>

                                    {error && <div style={{ color: 'red' }}>{error}</div>} {/* Mostrar error si ocurre */}
                                </div>
                            </> : <>
                                <Typography sx={{ ml: "10%" }} textAlign={"left"} variant="h6" >
                                    <strong>Datos cliente</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Cliente:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cliente}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Identificacion:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cedula}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Correo:
                                </Typography>
                                <TextField
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactemail}
                                    onChange={(e) => {
                                        setDataNegocio({
                                            ...dataNegocio,
                                            contactemail: e.target.value,
                                        })
                                        setRegistroCotizador({
                                            ...registroCotizador,
                                            scoClienteCorreo: e.target.value,
                                        });
                                    }}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Teléfono:
                                </Typography>
                                <TextField
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    size="small"
                                    margin="normal"
                                    inputProps={{ maxLength: 10 }}
                                    value={dataNegocio.contactphone}
                                    onChange={(e) => {
                                        setDataNegocio({
                                            ...dataNegocio,
                                            contactphone: e.target.value,
                                        })

                                        setRegistroCotizador({
                                            ...registroCotizador,
                                            scoClienteTelefono: e.target.value,
                                        });

                                    }
                                    }
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} /> <Typography sx={{ ml: "10%" }} textAlign={"left"} variant="h6" >
                                    <strong>Datos vehículo</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Modelo:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    select
                                    size="small"
                                    margin="normal"
                                    value={selectedModelo}
                                    onChange={handleModeloChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "70%" }}
                                    SelectProps={{
                                        MenuProps: { disableScrollLock: true, style: { maxHeight: 300 } },
                                    }}
                                >

                                    <MenuItem value="">
                                        <em>Seleccione un modelo</em>
                                    </MenuItem>
                                    {modelos.map((item) => (
                                        <MenuItem key={item.spcId} value={item.spcMarcaModeloVersion}>
                                            {item.spcModeloEspecifico}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Color:
                                </Typography>
                                <TextField
                                    id="outlined-select-color"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    select
                                    size="small"
                                    margin="normal"
                                    value={selectedColor.color}
                                    onChange={handleColorChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "15%", mr: "20%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={0} disabled>
                                        Seleccione un color
                                    </MenuItem>
                                    {colores.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.color}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Precio de venta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="$"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={precio}
                                    onChange={(e) => {
                                        {
                                            setPrecio(Number(e.target.value));
                                            setRegistroCotizador({
                                                ...registroCotizador,
                                                scoPvpBase: Number(e.target.value),
                                            });
                                        }
                                    }} // Muestra el valor de `spcPvp`
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                {/* Título "Extras" */}
                                <Divider sx={{ ml: '3%', mr: '3%', mt: 2, mb: 1 }} />
                                <Typography textAlign="left" variant="h6" sx={{ ml: "10%" }} style={{ display: 'inline-block' }}>
                                    <strong>Extras</strong>
                                </Typography>

                                {/* Mapeo de los extras */}
                                {extrasAdicionales.map((extra, index) => (
                                    <Box key={index}>
                                        <TextField
                                            size="small"
                                            style={{ backgroundColor: "rgb(255,255,255)" }}
                                            margin="normal"
                                            inputProps={{ maxLength: 30 }}
                                            label={"Extra " + String(index + 1)}
                                            value={extra.nombre}
                                            onChange={(e) => handleChange(index, 'nombre', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '35%', mr: '1%', ml: "10%" }}
                                            placeholder={`Extra ${index + 1}`}
                                            disabled={extra.obligado}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: '5%',
                                                fontSize: 10,
                                            }}
                                            style={{ display: 'inline-block' }}
                                        >
                                            {String(30 - extra.nombre.length) + " / 30"}
                                        </Typography>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={extra.valor}
                                            onChange={(e) => handleChange(index, 'valor', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '15%' }}
                                            disabled={extra.checked || extra.obligado}
                                            style={{
                                                backgroundColor:
                                                    extra.checked
                                                        ? "rgb(242,242,242)"
                                                        : "rgb(255,255,255)",
                                            }}
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        // Deshabilitar el campo de valor si el extra es de cortesía
                                        />
                                        {/* CheckBox para Cortesía */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={extra.checked}
                                                    onChange={() => manejarCheck(index)}
                                                    color="primary"
                                                />
                                            }
                                            label="Cortesía"
                                            sx={{ ml: 2 }}
                                        />
                                        {/* Botón de eliminar */}
                                        <Button
                                            size="large"
                                            color="error"
                                            onClick={() => eliminarExtra(index)}
                                            sx={{ ml: 2, width: "10%" }}
                                            disabled={extra.obligado}
                                        >
                                            Eliminar
                                        </Button>
                                    </Box>
                                ))}

                                <Button variant="contained" sx={{ ml: "80%", width: "10%" }} onClick={agregarExtra}>
                                    Agregar extra
                                </Button>
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} />
                                <Typography textAlign={"left"} variant="h6" sx={{ ml: "10%" }} >
                                    <strong>Descuentos disponibles</strong>
                                </Typography>
                                {descuentos.map((item, index) => (
                                    <div key={item.nombre} >
                                        <Typography sx={{ ml: "15%", width: "33%", mt: 2, mb: 1 }} style={{ display: "inline-block" }}>
                                            {item.nombre} (${item.valor})
                                        </Typography>
                                        <label>
                                            Precio de venta
                                            <input
                                                type="checkbox"
                                                checked={pvValues[index]?.checkPv || false}
                                                onChange={(e) => handleCheckPvChange(index, e.target.checked)}
                                            />
                                        </label>
                                        {/* Checkbox Entrada */}
                                        <label style={{ marginLeft: '10px' }}>
                                            Entrada
                                            <input
                                                type="checkbox"
                                                checked={pvValues[index]?.checkEntrada || false}
                                                onChange={(e) => handleCheckEntradaChange(index, e.target.checked)}
                                            />
                                        </label>
                                        <TextField
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={pvValues[index]?.valor || ''}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            sx={{ mt: 1, mb: 1, ml: "3%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        {/* Checkbox PV */}

                                    </div>
                                ))}

                                {/* Campos para Descuento, Precio Especial y Matrícula en una línea */}


                                {/* Descuento */}

                                <FormControlLabel
                                    sx={{ width: "38%", ml: "15%", mr: esDescuentoAutorizacion ? "0%" : "50%" }}
                                    control={
                                        <Checkbox
                                            checked={esDescuentoAutorizacion}
                                            onChange={() => {
                                                setEsDescuentoAutorizacion(!esDescuentoAutorizacion); setRegistroCotizador({
                                                    ...registroCotizador,
                                                    scoDesc1Valor: 0,
                                                    scoComercialNombre: "",
                                                    scoComercialTelefono: "",
                                                    scoComercialCorreo: "",
                                                    scoComercialObservacion: "",
                                                    scoComercialEstado: 0,
                                                });

                                            }}
                                        />
                                    }
                                    label="Agregar descuento bajo autorización"
                                />
                                {esDescuentoAutorizacion ? <>


                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name="scoTerminos"
                                        label="%"
                                        value={(parametroCotizador.spcPvp > 0 ? ((registroCotizador.scoDesc1Valor * 100) / parametroCotizador.spcPvp) : 0).toFixed(2)}
                                        sx={{ mt: 1, mb: 1, width: '16%', mr: '1%', ml: '0%' }}
                                        style={{ backgroundColor: "rgb(242,245,242)" }} InputProps={{
                                            readOnly: true,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            },
                                        }}

                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name="scoDesc1Valor"
                                        label="$"
                                        value={registroCotizador.scoDesc1Valor}
                                        onChange={(e) => {
                                            setRegistroCotizador({
                                                ...registroCotizador,
                                                scoDesc1Valor: Number(e.target.value),
                                            });
                                        }}
                                        sx={{ mt: 1, mb: 1, width: '16%', mr: '1%', ml: '0%' }}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            },
                                        }}

                                    />

                                </> : <></>}





                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '55%',
                                        ml: '15%'  // Alineación de la izquierda
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Descuento total:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(registroCotizador.scoDesc2Valor + registroCotizador.scoDesc1Valor).replace("$", "$  ")}
                                    sx={{ mt: 1, mb: 1, width: '16%', mr: '1%', ml: '0%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                {/* Precio Especial */}
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'  // Alineación de la izquierda
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Precio Especial:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(precioEspecial).replace("$", "$  ")}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                />

                                {/* Matrícula */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Matrícula:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={valorMatricula.replace("$", "$  ")} // Mostramos el valor de la matrícula calculada
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%" }} variant="h6" >
                                    <strong>Formas de pago</strong>
                                </Typography>

                                {/* Campo para el Precio Especial en una línea */}

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                    variant="h6"
                                >
                                    Contado:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(precioEspecial)).replace("$", "$  ")}
                                    onChange={(e) => setPrecioEspecial(parseFloat(e.target.value))}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    variant="h6"
                                >
                                    Crédito:
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Porcentaje de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={porcentaje}
                                    onChange={(e) => { setPorcentaje(e.target.value); setValidarCambios(false); }}
                                    onBlur={handlePorcentajeBlur}
                                    label="%"
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={entrada}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    label="$"
                                    onChange={(e) => {
                                        setValidarCambios(false);
                                        setEntrada(e.target.value);
                                    }}
                                    onBlur={handleEntradaBlur}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    InputProps={{

                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto a Financiar:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(montoAFinanciar)).replace("$", "$  ")}
                                    onChange={(e) => setMontoAFinanciar(e.target.value)}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />


                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 3 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '16%',
                                        ml: '10%',
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Términos de negociacion:
                                </Typography>
                                {/* <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '5%',
                                        fontSize: 10,
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    {String(200 - registroCotizador.scoTerminos.length) + " / 200"}
                                </Typography> */}
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name="scoTerminos"
                                    label= {String(200 - registroCotizador.scoTerminos.length) + " / 200"}
                                    multiline
                                    minRows={3}
                                    value={registroCotizador.scoTerminos}
                                    inputProps={{ maxLength: 200 }}
                                    onChange={(e) => {
                                        setRegistroCotizador({
                                            ...registroCotizador,
                                            scoTerminos: e.target.value,
                                        });
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '64%' }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}

                                />

                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%" }} variant="h6" >
                                    <strong>Cuotas mensuales</strong>
                                </Typography>

                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ ml: "5%", mr: "5%" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={registroCotizador.scoMuestra12Meses === 1 ? true : false}
                                                onChange={(e) => {
                                                    setRegistroCotizador({
                                                        ...registroCotizador,
                                                        scoMuestra12Meses: e.target.checked ? 1 : 0,
                                                    })
                                                }}
                                            />
                                        }
                                        label="12 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={registroCotizador.scoMuestra24Meses === 1 ? true : false}
                                                onChange={(e) => {
                                                    setRegistroCotizador({
                                                        ...registroCotizador,
                                                        scoMuestra24Meses: e.target.checked ? 1 : 0,
                                                    })
                                                }}
                                            />
                                        }
                                        label="24 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={registroCotizador.scoMuestra36Meses === 1 ? true : false}
                                                onChange={(e) => {
                                                    setRegistroCotizador({
                                                        ...registroCotizador,
                                                        scoMuestra36Meses: e.target.checked ? 1 : 0,
                                                    })
                                                }}
                                            />
                                        }
                                        label="36 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={registroCotizador.scoMuestra48Meses === 1 ? true : false}
                                                onChange={(e) => {
                                                    setRegistroCotizador({
                                                        ...registroCotizador,
                                                        scoMuestra48Meses: e.target.checked ? 1 : 0,
                                                    })
                                                }}
                                            />
                                        }
                                        label="48 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={registroCotizador.scoMuestra60Meses === 1 ? true : false}
                                                onChange={(e) => {
                                                    setRegistroCotizador({
                                                        ...registroCotizador,
                                                        scoMuestra60Meses: e.target.checked ? 1 : 0,
                                                    })
                                                }}
                                            />
                                        }
                                        label="60 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={registroCotizador.scoMuestra72Meses === 1 ? true : false}
                                                onChange={(e) => {
                                                    setRegistroCotizador({
                                                        ...registroCotizador,
                                                        scoMuestra72Meses: e.target.checked ? 1 : 0,
                                                    });
                                                    console.log("mi fi autorizacion es >>", esFIAutorizacion)
                                                }}
                                            />
                                        }
                                        label="72 meses"
                                    />
                                </Box>

                                <TextField
                                    size="small"
                                    margin="normal"
                                    type="number"
                                    disabled={registroCotizador.scoMuestra12Meses === 1 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    name="scoValor12Meses"
                                    value={registroCotizador.scoValor12Meses}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '11%', ml: "4%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    type="number"
                                    disabled={registroCotizador.scoMuestra24Meses === 1 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    name="scoValor24Meses"
                                    value={registroCotizador.scoValor24Meses}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    type="number"
                                    disabled={registroCotizador.scoMuestra36Meses === 1 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    name="scoValor36Meses"
                                    value={registroCotizador.scoValor36Meses}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    type="number"
                                    disabled={registroCotizador.scoMuestra48Meses === 1 ? false : true}
                                    label={"$"}
                                    name="scoValor48Meses"
                                    onChange={handleChangeCuotas}
                                    value={registroCotizador.scoValor48Meses}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    type="number"
                                    disabled={registroCotizador.scoMuestra60Meses === 1 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    name="scoValor60Meses"
                                    value={registroCotizador.scoValor60Meses}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />

                                <TextField
                                    size="small"
                                    margin="normal"
                                    type="number"
                                    disabled={registroCotizador.scoMuestra72Meses === 1 ? false : true}
                                    label={"$"}
                                    name="scoValor72Meses"
                                    onChange={handleChangeCuotas}
                                    value={registroCotizador.scoValor72Meses}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />


                                {/* Título */}
                                {/* <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} /> */}
                                <Typography textAlign={"left"} variant="h6" sx={{ ml: "10%" }}>
                                    <strong>Seguro vehicular</strong>
                                </Typography>

                                <Typography style={{ display: 'inline-block' }} sx={{ width: "10%", ml: "18%", mt: 2, mb: 1 }}>Vehículo</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(seguro)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '12%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />


                                <Typography style={{ display: 'inline-block' }} sx={{ width: "10%", ml: "10%", mt: 2, mb: 1 }}>12 Cuotas</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(cuota)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '12%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={async () => {
                                        await calcularValores(); await seteoTodoRegistro(); await setearDatosCuotas(precioEspecial, Number(entrada));
                                        setEsFIAutorizacion(false);
                                    }}
                                    sx={{ ml: "4%", width: "13%" }}
                                    disabled={precioEspecial > 0 ? false : true}
                                >
                                    Calcular
                                </Button>


                                {registroCotizador.scoDesc1Valor > 0 && esDescuentoAutorizacion ? <>
                                    <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 3 }} /> <Typography textAlign={"left"} variant="h6" />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "15%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Aprobar descuentos:
                                    </Typography>
                                    <TextField
                                        label="Nombre"
                                        size="small"
                                        margin="normal"
                                        value={registroCotizador.scoComercialNombre}
                                        sx={{ mt: 1, mb: 1, width: "24%" }}
                                    />
                                    <TextField
                                        label="Correo"
                                        size="small"
                                        margin="normal"
                                        value={registroCotizador.scoComercialCorreo}
                                        sx={{ mt: 1, mb: 1, ml: "1%", width: "24%" }}
                                    />
                                    <Button
                                        sx={{ mt: 1, mb: 1, width: "13%", marginLeft: "2%", position: 'relative' }}
                                        onClick={() => { getvListaAprueba(Number(dataNegocio.idZona), Number(dataNegocio.idbodega), 1, "COMERCIAL", 1) }}
                                    >
                                        Buscar
                                    </Button>
                                </> : <></>}

                                {esFIAutorizacion ? <>
                                    <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 3 }} /> <Typography textAlign={"left"} variant="h6" />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "15%",
                                            ml: "10%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Aprobar F&I:
                                    </Typography>
                                    <TextField
                                        label="Nombre"
                                        size="small"
                                        margin="normal"
                                        name="spcExtra4Descripcion"
                                        value={registroCotizador.scoFiNombre}
                                        sx={{ mt: 1, mb: 1, width: "24%" }}
                                    />
                                    <TextField
                                        label="Correo"
                                        size="small"
                                        margin="normal"
                                        name="spcExtra4Valor"
                                        value={registroCotizador.scoFiCorreo}
                                        sx={{ mt: 1, mb: 1, ml: "1%", width: "24%" }}
                                    />
                                    <Button
                                        sx={{ mt: 1, mb: 1, width: "13%", marginLeft: "2%", position: 'relative' }}
                                        onClick={() => { getvListaAprueba(Number(dataNegocio.idZona), Number(dataNegocio.idbodega), 1, "F&I", 1) }}
                                    >
                                        Buscar
                                    </Button>
                                </> : <></>}




                                <Modal
                                    open={openModalComercialAutorizacion}
                                    onClose={(e) => setOpenModalComercialAutorizacion(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleModal}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Seleccione un aprobador
                                        </Typography>
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nombre</TableCell>
                                                        <TableCell>Correo</TableCell>
                                                        <TableCell>Seleccionar</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listaAutorizaRows.map((row) => (
                                                        <TableRow key={row.scaId}>
                                                            <TableCell component="th" scope="row">
                                                                {row.scaNombre}
                                                            </TableCell>
                                                            <TableCell>{row.scaCorreo}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={() => {
                                                                        setRegistroCotizador({
                                                                            ...registroCotizador,
                                                                            scoComercialNombre: row.scaNombre,
                                                                            scoComercialTelefono: row.scaTelefono,
                                                                            scoComercialCorreo: row.scaCorreo,
                                                                            scoComercialObservacion: "",
                                                                            scoComercialEstado: 1,
                                                                        });
                                                                        setOpenModalComercialAutorizacion(false);
                                                                    }}
                                                                >
                                                                    ✓
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Modal>

                                <Modal
                                    open={openModalFIAutorizacion}
                                    onClose={(e) => setOpenModalFIAutorizacion(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleModal}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Seleccione un aprobador
                                        </Typography>
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nombre</TableCell>
                                                        <TableCell>Correo</TableCell>
                                                        <TableCell>Seleccionar</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listaAutorizaRows.map((row) => (
                                                        <TableRow key={row.scaId}>
                                                            <TableCell component="th" scope="row">
                                                                {row.scaNombre}
                                                            </TableCell>
                                                            <TableCell>{row.scaCorreo}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={() => {
                                                                        setRegistroCotizador({
                                                                            ...registroCotizador,
                                                                            scoFiNombre: row.scaNombre,
                                                                            scoFiTelefono: row.scaTelefono,
                                                                            scoFiCorreo: row.scaCorreo,
                                                                            scoFiObservacion: "",
                                                                            scoFiEstado: 1,
                                                                        });
                                                                        setOpenModalFIAutorizacion(false);
                                                                    }}
                                                                >
                                                                    ✓
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Modal>









                                <div>
                                    <Button

                                        onClick={async () => {
                                            if (!validarCampos()) {
                                                return; // Detiene la ejecución si hay campos vacíos
                                            }
                                            try {
                                                await seteoRegistro(registroCotizador)
                                            } catch (error) {
                                                console.error("Error al enviar los datos:", error);
                                            } finally {
                                                setLoading(false);
                                            }
                                        }}
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 1,
                                            width: "12%",
                                            marginLeft: "44%",
                                            position: 'relative',
                                        }}
                                        //disabled={loading || validarCambios || esFinValidacion ? false : true} //|| validarCambios ? false : true //setLoading(true);
                                        disabled={loading === true ? true : validarCambios === false ? true : (esDescuentoAutorizacion === true && registroCotizador.scoComercialCorreo.length < 10) ? true : (esFIAutorizacion === true && registroCotizador.scoFiCorreo.length < 10) ? true : false}
                                    //</div>  loading === true ? true : validarCambios ? false : true
                                    >
                                        {loading ? (
                                            <>
                                                <CircularProgress size={24} sx={{ position: 'absolute' }} />
                                                {"espere.."}
                                            </>
                                        ) : (
                                            "Enviar Cotización"
                                        )}
                                    </Button>

                                    {error && <div style={{ color: 'red' }}>{error}</div>} {/* Mostrar error si ocurre */}
                                </div>
                            </>}

                            <Dialog
                                open={openDialog}
                                //onClose={handleCloseDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Datos guardados correctamente
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        //href="/politicacomercial/lista"
                                        onClick={function (event) { }}
                                    //autoFocus
                                    >
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={openDialogEntrada}
                                //onClose={handleCloseDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        El procentaje de entrada no puede ser menor a 15%
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        //href="/politicacomercial/lista"
                                        onClick={function (event) { setOpenDialogEntrada(false); setPorcentaje("15") }}
                                    //autoFocus
                                    >
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={openDialogTelefono}
                                //onClose={handleCloseDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Alerta"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        No olvides verificar el destinatario - Correo y Teléfono -
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        //href="/politicacomercial/lista"
                                        onClick={function (event) { setOpenDialogTelefono(false) }}
                                    //autoFocus
                                    >
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Paper>

                    </Grid>
                </Grid>
                <Dialog
                    open={openDialogEnviadoOk}
                    //onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Cotización guardada con éxito!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            href={`/cotizador/cotizacion/${String(nuevoId)}`}
                            onClick={function (event) {
                                setOpenDialogEnviadoOk(false);
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDialogEnviadoError}
                    //onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Ocurrio un error
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={function (event) {
                                setOpenDialogEnviadoError(false);
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
