import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    AnoType,
    CrmAsesorType,
    MesType,
    VSateliteUsuarioType,
    vventassatelitecrmType,
} from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

export const ListaNegociosTestPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getAsesores();
            getUsuario(account.username);

            // Verifica el tipo de dispositivo al cargar la página
            checkDeviceType();
        } else {
            setName("");
        }
    }, [account]);

    const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
        id: 0,
        nombre: "",
        correo: "",
        idMarca: 0,
        idBodega: 0,
        usuSupervisor: 0,
        usuJefeAgencia: 0,
        agenciaCrm: "",
        marca: "",
        bodega: "",
    });

    const [openDialog, setOpenDialog] = React.useState(false);
    const [buscarId, setBuscarId] = React.useState("");
    const dataBuscarId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBuscarId(e.target.value);
    };

    async function getUsuario(correo: string) {
        try {
            await axios
                .get<VSateliteUsuarioType>(
                    "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
                    correo
                )
                .then((response) => {
                    console.log("mi user es >>>>>>", response.data);
                    setSateliteUsuario(response.data);
                    getAsesoresAgencia(response.data.idBodega);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(sateliteUsuario, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;

    const [anoConsulta, setAnoConsulta] = React.useState(anioActual);
    const [mesConsulta, setMesConsulta] = React.useState(mesActual);
    const [asesorConsulta, setAsesorConsulta] = React.useState(0);
    const [mostrar, setMostrar] = React.useState<boolean>(false);

    const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnoConsulta(Number(event.target.value));
        setMostrar(false);
    };
    const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMesConsulta(Number(event.target.value));
        setMostrar(false);
    };
    const handleChangeAsesor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAsesorConsulta(Number(event.target.value));
        getAsesor(Number(event.target.value));
        setMostrar(false);
    };

    const listaAnos: AnoType[] = [
        { id: 2023, ano: 2023 },
        { id: 2024, ano: 2024 },
        { id: 2025, ano: 2025 },
    ];
    const listaMes: MesType[] = [
        { id: 1, mes: "Enero" },
        { id: 2, mes: "Febrero" },
        { id: 3, mes: "Marzo" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Mayo" },
        { id: 6, mes: "Junio" },
        { id: 7, mes: "Julio" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Septiembre" },
        { id: 10, mes: "Octubre" },
        { id: 11, mes: "Noviembre" },
        { id: 12, mes: "Diciembre" },
    ];

    const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
    const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
    const listaListado: vventassatelitecrmType[] = [];


    const [dataLista, setDataLista] =
        useState<vventassatelitecrmType[]>(listaListado);

    const [dataAsesores, setDataAsesores] = useState<CrmAsesorType[]>([]);
    const [dataAsesoresAgencia, setDataAsesoresAgencia] = useState<CrmAsesorType[]>([]);
    const [dataAsesor, setDataAsesor] = useState<CrmAsesorType>({
        id: 0,
        nit: "",
        nombre: "",
        grupo: "",
        estado: 0,
        idCotBodega: 0,
        cargo: "",
        anulado: 0,
        ownerId: "",
        correo: "",
    });

    async function getAsesores() {
        try {
            await axios
                .get<CrmAsesorType[]>(
                    "https://apisatelite.azurewebsites.net/vcrmasesor/todo"
                )
                .then((response) => {
                    setDataAsesores(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getAsesoresAgencia(idBodega: number) {
        try {
            await axios
                .get<CrmAsesorType[]>(
                    "https://apisatelite.azurewebsites.net/vcrmasesor/bodega/" +
                    String(idBodega)
                )
                .then((response) => {
                    setDataAsesoresAgencia(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getAsesor(id: number) {
        try {
            await axios
                .get<CrmAsesorType>(
                    "https://apisatelite.azurewebsites.net/vcrmasesor/" + String(id)
                )
                .then((response) => {
                    setDataAsesor(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListado(usuario: string) {
        try {
            await axios
                .get<vventassatelitecrmType[]>(
                    "https://apisatelite.azurewebsites.net/vventassatelitecrm/consulta/mes/" +
                    usuario +
                    "/" +
                    String(anoConsulta) +
                    "/" +
                    String(mesConsulta)
                )
                .then((response) => {
                    setDataLista(response.data);
                    setMostrar(true);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataLista, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListaID() {
        try {
            await axios
                .get<vventassatelitecrmType[]>(
                    "https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" +
                    buscarId
                )
                .then((response) => {
                    setDataLista(response.data);
                    setMostrar(true);
                    setOpenDialog(false);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataLista, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListadoAgencia(id_bodega: number) {
        try {
            await axios
                .get<vventassatelitecrmType[]>(
                    "https://apisatelite.azurewebsites.net/vventassatelitecrm/agencia/mes/" +
                    String(id_bodega) +
                    "/" +
                    String(anoConsulta) +
                    "/" +
                    String(mesConsulta)
                )
                .then((response) => {
                    setDataLista(response.data);
                    setMostrar(true);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataLista, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const columns: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "idcrm",
            headerName: "ID CRM",
            editable: false,
            width: 100,
        },
        {
            headerClassName: "theme-header",
            field: "createdate",
            headerName: "Fecha",
            editable: false,
            width: 100,
            valueGetter: ({ row }) => {
                return String(row.createdate).substring(0, 10);
            },
        },
        {
            headerClassName: "theme-header",
            field: "cedula",
            headerName: "Cédula",
            editable: false,
            width: 100,
        },
        {
            headerClassName: "theme-header",
            field: "cliente",
            headerName: "Cliente",
            editable: false,
            width: 275,
        },
        {
            headerClassName: "theme-header",
            field: "asesor",
            headerName: "Asesor",
            editable: false,
            width: 130,
        },
        {
            headerClassName: "theme-header",
            field: "agencia",
            headerName: "Agencia",
            editable: false,
            width: 200,
        },
        {
            headerClassName: "theme-header",
            field: "testdrive",
            headerName: "TestDrive",
            editable: false,
            width: 80,
            valueGetter: ({ row }) => {
                return Number(row.testdrive) === 1 ? "Si":"No";
            },
        },        
        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Detalle",
            sortable: false,
            width: 80,
            renderCell: (params) => {
                //{params.row.Id}
                return (
                    <Button
                        href={`/testdrive/salida/${params.row.idcrm}`}
                    >
                        Abrir{" "}
                    </Button>
                );
            },
        },
    ];

    const columnsMovil: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "idcrm",
            headerName: "ID CRM",
            editable: false,
            width: 120,
        },

        {
            headerClassName: "theme-header",
            field: "cedula",
            headerName: "Cédula",
            editable: false,
            width: 120,
        },
        {
            headerClassName: "theme-header",
            field: "cliente",
            headerName: "Cliente",
            editable: false,
            width: 200,
        },
        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Cotizar",
            sortable: false,
            width: 80,
            renderCell: (params) => {
                //{params.row.Id}
                return (
                    <Button
                        href={`/testdrive/salida/${params.row.idcrm}`}
                    >
                        Abrir{" "}
                    </Button>
                );
            },
        },
    ];

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Negocios por Asesor
                            </Typography>
                        </Paper>

                        {/* insertar validacion para mostrar */}
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                                mb: 4,
                            }}
                        >
                            {isMobile ? (<><Typography
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "30%",
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Año:
                            </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    margin="normal"
                                    value={anoConsulta}
                                    onChange={handleChangeAno}
                                    sx={{ mt: 0.5, mb: 0.5, width: "70%" }}
                                >
                                    {dataAno.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.ano}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: "30%",
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Mes:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    margin="normal"
                                    value={mesConsulta}
                                    onChange={handleChangeMes}
                                    sx={{ mt: 0.5, mb: 0.5, width: "70%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {dataMes.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.mes}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                {sateliteUsuario.usuSupervisor === 1 ? (
                                    <>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "30%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Asesor:
                                        </Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            size="small"
                                            margin="normal"
                                            value={asesorConsulta}
                                            onChange={handleChangeAsesor}
                                            sx={{ mt: 0.5, mb: 0.5, width: "70%" }}
                                            SelectProps={{
                                                MenuProps: {
                                                    disableScrollLock: true,
                                                    style: {
                                                        maxHeight: 450,
                                                    },
                                                },
                                            }}
                                        >
                                            {dataAsesores.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.nombre}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                                ) : (
                                    <>
                                        {/* para que se muestre los asesores de esa bodega */}

                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "30%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Asesor:
                                        </Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            size="small"
                                            margin="normal"
                                            value={asesorConsulta}
                                            onChange={handleChangeAsesor}
                                            sx={{ mt: 0.5, mb: 0.5, width: "70%" }}
                                            SelectProps={{
                                                MenuProps: {
                                                    disableScrollLock: true,
                                                    style: {
                                                        maxHeight: 450,
                                                    },
                                                },
                                            }}
                                        >
                                            {dataAsesoresAgencia.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.nombre}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </>
                                )}

                                <Button
                                    variant="contained"
                                    sx={{
                                        marginLeft: "20%",
                                        width: "60%",
                                        mt: 2,
                                        mb: 2,
                                        backgroundColor: "rgba(21,57,105,0.75)",
                                    }}
                                    onClick={function (event) {
                                        {
                                            sateliteUsuario.usuSupervisor === 1 &&
                                                dataAsesor.correo.length > 0
                                                ? getListado(dataAsesor.correo)
                                                : sateliteUsuario.usuJefeAgencia === 1
                                                    ? getListadoAgencia(sateliteUsuario.idBodega)
                                                    : getListado(username);
                                        }
                                    }}
                                >
                                    Consultar
                                </Button>
                                <Divider sx={{ marginLeft: "35%", marginRight: "35%" }} />
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginLeft: "20%",
                                        width: "60%",
                                        mt: 2,
                                        mb: 1,
                                        backgroundColor: "rgba(21,57,105,0.75)",
                                    }}
                                    onClick={function (event) {
                                        setOpenDialog(true);
                                    }}
                                >
                                    Buscar por ID
                                </Button></>) : (<><Typography
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: "5%",
                                        marginLeft: "27%",
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Año:
                                </Typography>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        size="small"
                                        margin="normal"
                                        value={anoConsulta}
                                        onChange={handleChangeAno}
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "15%" }}
                                    >
                                        {dataAno.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.ano}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Typography
                                        sx={{
                                            mt: 1,
                                            mb: 1,
                                            width: "6%",
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Mes:
                                    </Typography>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        size="small"
                                        margin="normal"
                                        value={mesConsulta}
                                        onChange={handleChangeMes}
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "15%" }}
                                        SelectProps={{
                                            MenuProps: {
                                                disableScrollLock: true,
                                                style: {
                                                    maxHeight: 300,
                                                },
                                            },
                                        }}
                                    >
                                        {dataMes.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.mes}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {sateliteUsuario.usuSupervisor === 1 ? (
                                        <>
                                            <Typography
                                                sx={{
                                                    mt: 1,
                                                    mb: 1,
                                                    width: "5%",
                                                    marginLeft: "27%",
                                                }}
                                                style={{ display: "inline-block" }}
                                            >
                                                Asesor:
                                            </Typography>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                size="small"
                                                margin="normal"
                                                value={asesorConsulta}
                                                onChange={handleChangeAsesor}
                                                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "41%" }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        disableScrollLock: true,
                                                        style: {
                                                            maxHeight: 450,
                                                        },
                                                    },
                                                }}
                                            >
                                                {dataAsesores.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nombre}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    ) : (
                                        <>

                                            {/* para que se muestre los asesores de esa bodega */}

                                            <Typography
                                                sx={{
                                                    mt: 1,
                                                    mb: 1,
                                                    width: "5%",
                                                    marginLeft: "27%",
                                                }}
                                                style={{ display: "inline-block" }}
                                            >
                                                Asesor:
                                            </Typography>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                size="small"
                                                margin="normal"
                                                value={asesorConsulta}
                                                onChange={handleChangeAsesor}
                                                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "41%" }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        disableScrollLock: true,
                                                        style: {
                                                            maxHeight: 450,
                                                        },
                                                    },
                                                }}
                                            >
                                                {dataAsesoresAgencia.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nombre}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    )}

                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginLeft: "42%",
                                            width: "16%",
                                            mt: 2,
                                            mb: 2,
                                            backgroundColor: "rgba(21,57,105,0.75)",
                                        }}
                                        onClick={function (event) {
                                            {
                                                sateliteUsuario.usuSupervisor === 1 &&
                                                    dataAsesor.correo.length > 0
                                                    ? getListado(dataAsesor.correo)
                                                    : getListado(dataAsesor.correo)
                                                    
                                                    // sateliteUsuario.usuJefeAgencia === 1
                                                    //     ? getListadoAgencia(sateliteUsuario.idBodega)
                                                    //     : getListado(username);
                                            }
                                        }}
                                    >
                                        Consultar
                                    </Button>
                                    <Divider sx={{ marginLeft: "35%", marginRight: "35%" }} />
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginLeft: "42%",
                                            width: "16%",
                                            mt: 2,
                                            mb: 1,
                                            backgroundColor: "rgba(21,57,105,0.75)",
                                        }}
                                        onClick={function (event) {
                                            setOpenDialog(true);
                                        }}
                                    >
                                        Buscar por ID
                                    </Button></>)}


                            <Dialog
                                open={openDialog}
                                onClose={function (event) {
                                    setOpenDialog(false);
                                }}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Buscar"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <TextField
                                            name="nuevoPVP"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            fullWidth
                                            onChange={dataBuscarId}
                                            label="ID CRM"
                                            defaultValue={buscarId}
                                            sx={{ mt: 1, mb: 1, width: "100%" }}
                                        />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={function (event) {
                                            getListaID();
                                        }}
                                        autoFocus
                                    >
                                        Consultar
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {mostrar ? (
                                <>
                                    <Box
                                        sx={{
                                            "& .theme-header": {
                                                backgroundColor: "rgba(21,57,105,0.75)",
                                                color: "rgba(255,255,255,1)",
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            //getRowId={(row) => row.id}
                                            sx={{ height: 650 }}
                                            editMode="row"
                                            rows={dataLista}
                                            columns={isMobile ? columnsMovil : columns}
                                            disableRowSelectionOnClick
                                            disableColumnFilter
                                            disableColumnSelector
                                            disableDensitySelector
                                            slots={{ toolbar: GridToolbarQuickFilter, }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                },
                                                pagination: {
                                                    labelRowsPerPage: "Registros por página:",
                                                },
                                            }}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            pageSizeOptions={[10, 25, 50]}
                                            localeText={{
                                                footerTotalRows: false,
                                                noRowsLabel: "No se ha encontrado datos.",
                                                noResultsOverlayLabel:
                                                    "No se ha encontrado ningún resultado",
                                                toolbarColumns: "Columnas",
                                                toolbarColumnsLabel: "Seleccionar columnas",
                                                toolbarFilters: "Filtros",
                                                toolbarFiltersLabel: "Ver filtros",
                                                toolbarFiltersTooltipHide: "Quitar filtros",
                                                toolbarFiltersTooltipShow: "Ver filtros",
                                                toolbarQuickFilterPlaceholder: "Buscar",
                                            }}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <></>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
