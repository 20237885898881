import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
    AnoType,
    MesType,
    SateliteProveeduriaItemType,
    SateliteProveeduriaPedidoType,
    SateliteProveeduriaResponsableType,
} from "../../types";
import logoSatelite from "../../assets/logo_satelite.png";
import { GridCellParams } from "@mui/x-data-grid";
import path from "path";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});



export const PedidoProveduriaPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const [esNuevo, setEsNuevo] = React.useState(false);

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getListaItems();
            getPresupuesto(account.username);
        } else {
            setName("");
        }
    }, [account]);

    const listaAnos: AnoType[] = [
        { id: 2025, ano: 2025 },
    ];
    const listaMes: MesType[] = [
        { id: 1, mes: "Enero" },
        { id: 2, mes: "Febrero" },
        { id: 3, mes: "Marzo" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Mayo" },
        { id: 6, mes: "Junio" },
        { id: 7, mes: "Julio" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Septiembre" },
        { id: 10, mes: "Octubre" },
        { id: 11, mes: "Noviembre" },
        { id: 12, mes: "Diciembre" },
    ];

    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
    const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
    const [ano, setAno] = React.useState(anioActual);
    const [mes, setMes] = React.useState(mesActual);
    const [observaciones, setObservaciones] = React.useState<string>("");
    const [mostrar, setMostrar] = React.useState<boolean>(false);
    const [mostrarTodo, setMostrarTodo] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalItem, setOpenModalItem] = React.useState(false);
    const [openModalResumen, setOpenModalResumen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogErrorPresupuesto, setOpenDialogErrorPresupuesto] = React.useState(false);
    const [openDialogErrorFecha, setOpenDialogErrorFecha] = React.useState(false);
    const [openDialogPedidoCerrado, setOpenDialogPedidoCerrado] = React.useState(false);
    const [openDialogOK, setOpenDialogOk] = React.useState(false);
    const [pedidoCerrado, setPedidoCerrado] = React.useState(false);
    const [listaPedido, setListaPedido] = useState<SateliteProveeduriaPedidoType[]>([]);
    const [listaItems, setListaItems] = useState<SateliteProveeduriaItemType[]>([]);
    const totalPrpPvp = listaPedido.reduce((sum, pedido) => sum + pedido.prpPvp, 0);

    const [registro, setRegistro] = useState<SateliteProveeduriaPedidoType>({
        prpId: 0,
        prrId: 0,
        priId: 0,
        prpAno: 0,
        prpMes: 0,
        prpFecha: new Date(),
        priNombre: "",
        prpCantidad: 0,
        prpPvp: 0,
        prpCorreo: "",
        prrObservaciones: "",
        prrProcesado: 0,
    });

    const [item, setItem] = useState<SateliteProveeduriaItemType>({
        priId: 0,
        priNombre: "",
        priPvp: 0,
        priPresentacion: "",
        priObservaciones: "",
    });

    const [presupuesto, setPresupuesto] = useState<SateliteProveeduriaResponsableType>({
        prrId: 0,
        prrNombre: "",
        prrCorreo: "",
        prrNotifica: "",
        idBodega: 0,
        prrLinea: "",
        prrPresupuestoMensual: 0,
        prrObservaciones: "",
        prrDiaHasta: 0,
        prrDiaEntrega: 0
    });

    const construirFecha = (anio: number, mes: number, dia: number): Date => {
        // El mes en el objeto Date es indexado desde 0 (Enero = 0, Diciembre = 11)
        return new Date(anio, mes - 1, dia, 23, 59);
    };

    const fechaEntrega = construirFecha(anioActual, mes + 1, presupuesto.prrDiaEntrega);

    const fechaHasta = construirFecha(anioActual, mes, presupuesto.prrDiaHasta);


    const styleModal = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const styleModalItem = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 700,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };


    const handleChangeRegistro = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRegistro({
            ...registro,
            [e.target.name]: e.target.value,
        });

    };


    const columns: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "priNombre",
            headerName: "Descripción",
            editable: false,
            width: 275,
        },
        {
            headerClassName: "theme-header",
            field: "prpCantidad",
            headerName: "Cantidad",
            editable: false,
            width: 90,
            align: "right",
        },
        {
            headerClassName: "theme-header",
            field: "pvpUnitario",
            headerName: "PVP Uni",
            editable: false,
            width: 90,
            align: "right",
            valueGetter: ({ row }) => {
                return formatter.format(Number(Number(row.prpPvp) / Number(row.prpCantidad))).replace("$", "$  ")
            },
        },
        {
            headerClassName: "theme-header",
            field: "prpPvp",
            headerName: "PVP Total",
            editable: false,
            width: 90,
            align: "right",
            valueGetter: ({ row }) => {
                return formatter.format(Number(row.prpPvp)).replace("$", "$  ")
            },
        },
        {
            headerClassName: "theme-header",
            field: "prrObservaciones",
            headerName: "Observaciones",
            editable: false,
            width: 250,
        },
        {
            headerClassName: "theme-header", field: "action",
            headerName: "Detalle",
            sortable: false,
            width: 90,
            align: "center",
            renderCell: (params) => {
                //{params.row.Id}
                return (
                    <Button
                        disabled={pedidoCerrado}
                        //href={`/clienterpa/${params.row.idcrm}`}
                        onClick={function (event) {
                            console.log(" mi row es >>", params.row)
                            setOpenModal(true);
                            setEsNuevo(false);
                            getRegistroPedido(params.row.prpId)
                        }}
                    >
                        Abrir
                    </Button>
                );
            },
        },
    ];

    const columnsItems: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "priNombre",
            headerName: "Descripción",
            editable: false,
            width: 300,
        },
        {
            headerClassName: "theme-header",
            field: "priPvp",
            headerName: "Pvp",
            editable: false,
            width: 90,
            align: "right",
            valueGetter: ({ row }) => {
                return formatter.format(Number(row.priPvp)).replace("$", "$  ")
            },
        },
        {
            headerClassName: "theme-header",
            field: "priPresentacion",
            headerName: "Presentacion",
            editable: false,
            width: 100,
        },
        {
            headerClassName: "theme-header",
            field: "priObservaciones",
            headerName: "Observaciones",
            editable: false,
            width: 100,
        },
        {
            headerClassName: "theme-header", field: "action",
            headerName: "Detalle",
            sortable: false,
            width: 100,
            align: "center",
            renderCell: (params) => {
                //{params.row.Id}
                return (
                    <Button
                        onClick={() => {
                            console.log(" mi row es >>", params.row)
                            getItem(Number(params.row.priId))
                        }}
                    >
                        Seleccionar
                    </Button>
                );
            },
        },
    ];

    function seteoItem(
        prpId: number,
        prrId: number,
        priId: number,
        prpAno: number,
        prpMes: number,
        prpFecha: Date,
        priNombre: string,
        prpCantidad: number,
        prpPvp: number,
        prpCorreo: string,
        prrObservaciones: string,
        prrProcesado: number,
    ) {

        if (esNuevo) {
            const nuevoRegistro: SateliteProveeduriaPedidoType = {
                prrId: prrId,
                priId: priId,
                prpAno: prpAno,
                prpMes: prpMes,
                prpFecha: prpFecha,
                priNombre: priNombre,
                prpCantidad: prpCantidad,
                prpPvp: prpPvp,
                prpCorreo: prpCorreo,
                prrObservaciones: prrObservaciones,
                prrProcesado: prrProcesado
            };
            console.log(
                "ruta a guardar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        } else {
            const nuevoRegistro: SateliteProveeduriaPedidoType = {
                prpId: prpId,
                prrId: prrId,
                priId: priId,
                prpAno: prpAno,
                prpMes: prpMes,
                prpFecha: prpFecha,
                priNombre: priNombre,
                prpCantidad: prpCantidad,
                prpPvp: prpPvp,
                prpCorreo: prpCorreo,
                prrObservaciones: prrObservaciones,
                prrProcesado: prrProcesado
            };
            console.log(
                "usuario a guardar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        }
    }

    async function postGuardarRegistro(registro: SateliteProveeduriaPedidoType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/sateliteproveeduriapedido", registro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("id guardado es >> ", response);
                    getListaPedido(ano, mes, username, false)
                    setOpenModal(false);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListaPedido(_ano: number, _mes: number, correo: string, esUseEffect: boolean) {
        try {
            await axios
                .get<SateliteProveeduriaPedidoType[]>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriapedido/consultar/" + String(_ano) + "/" + String(_mes) + "/" + correo
                )
                .then((response) => {
                    setListaPedido(response.data);
                    setMostrar(true);
                    console.log("estado de pedido >>", response.data[0].prrProcesado)
                    if (response.data[0].prrProcesado && esUseEffect) {
                        console.log("pedido se encuentra cerrado")
                        setPedidoCerrado(true);
                        setOpenDialogPedidoCerrado(true);
                    }

                   if( new Date() > fechaHasta ){
                    setOpenDialogErrorFecha(true) 
                    setPedidoCerrado(true)
                   }                     
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function postEnviaCorreo(destinatario: string, asunto: string, cuerpo: string) {
        const _registro: any = {
            destinatario: destinatario,
            asunto: asunto,
            cuerpo: cuerpo,
        };
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/correo/enviar", _registro, {
                    headers: headers,
                })
                .then((response) => {
                    setOpenDialogOk(true);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getActualizarEstado(_ano: number, _mes: number, correo: string, destinatario: string, asunto: string, cuerpo: string) {
        try {
            await axios
                .get<any>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriapedido/actualizarestado/" + String(_ano) + "/" + String(_mes) + "/" + correo
                )
                .then((response) => {
                    postEnviaCorreo(destinatario, asunto, cuerpo)
                    console.log("fue actualizado >>", response.data)
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getRegistroPedido(_id: number) {
        try {
            await axios
                .get<SateliteProveeduriaPedidoType>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriapedido/" + String(_id)
                )
                .then((response) => {
                    setRegistro(response.data);
                    getItem(response.data.priId);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListaItems() {
        try {
            await axios
                .get<SateliteProveeduriaItemType[]>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriaitem/consultar/"
                )
                .then((response) => {
                    setListaItems(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getItem(_id: number) {
        try {
            await axios
                .get<SateliteProveeduriaItemType>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriaitem/" + String(_id)
                )
                .then((response) => {
                    setItem(response.data);
                    setOpenModalItem(false);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getPresupuesto(correo: string) {
        try {
            await axios
                .get<SateliteProveeduriaResponsableType>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriaresponsable/correo/" + correo
                )
                .then((response) => {
                    setPresupuesto(response.data);
                    if (Number(response.data.prrId) > 0) {
                        setMostrarTodo(true)
                    } else {
                        setOpenDialogErrorPresupuesto(true);
                    }
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }


    const generateHTMLTable = (data: SateliteProveeduriaPedidoType[]): string => {
        const tableHeader = `
          <h2 style="text-align: center; margin-bottom: 20px;">Lista de Productos</h2>
          <h2 style="text-align: center; margin-bottom: 20px;">${presupuesto.prrLinea}</h2>
          <table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd; text-align: center;">
            <thead>
              <tr>
                <th style="padding: 10px; background-color: #f4f4f4; border-bottom: 1px solid #ddd;">Nombre</th>
                <th style="padding: 10px; background-color: #f4f4f4; border-bottom: 1px solid #ddd;">Cantidad</th>
                <th style="padding: 10px; background-color: #f4f4f4; border-bottom: 1px solid #ddd;">Precio Uni</th>
                <th style="padding: 10px; background-color: #f4f4f4; border-bottom: 1px solid #ddd;">Precio Tot</th>
                <th style="padding: 10px; background-color: #f4f4f4; border-bottom: 1px solid #ddd;">Observación</th>
              </tr>
            </thead>
            <tbody>
        `;

        const tableRows = data
            .map(
                (item) => `
              <tr>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.priNombre}</td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.prpCantidad}</td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">$ ${Number(item.prpPvp / item.prpCantidad).toFixed(2)}</td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">$ ${item.prpPvp.toFixed(2)}</td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.prrObservaciones}</td>
              </tr>
            `
            )
            .join("");

        const tableFooter = `
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" style="padding: 10px; text-align: center; background-color: #f9f9f9; border-top: 1px solid #ddd;">
                  Total de items: ${data.length} - Valor total:  $ ${totalPrpPvp.toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>
          <p style="text-align: center; margin-top: 20px;">Solicita: ${presupuesto.prrNombre} </p>
          <p style="text-align: center; margin-top: 20px;">Observaciones: ${observaciones} </p>
          <p style="text-align: center; margin-top: 20px;">Gracias por su gentil ayuda.</p>
        `;

        return tableHeader + tableRows + tableFooter;
    };


    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Pedido de Proveeduria
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "6%",
                                    marginLeft: "25%",
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Año:
                            </Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                margin="normal"
                                value={ano}
                                onChange={(e) => { setAno(Number(e.target.value)); setMostrar(false); setPedidoCerrado(false); }}
                                sx={{ mt: 0.5, mb: 0.5, mr: "5%", width: "14%" }}
                            >
                                {dataAno.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.ano}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "6%",
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Mes:
                            </Typography>
                            <TextField
                                id="outlined-select-currency"
                                size="small"
                                margin="normal"
                                value={mes === 1 ? "Enero" : mes === 2 ? "Febrero" : mes === 3 ? "Marzo" : mes === 4 ? "Abril" : mes === 5 ? "Mayo" : mes === 6 ? "Junio" : mes === 7 ? "Julio" : mes === 8 ? "Agosto" : mes === 9 ? "Septiembre" : mes === 10 ? "Octubre" : mes === 11 ? "Noviembre" : "Diciembre"}
                                onChange={(e) => { setMes(Number(e.target.value)); setMostrar(false); setPedidoCerrado(false); }}
                                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "14%" }}
                            />



                            <Button
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 1,
                                    width: "12%",
                                    marginLeft: "44%",
                                }}
                                onClick={() => { getListaPedido(ano, mes, username, true) }}
                            >
                                Consultar
                            </Button>
                        </Paper>
                        <Dialog
                            open={openDialog}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Datos guardados correctamente
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    //href="/politicacomercial/lista"
                                    onClick={function (event) {
                                        setOpenDialog(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openDialogPedidoCerrado}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"No puedes agregar más ítems"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    El pedido se encuentra cerrado y enviado!
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={function (event) {
                                        setOpenDialogPedidoCerrado(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openDialogOK}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    El pedido fue cerrado y enviado con exito!
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    href="/"
                                    onClick={function (event) {
                                        setOpenDialogOk(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openDialogErrorPresupuesto}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Tu usuario no cuenta con presupuesto asignado
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    href="/"
                                    onClick={function (event) {
                                        setOpenDialogErrorPresupuesto(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openDialogErrorFecha}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    No puedes registrar pedidos para la fecha indicada!
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    Fecha maxima: {fechaHasta.toLocaleDateString()}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={function (event) {
                                        setOpenDialogErrorFecha(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {mostrar ? (
                            <Paper
                                sx={{
                                    padding: "1.2em",
                                    borderRadius: "0.5em",
                                    backgroundColor: "rgb(255,255,255,0.95)",
                                    mt: 1,
                                    mb: 4,
                                }}
                            >

                                <Box
                                    sx={{
                                        "& .theme-header": {
                                            backgroundColor: "rgba(21,57,105,0.75)",
                                            color: "rgba(255,255,255,1)",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.prpId}
                                        sx={{ height: 500, ml: "10%", mr: "10%" }}
                                        editMode="row"
                                        rows={listaPedido}
                                        columns={columns}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 20 } },
                                        }}
                                        pageSizeOptions={[10, 20, 30]}
                                        slotProps={{
                                            pagination: {
                                                labelRowsPerPage: "Registros por página:",
                                            },
                                        }}
                                        localeText={{
                                            noRowsLabel: "No se ha encontrado datos.",
                                            noResultsOverlayLabel:
                                                "No se ha encontrado ningún resultado",
                                            toolbarColumns: "Columnas",
                                            toolbarColumnsLabel: "Seleccionar columnas",
                                            toolbarFilters: "Filtros",
                                            toolbarFiltersLabel: "Ver filtros",
                                            toolbarFiltersTooltipHide: "Quitar filtros",
                                            toolbarFiltersTooltipShow: "Ver filtros",
                                        }}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: "60%",
                                        ml: "15%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Tu presupuesto mensual asignado es: <strong> {formatter.format(presupuesto.prrPresupuestoMensual).replace("$", "$ ")}</strong>, valor actual: <strong> {formatter.format(totalPrpPvp).replace("$", "$ ")}</strong>
                                </Typography>
                                {pedidoCerrado ? <> <Typography
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: "60%",
                                        ml: "15%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Tu pedido se encuentra cerrado y será entregado hasta <strong> {fechaEntrega.toLocaleDateString()} </strong>
                                </Typography></> : <>
                                </>}

                                <Button
                                    disabled={pedidoCerrado}
                                    sx={{ width: "10%", mt: 2 }}
                                    onClick={(e) => {
                                        setEsNuevo(true); setOpenModal(true); setItem({
                                            ...item,
                                            priId: 0,
                                            priNombre: "",
                                            priPvp: 0,
                                            priPresentacion: "",
                                            priObservaciones: "",
                                        });
                                        setRegistro({
                                            ...registro,
                                            prpId: 0,
                                            prrId: 0,
                                            priId: 0,
                                            prpAno: 0,
                                            prpMes: 0,
                                            prpFecha: new Date(),
                                            priNombre: "",
                                            prpCantidad: 0,
                                            prpPvp: 0,
                                            prpCorreo: "",
                                            prrObservaciones: "",
                                            prrProcesado: 0,
                                        });
                                    }}>
                                    Nuevo
                                </Button>
                                <Button
                                    disabled={ new Date() > fechaHasta ? true :  listaPedido.length === 0 ? true : pedidoCerrado ? true : false}
                                    variant="contained"
                                    sx={{ ml: "42%", width: "16%", mt: 2 }}
                                    onClick={(e) => {
                                        setOpenModalResumen(true);
                                    }}>
                                    Procesar pedido
                                </Button>

                                {/* modal asesor */}
                                <Modal
                                    open={openModal}
                                    onClose={(e) => setOpenModal(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleModal}>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "20%",
                                                ml: "5%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Item:
                                        </Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            size="small"
                                            margin="normal"
                                            value={item.priNombre}
                                            sx={{ mt: 0.5, mb: 0.5, width: "50%", mr: "1%" }}
                                        />
                                        <Button
                                            sx={{ mt: 0.5, mb: 0.5, width: "10%" }} onClick={() => { setOpenModalItem(true) }}>
                                            Buscar
                                        </Button>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "20%",
                                                ml: "5%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Presentación:
                                        </Typography>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            value={item.priPresentacion}
                                            sx={{ mt: 0.5, mb: 0.5, width: "65%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "20%",
                                                ml: "5%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Cantidad:
                                        </Typography>
                                        <TextField
                                            name="prpCantidad"
                                            size="small"
                                            margin="normal"
                                            value={registro.prpCantidad}
                                            onChange={handleChangeRegistro}
                                            sx={{ mt: 0.5, mb: 0.5, mr: "5%", width: "20%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "20%",
                                                ml: "0%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            PVP Total:
                                        </Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            size="small"
                                            margin="normal"
                                            value={formatter.format(Number(registro.prpCantidad * item.priPvp)).replace("$", "$  ")}
                                            sx={{ mt: 0.5, mb: 0.5, mr: "10%", width: "20%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "20%",
                                                ml: "5%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Observaciones:
                                        </Typography>
                                        <TextField
                                            name="prrObservaciones"
                                            size="small"
                                            margin="normal"
                                            value={registro.prrObservaciones}
                                            onChange={handleChangeRegistro}
                                            sx={{ mt: 0.5, mb: 0.5, width: "65%" }}
                                        />
                                        <Button
                                            disabled={totalPrpPvp + (registro.prpCantidad * item.priPvp) > presupuesto.prrPresupuestoMensual ? true : item.priId === 0 ? true : registro.prpCantidad === 0 ? true : false}
                                            variant="contained"
                                            sx={{ mt: 2, mb: 2, ml: "35%", width: "30%" }}
                                            onClick={(e) => { seteoItem(Number(registro.prpId), Number(presupuesto.prrId), Number(item.priId), ano, mes, new Date(), item.priNombre + " (" + item.priPresentacion + ")", registro.prpCantidad, (registro.prpCantidad * item.priPvp), username, registro.prrObservaciones, 0) }}>
                                            Guardar
                                        </Button>
                                    </Box>
                                </Modal>

                                <Modal
                                    open={openModalItem}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleModalItem}>
                                        <Box
                                            sx={{
                                                "& .theme-header": {
                                                    backgroundColor: "rgba(21,57,105,0.75)",
                                                    color: "rgba(255,255,255,1)",
                                                },
                                            }}
                                        >
                                            <DataGrid
                                                getRowId={(row) => row.priId}
                                                sx={{ height: 500, }}
                                                editMode="row"
                                                rows={listaItems}
                                                columns={columnsItems}
                                                disableRowSelectionOnClick
                                                disableColumnFilter
                                                disableColumnSelector
                                                disableDensitySelector
                                                slots={{ toolbar: GridToolbarQuickFilter }}
                                                slotProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                    },
                                                    pagination: {
                                                        labelRowsPerPage: "Registros por página:",
                                                    },
                                                }}
                                                initialState={{
                                                    pagination: { paginationModel: { pageSize: 5 } },
                                                }}
                                                pageSizeOptions={[5, 10, 25]}
                                                localeText={{
                                                    footerTotalRows: false,
                                                    noRowsLabel: "No se ha encontrado datos.",
                                                    noResultsOverlayLabel:
                                                        "No se ha encontrado ningún resultado",
                                                    toolbarColumns: "Columnas",
                                                    toolbarColumnsLabel: "Seleccionar columnas",
                                                    toolbarFilters: "Filtros",
                                                    toolbarFiltersLabel: "Ver filtros",
                                                    toolbarFiltersTooltipHide: "Quitar filtros",
                                                    toolbarFiltersTooltipShow: "Ver filtros",
                                                    toolbarQuickFilterPlaceholder: "Buscar",
                                                }}
                                            />
                                        </Box>

                                        <Button sx={{ mt: 0.5, mb: 0.5, width: "10%", ml: "85%" }} onClick={() => { setOpenModalItem(false) }}>
                                            Cancelar
                                        </Button>
                                    </Box>
                                </Modal>


                                <Modal
                                    open={openModalResumen}
                                    onClose={(e) => setOpenModalResumen(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleModal}>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "100%",
                                                textAlign: "center"
                                            }}
                                        >
                                            <strong>Resumen</strong>
                                        </Typography>
                                        <TableContainer
                                            component={Paper}
                                            sx={{ maxHeight: 400, overflow: "auto" }} // Establece una altura máxima y habilita el scroll
                                        >
                                            <Table stickyHeader>
                                                {/* Encabezado */}
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Ítem</strong></TableCell>
                                                        <TableCell><strong>Cantidad</strong></TableCell>
                                                        <TableCell><strong>PVP</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {/* Cuerpo */}
                                                <TableBody>
                                                    {listaPedido.map((row) => (
                                                        <TableRow key={row.prpId}>
                                                            <TableCell>{row.priNombre}</TableCell>
                                                            <TableCell align="center">{row.prpCantidad}</TableCell>
                                                            <TableCell align="right"><strong>{formatter.format(row.prpPvp).replace("$", "$ ")}</strong></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "100%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Tu presupuesto mensual asignado es: <strong> {formatter.format(presupuesto.prrPresupuestoMensual).replace("$", "$ ")}</strong>, valor actual: <strong> {formatter.format(totalPrpPvp).replace("$", "$ ")}</strong>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                width: "100%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Tu pedido llegará hasta: <strong> {fechaEntrega.toLocaleDateString()} </strong>
                                        </Typography>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            label="Observaciones de pedido"
                                            onChange={(e) => setObservaciones(e.target.value)}
                                            sx={{ mt: 0.5, mb: 0.5, width: "100%" }}
                                        />
                                        <Button
                                            variant="contained"
                                            sx={{ mt: 2, mb: 2, ml: "35%", width: "30%" }}
                                            onClick={(e) => { getActualizarEstado(ano, mes, username, presupuesto.prrNotifica, "Proveeduria Mes:" + String(mes) + "/" + String(ano) + " - " + presupuesto.prrLinea, generateHTMLTable(listaPedido)) }}>
                                            Confirmar y enviar
                                        </Button>
                                    </Box>
                                </Modal>
                            </Paper>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
