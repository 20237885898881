import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    IconButton,
    Input,
    Modal,
    DialogContentText,
    MenuItem,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { MesType, ModeloType, SateliteTestDrive, SateliteTestDriveImagen, SateliteTestDriveImagenMostrar, SateliteTestDriveRuta, VSateliteTestDriveRuta, vSateliteTestDriveVh, vventassatelitecrmType } from "../../types";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const SalidaTestDrivePage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const params = useParams();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [rutas, setRutas] = useState<SateliteTestDriveRuta[]>([]);
    const [vehiculos, setVehiculos] = useState<vSateliteTestDriveVh[]>([]);
    const [rowsMod, setRowsMod] = useState<ModeloType[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [esInventario, setEsInventario] = useState<boolean>(false);
    const [vehInventario, setVehInventario] = useState<string>("");
    const [parametroBuscar, setParametroBuscar] = useState<string>("");
    const [openDialogInv, setOpenDialogInv] = useState<boolean>(false);
    const [openDialogGuardado, setOpenDialogGuardado] = useState<boolean>(false);
    const [estaGuardando, setEstaGuardando] = useState<boolean>(false);
    const [openDialogGuardadoError, setOpenDialogGuardadoError] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            checkDeviceType();
            getDatosNegocio();
        } else {
            setName("");
        }
    }, [account]);

    const fechaActual = new Date();
    fechaActual.setHours(fechaActual.getHours() - 5);

    const [dataTestDrive, setDataTestDrive] = useState<SateliteTestDrive>({
        stdFecha: fechaActual,
        stdFechaIni: fechaActual,
        stdFinalizado: 0,
        idBodega: 0,
        idCrm: "",
        sruId: 0,
        stdEsInventario: 0,
        stdVinPlaca: "",
        stdUsuCorreoCrea: "",
        stdUsuNombreCrea: "",
        stdUsuCorreoResponsable: "",
        stdUsuNombreResponsable: "",
        stdUsuCorreoFin: "",
        stdUsuNombreFin: "",
        stdObservacion1: "",
        stdObservacion2: "",
        stdKmInicio: 0,
    });

    const [dataNegocio, setDataNegocio] = useState<vventassatelitecrmType>({
        id: 0,
        idcrm: "",
        createdate: "",
        cedula: "",
        cliente: "",
        asesor: "",
        dealname: "",
        agencia: "",
        modelo: "",
        correo: "",
        ageDireccion: "",
    });

    const [dataVRuta, setDataVRuta] = useState<VSateliteTestDriveRuta>({
        sruId: 0,
        idBodega: 0,
        idZona: 0,
        agencia: "",
        zona: "",
        sruDescripcion: "",
        sruMetrosDistancia: 0,
        sruObservacion: "",
        sruMetrosDistanciaTolerancia: 0,
        sruMinutos: 0,
        sruMinutosTolerancia: 0,
        sruIdGrupoWpp: ""
    });


    const [images, setImages] = useState<SateliteTestDriveImagenMostrar[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const handleImageClick = (index: number) => {
        setCurrentIndex(index);
        setOpenDialog(true);
    };



    async function getDatosNegocio() {
        try {
            await axios
                .get<vventassatelitecrmType[]>("https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" + params.idcrm)
                .then((response) => {
                    setDataNegocio(response.data[0]);
                    getDatosRuta(Number(response.data[0].idbodega));
                    getRutas(Number(response.data[0].idbodega));
                    getVehiculos(Number(response.data[0].idZona));
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getvListaInvModelo() {
        try {
            await axios
                .get<ModeloType[]>(
                    "https://apisatelite.azurewebsites.net/tablarentamodelo/todo/pablo.fernandez@mirasol.com.ec/" + parametroBuscar
                )
                .then((response) => {
                    setRowsMod(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                    //setError(error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosRuta(id: number) {
        try {
            await axios
                .get<VSateliteTestDriveRuta>("https://apisatelite.azurewebsites.net/vsatelitetestdriveruta/buscar/" + String(id))
                .then((response) => {
                    setDataVRuta(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }




    const handlePrevious = () => {
        if (currentIndex !== null && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex !== null && currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    function stringToBlob(string: string) {
        try {
            // Tu string en formato Base64 que representa la imagen
            const base64Image = "data:image/png;" + string; // Recorta tu base64 aquí

            // Convertir el Base64 a un Blob
            const base64Data = base64Image.split(',')[1]; // Separar el encabezado
            const byteCharacters = atob(base64Data); // Decodificar Base64
            const byteNumbers = new Uint8Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const blob = new Blob([byteNumbers], { type: 'image/png' });

            // Crear un objeto File a partir del Blob
            const file = new File([blob], "example.png", { type: 'image/png' });

            console.log('Blob:', blob);
            console.log('File:', file);
            return file
            // Ahora puedes usar este archivo para cargarlo en un servidor o realizar otras operaciones.
        } catch (error) {
            return ""
        }
    }
    async function getRutas(_idBodega: number) {
        try {
            await axios
                .get<SateliteTestDriveRuta[]>("https://apisatelite.azurewebsites.net/testdriveruta/todo/" + String(_idBodega))
                .then((response) => {
                    setRutas(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getVehiculos(_idzona: number) {
        try {
            await axios
                .get<vSateliteTestDriveVh[]>("https://apisatelite.azurewebsites.net/vsatelitetestdrivevh/zona/" + String(_idzona))
                .then((response) => {
                    setVehiculos(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function postGuardarRegistro(registro: SateliteTestDrive) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/testdrive", registro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("id guardado es: >>>>>>>> ", response);
                    handleSaveAll(String(response.data.stdId));
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const [tempImagesTablero, setTempImagesTablero] = useState<SateliteTestDriveImagen[]>([]);
    const [tempImagesVehiculo, setTempImagesVehiculo] = useState<SateliteTestDriveImagen[]>([]);
    const [tempImagesLicencia, setTempImagesLicencia] = useState<SateliteTestDriveImagen[]>([]);
    const [currentImageIndexTablero, setCurrentImageIndexTablero] = useState<number | null>(null);
    const [currentImageIndexVehiculo, setCurrentImageIndexVehiculo] = useState<number | null>(null);
    const [currentImageIndexLicencia, setCurrentImageIndexLicencia] = useState<number | null>(null);


    const handleFileChangeTablero = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setTempImagesTablero((prev) => [
                ...prev,
                {
                    stiId: 0, // Provisional
                    stdId: 123, // ID de ejemplo
                    stiNombre: file.name,
                    stiImagen: file, // Guarda el archivo original
                    stiFecha: fechaActual.toISOString(),
                },
            ]);
        }
    };

    const handleFileChangeVehiculo = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setTempImagesVehiculo((prev) => [
                ...prev,
                {
                    stiId: 0, // Provisional
                    stdId: 123, // ID de ejemplo
                    stiNombre: file.name,
                    stiImagen: file, // Guarda el archivo original
                    stiFecha: fechaActual.toISOString(),
                },
            ]);
        }
    };
    const handleFileChangeLicencia = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setTempImagesLicencia((prev) => [
                ...prev,
                {
                    stiId: 0, // Provisional
                    stdId: 123, // ID de ejemplo
                    stiNombre: file.name,
                    stiImagen: file, // Guarda el archivo original
                    stiFecha: fechaActual.toISOString(),
                },
            ]);
        }
    };



    const handleSaveAll = async (_idTest: string) => {

        try {
            for (const image of tempImagesTablero) {
                const formData = new FormData();
                formData.append("file", image.stiImagen); // Archivo binario
                formData.append("stdId", _idTest);
                formData.append("stiNombre", "Salida: tablero");
                formData.append("stiFecha", image.stiFecha);

                await axios.post(
                    "https://apisatelite.azurewebsites.net/testdriveimagen/upload",
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
            }
            for (const image of tempImagesVehiculo) {
                const formData = new FormData();
                formData.append("file", image.stiImagen); // Archivo binario
                formData.append("stdId", _idTest);
                formData.append("stiNombre", "Salida: vehículo");
                formData.append("stiFecha", image.stiFecha);

                await axios.post(
                    "https://apisatelite.azurewebsites.net/testdriveimagen/upload",
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
            }
            for (const image of tempImagesLicencia) {
                const formData = new FormData();
                formData.append("file", image.stiImagen); // Archivo binario
                formData.append("stdId", _idTest);
                formData.append("stiNombre", "Salida: licencia");
                formData.append("stiFecha", image.stiFecha);

                await axios.post(
                    "https://apisatelite.azurewebsites.net/testdriveimagen/upload",
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
            }
            setOpenDialogGuardado(true);
            setTempImagesTablero([]);
            setTempImagesVehiculo([]);
            setTempImagesLicencia([]);
        } catch (error) {
            console.error("Error al guardar imágenes:", error);
            setOpenDialogGuardadoError(true);
        }
    };

    const handleDeleteImageTablero = (stiNombre: string) => {
        setTempImagesTablero((prev) => prev.filter((img) => img.stiNombre !== stiNombre));
        if (currentImageIndexTablero !== null && tempImagesTablero[currentImageIndexTablero]?.stiNombre === stiNombre) {
            setCurrentImageIndexTablero(null);
        }
    };

    const handleDeleteImageVehiculo = (stiNombre: string) => {
        setTempImagesVehiculo((prev) => prev.filter((img) => img.stiNombre !== stiNombre));
        if (currentImageIndexVehiculo !== null && tempImagesVehiculo[currentImageIndexVehiculo]?.stiNombre === stiNombre) {
            setCurrentImageIndexVehiculo(null);
        }
    };
    const handleDeleteImageLicencia = (stiNombre: string) => {
        setTempImagesLicencia((prev) => prev.filter((img) => img.stiNombre !== stiNombre));
        if (currentImageIndexLicencia !== null && tempImagesLicencia[currentImageIndexLicencia]?.stiNombre === stiNombre) {
            setCurrentImageIndexLicencia(null);
        }
    };

    const handleOpenModalTablero = (index: number) => {
        setCurrentImageIndexTablero(index);
    };

    const handleOpenModalVehiculo = (index: number) => {
        setCurrentImageIndexVehiculo(index);
    };
    const handleOpenModalLicencia = (index: number) => {
        setCurrentImageIndexLicencia(index);
    };

    const handleCloseModalTablero = () => {
        setCurrentImageIndexTablero(null);
    };
    const handleCloseModalVehiculo = () => {
        setCurrentImageIndexVehiculo(null);
    };
    const handleCloseModalLicencia = () => {
        setCurrentImageIndexLicencia(null);
    };

    const handleNextImageTablero = () => {
        if (currentImageIndexTablero !== null) {
            setCurrentImageIndexTablero((prevIndex) => (prevIndex! + 1) % tempImagesTablero.length);
        }
    };
    const handleNextImageVehiculo = () => {
        if (currentImageIndexVehiculo !== null) {
            setCurrentImageIndexVehiculo((prevIndex) => (prevIndex! + 1) % tempImagesVehiculo.length);
        }
    };
    const handleNextImageLicencia = () => {
        if (currentImageIndexLicencia !== null) {
            setCurrentImageIndexLicencia((prevIndex) => (prevIndex! + 1) % tempImagesLicencia.length);
        }
    };

    const handlePrevImageTablero = () => {
        if (currentImageIndexTablero !== null) {
            setCurrentImageIndexTablero(
                (prevIndex) => (prevIndex! - 1 + tempImagesTablero.length) % tempImagesTablero.length
            );
        }
    };
    const handlePrevImageVehiculo = () => {
        if (currentImageIndexVehiculo !== null) {
            setCurrentImageIndexVehiculo(
                (prevIndex) => (prevIndex! - 1 + tempImagesVehiculo.length) % tempImagesVehiculo.length
            );
        }
    };
    const handlePrevImageLicencia = () => {
        if (currentImageIndexLicencia !== null) {
            setCurrentImageIndexLicencia(
                (prevIndex) => (prevIndex! - 1 + tempImagesLicencia.length) % tempImagesLicencia.length
            );
        }
    };

    const [ruta, setRuta] = useState<SateliteTestDriveRuta>({
        sruId: 0,
        idBodega: 0,
        sruDescripcion: "",
        sruMetrosDistancia: 0,
        sruObservacion: "",
        sruMetrosDistanciaTolerancia: 0,
        sruMinutos: 0,
        sruMinutosTolerancia: 0,
        sruIdGrupoWpp: ""
    });

    const [vehiculo, setVehiculo] = useState<vSateliteTestDriveVh>({
        id: 0,
        descripcion: "",
        vin: "",
        placa: "",
        idBodega: 0,
        esActivo: 0
    });

    const handleRutaChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const itemValue = Number(event.target.value);
        const selectedItem = rutas.find(
            (item) => item.sruId === itemValue
        );
        if (selectedItem) {
            setRuta(selectedItem)
            setDataTestDrive({
                ...dataTestDrive,
                sruId: selectedItem.sruId,
                idBodega: selectedItem.idBodega,
                stdUsuCorreoCrea: username,
                stdUsuNombreCrea: name,
                stdUsuCorreoResponsable: dataNegocio.correo,
                stdUsuNombreResponsable: dataNegocio.asesor
            })

            console.log("mi ruta select >>", selectedItem.sruId)
        }

    };

    const handleVehiculoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const itemValue = Number(event.target.value);
        const selectedItem = vehiculos.find(
            (item) => item.id === itemValue
        );
        if (selectedItem) {
            setVehiculo(selectedItem)
            setDataTestDrive({
                ...dataTestDrive,
                stdVinPlaca: selectedItem.descripcion,
                stdEsInventario: 0
            })
        }
    };

    const styleModal = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };


    const columnsModelo: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "vin",
            headerName: "VIN",
            editable: false,
            width: 175,
        },
        {
            headerClassName: "theme-header",
            field: "modelo",
            headerName: "Modelo",
            editable: false,
            width: 250,
        },
        {
            headerClassName: "theme-header",
            field: "anio",
            headerName: "Año",
            editable: false,
            width: 60,
        },
        {
            headerClassName: "theme-header",
            field: "color",
            headerName: "Color",
            editable: false,
            width: 80,
        },

        {
            headerClassName: "theme-header",
            field: "bodega",
            headerName: "Bodega",
            editable: false,
            width: 125,
        },

        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Seleccionar",
            sortable: false,
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Button
                        sx={{ width: "80%" }}
                        onClick={() => {
                            setVehInventario(params.row.vin + ' - ' + params.row.modelo); setOpenDialogInv(false); setDataTestDrive({
                                ...dataTestDrive,
                                stdVinPlaca: params.row.vin + ' - ' + params.row.modelo,
                                stdEsInventario: 1
                            })
                        }}
                    >
                        ✔
                    </Button>
                );
            },
        },
    ];


    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Test Drive: Registro de Salida
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >
                            {isMobile ? <>
                            </> : <>

                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Información general</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Identificacion:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cedula}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Cliente:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cliente}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />


                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Teléfono:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactphone}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Correo:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactemail}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 2 }} />


                                <FormControlLabel
                                    sx={{ width: "12%", ml: "10%", mr: esInventario ? "0%" : "70%" }}
                                    control={
                                        <Checkbox
                                            checked={esInventario}
                                            onChange={() => setEsInventario(!esInventario)}
                                        />
                                    }
                                    label="Es inventario"
                                />
                                {esInventario ? <>
                                    <Button onClick={() => { setOpenDialogInv(true) }} sx={{ mt: 0.5, mb: 0.5, ml: "1%", width: "7%", mr: "65%" }}>
                                        Buscar
                                    </Button>
                                </> : <></>}


                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Ruta:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    select
                                    size="small"
                                    margin="normal"
                                    value={ruta.sruId}
                                    onChange={handleRutaChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "35%" }}
                                    SelectProps={{
                                        MenuProps: { disableScrollLock: true, style: { maxHeight: 300 } },
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Seleccione un ruta</em>
                                    </MenuItem>
                                    {rutas.map((item) => (
                                        <MenuItem key={item.sruId} value={item.sruId}>
                                            {item.sruDescripcion}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "15%",
                                        ml: "8%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Distancia de la ruta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={`${(ruta.sruMetrosDistancia / 1000)} KM`}
                                    sx={{ mt: 1, mb: 1, width: "12%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Vehículo:
                                </Typography>


                                {esInventario ? <>
                                    <TextField
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        value={vehInventario}
                                        sx={{ mt: 0.5, mb: 0.5, width: "35%" }}
                                    />

                                </> : <>
                                    <TextField
                                        id="outlined-select-currency"
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        select
                                        size="small"
                                        margin="normal"
                                        value={vehiculo.id}
                                        onChange={handleVehiculoChange}
                                        sx={{ mt: 0.5, mb: 0.5, width: "35%" }}
                                        SelectProps={{
                                            MenuProps: { disableScrollLock: true, style: { maxHeight: 300 } },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>Seleccione un vehículo</em>
                                        </MenuItem>
                                        {vehiculos.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.descripcion}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </>}

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "15%",
                                        ml: "8%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    KM de inicio:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    onChange={(e) => {
                                        setDataTestDrive({
                                            ...dataTestDrive,
                                            stdKmInicio: Number(e.target.value),
                                            stdFechaIni: fechaActual,
                                            idCrm: dataNegocio.idcrm,
                                        })
                                    }}
                                    value={dataTestDrive.stdKmInicio}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    sx={{ mt: 1, mb: 1, width: "12%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Responsable:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.asesor}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "8%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Hora de inicio:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={new Date().toLocaleString()}
                                    sx={{ mt: 1, mb: 1, width: "17%" }}
                                />

                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Fotos del registro de salida  </strong>
                                </Typography>
                                <Tooltip title="Mínimo 1 foto">
                                    <Typography sx={{ ml: "10%", mt: 2, mb: 3, width: "30%" }} textAlign={"left"}  >
                                        <strong>Fotos del tablero {String(tempImagesTablero.length)}/1 💡</strong>
                                    </Typography>
                                </Tooltip>
                                <Input sx={{ ml: "10%", mb: 2 }}
                                    type="file"
                                    inputProps={{ accept: "image/jpeg, image/png" }}
                                    onChange={handleFileChangeTablero}
                                />
                                <Box sx={{ ml: "10%", mr: "10%", mb: 2 }}>
                                    <Grid container spacing={2}>
                                        {tempImagesTablero.map((image, index) => (
                                            <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                <Card
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleOpenModalTablero(index)}
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        height="140"
                                                        image={typeof image.stiImagen === "string"
                                                            ? image.stiImagen
                                                            : URL.createObjectURL(image.stiImagen)} // Convierte File en URL
                                                        alt={image.stiNombre}
                                                    />
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Tooltip title="Máximo 8 fotos">
                                <Typography sx={{ ml: "10%", mt: 2, mb: 3, width: "30%" }} textAlign={"left"}  >
                                    <strong>Fotos del vehículo {String(tempImagesVehiculo.length)}/8 💡</strong>
                                </Typography>
                                </Tooltip>
                                <Input sx={{ ml: "10%", mb: 2 }}
                                    type="file"
                                    inputProps={{ accept: "image/jpeg, image/png" }}
                                    onChange={handleFileChangeVehiculo}
                                />
                                <Box sx={{ ml: "10%", mr: "10%", mb: 2 }}>
                                    <Grid container spacing={2}>
                                        {tempImagesVehiculo.map((image, index) => (
                                            <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                <Card
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleOpenModalVehiculo(index)}
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        height="140"
                                                        image={typeof image.stiImagen === "string"
                                                            ? image.stiImagen
                                                            : URL.createObjectURL(image.stiImagen)} // Convierte File en URL
                                                        alt={image.stiNombre}
                                                    />
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Tooltip title="Mínimo 2 fotos">
                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 , width: "30%"}} textAlign={"left"} >
                                    <strong>Fotos de la licencia {String(tempImagesLicencia.length)}/2 💡</strong>
                                </Typography>
                                </Tooltip>
                                <Input sx={{ ml: "10%", mb: 2 }}
                                    type="file"
                                    inputProps={{ accept: "image/jpeg, image/png" }}
                                    onChange={handleFileChangeLicencia}
                                />
                                <Box sx={{ ml: "10%", mr: "10%", mb: 2 }}>
                                    <Grid container spacing={2}>
                                        {tempImagesLicencia.map((image, index) => (
                                            <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                <Card
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleOpenModalLicencia(index)}
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        height="140"
                                                        image={typeof image.stiImagen === "string"
                                                            ? image.stiImagen
                                                            : URL.createObjectURL(image.stiImagen)} // Convierte File en URL
                                                        alt={image.stiNombre}
                                                    />
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Modal
                                    open={openDialogInv}
                                    onClose={(e) => setOpenDialogInv(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleModal}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Seleccione un chasis / modelo
                                        </Typography>

                                        <TextField
                                            name="parametro"
                                            size="small"
                                            margin="normal"
                                            label="Modelo"
                                            onChange={(e) => { setParametroBuscar(e.target.value) }}
                                            sx={{ mt: 1.5, mb: 1.5, width: "66%" }}
                                        ></TextField>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                mt: 1.5,
                                                mb: 1.5,
                                                width: "15%",
                                                marginLeft: "2%",
                                            }}
                                            onClick={(e) => { getvListaInvModelo() }}
                                        >
                                            Consultar
                                        </Button>

                                        <Divider
                                            sx={{
                                                marginLeft: "5%",
                                                marginRight: "5%",
                                                mt: 2,
                                                mb: 2,
                                            }}
                                        />

                                        <Box
                                            sx={{
                                                "& .theme-header": {
                                                    backgroundColor: "rgba(21,57,105,0.75)",
                                                    color: "rgba(255,255,255,1)",
                                                },
                                            }}
                                        >
                                            <DataGrid
                                                getRowId={(row) => row.id}
                                                sx={{ height: 500 }}
                                                editMode="row"
                                                rows={rowsMod}
                                                columns={columnsModelo}
                                                disableColumnFilter
                                                disableColumnSelector
                                                disableDensitySelector
                                                disableRowSelectionOnClick
                                                slots={{ toolbar: GridToolbarQuickFilter }}
                                                slotProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                    },
                                                    pagination: {
                                                        labelRowsPerPage: "Registros por página:",
                                                    },
                                                }}
                                                initialState={{
                                                    pagination: { paginationModel: { pageSize: 10 } },
                                                }}
                                                pageSizeOptions={[10, 25, 50]}
                                                localeText={{
                                                    footerTotalRows: false,
                                                    noRowsLabel: "No se ha encontrado datos.",
                                                    noResultsOverlayLabel:
                                                        "No se ha encontrado ningún resultado",
                                                    toolbarColumns: "Columnas",
                                                    toolbarColumnsLabel: "Seleccionar columnas",
                                                    toolbarFilters: "Filtros",
                                                    toolbarFiltersLabel: "Ver filtros",
                                                    toolbarFiltersTooltipHide: "Quitar filtros",
                                                    toolbarFiltersTooltipShow: "Ver filtros",
                                                    toolbarQuickFilterPlaceholder: "Buscar",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Modal>

                                <Modal open={currentImageIndexTablero !== null} onClose={handleCloseModalTablero}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            bgcolor: "background.paper",
                                            p: 4,
                                            position: "relative",
                                        }}
                                    >
                                        {currentImageIndexTablero !== null && (
                                            <>
                                                <IconButton
                                                    sx={{ position: "absolute", left: 20, top: "50%" }}
                                                    onClick={handlePrevImageTablero}
                                                >
                                                    <ArrowBack />
                                                </IconButton>
                                                <img
                                                    src={URL.createObjectURL(tempImagesTablero[currentImageIndexTablero].stiImagen)} // Si es un File, crea una URL temporal
                                                    alt={tempImagesTablero[currentImageIndexTablero].stiNombre}
                                                    style={{ maxHeight: "80%", maxWidth: "80%" }}
                                                />
                                                <IconButton
                                                    sx={{ position: "absolute", right: 20, top: "50%" }}
                                                    onClick={handleNextImageTablero}
                                                >
                                                    <ArrowForward />
                                                </IconButton>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        handleDeleteImageTablero(tempImagesTablero[currentImageIndexTablero].stiNombre)
                                                    }
                                                    sx={{ mt: 2 }}
                                                >
                                                    Eliminar Imagen
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Modal>

                                <Modal open={currentImageIndexVehiculo !== null} onClose={handleCloseModalVehiculo}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            bgcolor: "background.paper",
                                            p: 4,
                                            position: "relative",
                                        }}
                                    >
                                        {currentImageIndexVehiculo !== null && (
                                            <>
                                                <IconButton
                                                    sx={{ position: "absolute", left: 20, top: "50%" }}
                                                    onClick={handlePrevImageVehiculo}
                                                >
                                                    <ArrowBack />
                                                </IconButton>
                                                <img
                                                    src={URL.createObjectURL(tempImagesVehiculo[currentImageIndexVehiculo].stiImagen)} // Si es un File, crea una URL temporal
                                                    alt={tempImagesVehiculo[currentImageIndexVehiculo].stiNombre}
                                                    style={{ maxHeight: "80%", maxWidth: "80%" }}
                                                />
                                                <IconButton
                                                    sx={{ position: "absolute", right: 20, top: "50%" }}
                                                    onClick={handleNextImageVehiculo}
                                                >
                                                    <ArrowForward />
                                                </IconButton>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        handleDeleteImageVehiculo(tempImagesVehiculo[currentImageIndexVehiculo].stiNombre)
                                                    }
                                                    sx={{ mt: 2 }}
                                                >
                                                    Eliminar Imagen
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Modal>

                                <Modal open={currentImageIndexLicencia !== null} onClose={handleCloseModalLicencia}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            bgcolor: "background.paper",
                                            p: 4,
                                            position: "relative",
                                        }}
                                    >
                                        {currentImageIndexLicencia !== null && (
                                            <>
                                                <IconButton
                                                    sx={{ position: "absolute", left: 20, top: "50%" }}
                                                    onClick={handlePrevImageLicencia}
                                                >
                                                    <ArrowBack />
                                                </IconButton>
                                                <img
                                                    src={URL.createObjectURL(tempImagesLicencia[currentImageIndexLicencia].stiImagen)} // Si es un File, crea una URL temporal
                                                    alt={tempImagesLicencia[currentImageIndexLicencia].stiNombre}
                                                    style={{ maxHeight: "80%", maxWidth: "80%" }}
                                                />
                                                <IconButton
                                                    sx={{ position: "absolute", right: 20, top: "50%" }}
                                                    onClick={handleNextImageLicencia}
                                                >
                                                    <ArrowForward />
                                                </IconButton>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        handleDeleteImageLicencia(tempImagesLicencia[currentImageIndexLicencia].stiNombre)
                                                    }
                                                    sx={{ mt: 2 }}
                                                >
                                                    Eliminar Imagen
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Modal>


                                <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "15%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Observaciones:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    onChange={(e) => {
                                        setDataTestDrive({
                                            ...dataTestDrive,
                                            stdObservacion1: e.target.value,
                                        })
                                    }}
                                    value={dataTestDrive.stdObservacion1}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    sx={{ mt: 1, mb: 1, width: "65%" }}
                                />
                                
                                <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />

                                <Button
                                    variant="contained"
                                    onClick={(e) => { setEstaGuardando(true); postGuardarRegistro(dataTestDrive) }}
                                    sx={{ ml: "42%", width: "16%" }}
                                    disabled={estaGuardando ? true : tempImagesTablero.length !== 1 ? true : tempImagesLicencia.length !== 2 ? true : tempImagesVehiculo.length > 8 ? true :   dataTestDrive.stdKmInicio === 0 ? true : false}
                                >
                                    {estaGuardando ? <>
                                        <CircularProgress size={24} sx={{ position: 'absolute' }} /> {"Espere por favor"}
                                    </> : "Guardar"}
                                </Button>
                                <Dialog
                                    open={openDialogGuardado}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Guardado correctamente!
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            href={`/testdrive/negocios`}
                                            onClick={function (event) {
                                                setOpenDialogGuardado(false);
                                            }}
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={openDialogGuardadoError}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Error al guardar los datos!
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={function (event) {
                                                setOpenDialogGuardadoError(false);
                                            }}
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
