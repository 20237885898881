import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { Colorize } from "@mui/icons-material";
import { NotificacionWPPType, SateliteCotizacionType, SateliteParametroCotizadorType } from "../../types";
import blazer_1 from "../../assets/cotizador/blazer_1.png";
import blazer_ev_1 from "../../assets/cotizador/blazer_ev_1.png";
import captivaxl_1 from "../../assets/cotizador/captivaxl_1.png";
import colorado_1 from "../../assets/cotizador/colorado_1.png";
import dmax_1 from "../../assets/cotizador/dmax_1.png";
import equinox_1 from "../../assets/cotizador/equinox_1.png";
import groove_1 from "../../assets/cotizador/groove_1.png";
import joy_black_1 from "../../assets/cotizador/joy_black_1.png";
import joy_sedan_1 from "../../assets/cotizador/joy_sedan_1.png";
import montana_1 from "../../assets/cotizador/montana_1.png";
import n400_1 from "../../assets/cotizador/n400_1.png";
import onix_sedan_1 from "../../assets/cotizador/onix_sedan_1.png";
import onix_turbo_rs_1 from "../../assets/cotizador/onix_turbo_rs_1.png";
import silverado_1 from "../../assets/cotizador/silverado_1.png";
import tahoe_1 from "../../assets/cotizador/tahoe_1.png";
import tracker_1 from "../../assets/cotizador/tracker_1.png";
import trailblazer_1 from "../../assets/cotizador/trailblazer_1.png";

const images: Record<string, string> = {
  'blazer_1.png': blazer_1,
  'blazer_ev_1.png': blazer_ev_1,
  'captivaxl_1.png': captivaxl_1,
  'colorado_1.png': colorado_1,
  'dmax_1.png': dmax_1,
  'equinox_1.png': equinox_1,
  'groove_1.png': groove_1,
  'joy_black_1.png': joy_black_1,
  'joy_sedan_1.png': joy_sedan_1,
  'montana_1.png': montana_1,
  'n400_1.png': n400_1,
  'onix_sedan_1.png': onix_sedan_1,
  'onix_turbo_rs_1.png': onix_turbo_rs_1,
  'silverado_1.png': silverado_1,
  'tahoe_1.png': tahoe_1,
  'tracker_1.png': tracker_1,
  'trailblazer_1.png': trailblazer_1,

};

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1050,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const CotizacionViewPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [openDialogEnviado, setOpenDialogEnviado] = React.useState(false);
  const [openDialogEnviadoOk, setOpenDialogEnviadoOk] = React.useState(false);
  const [openDialogAutorizado, setOpenDialogAutorizado] = React.useState(false);
  const [openDialogNegado, setOpenDialogNegado] = React.useState(false);
  const [openDialogEnviadoError, setOpenDialogEnviadoError] = React.useState(false);

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getRegistroCotizacion();

      const userAgent = navigator.userAgent;
      const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
      ];

      const checkDeviceType = () => {
        setIsMobile(
          mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
      };
      // Verifica el tipo de dispositivo al cargar la página
      checkDeviceType();
      // No necesitamos limpiar el listener en este caso
    } else {
      setName("");
    }
  }, [account]);

  const handleLogin = (loginType: string) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const params = useParams();

  type TypeDatosInfo = {
    nuevoCorreo: string;
    nuevaObservacion: string;
  };


  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function EnviarNotificacionWPP(
    typing_time: number,
    to: string,
    body: string
  ) {
    const nuevoRegistro: NotificacionWPPType = {
      typing_time: typing_time,
      to: to,
      body: body,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postNotificarWPP(nuevoRegistro);
  }

  async function postNotificarWPP(nuevoRegistro: NotificacionWPPType) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + "gMlELccOPZC3Uwy9zIyvQoe8feZ0xRjD",
    };
    try {
      axios
        .post<any>("https://gate.whapi.cloud/messages/text", nuevoRegistro, {
          headers: headers,
        })
        .then((response) => {
          console.log("mi wpp: ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error peticion crear cli dms: ", error.message);
        return error.message;
      } else {
        console.log("error peticion crear cli dms: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  const [registroCotizador, setRegistroCotizador] = useState<SateliteCotizacionType>({
    scoId: 0,
    scoFecha: new Date(),
    scoEnviado: 0,
    scoComercialNombre: "",
    scoComercialTelefono: "",
    scoComercialCorreo: "",
    scoComercialObservacion: "",
    scoComercialEstado: 0,
    scoFiNombre: "",
    scoFiTelefono: "",
    scoFiCorreo: "",
    scoFiObservacion: "",
    scoFiEstado: 0,
    scoAsesorCorreo: "",
    scoAsesorNombre: "",
    scoAsesorTelefono: "",
    scoAsesorDireccion: "",
    scoFilename: "",
    idZona: 0,
    idCrm: "",
    scoClienteNombre: "",
    scoClienteNit: "",
    scoClienteCorreo: "",
    scoClienteTelefono: "",
    scoAgencia: "",
    spcId: 0,
    idColor: 0,
    scoColor: "",
    scoColorB: 0,
    scoColorG: 0,
    scoColorR: 0,
    scoPvpBase: 0,
    scoPvpEspecial: 0,
    scoExt1Descripcion: "",
    scoExt1Pvp: 0,
    scoExt1Cortesia: 0,
    scoExt2Descripcion: "",
    scoExt2Pvp: 0,
    scoExt2Cortesia: 0,
    scoExt3Descripcion: "",
    scoExt3Pvp: 0,
    scoExt3Cortesia: 0,
    scoExt4Descripcion: "",
    scoExt4Pvp: 0,
    scoExt4Cortesia: 0,
    scoExt5Descripcion: "",
    scoExt5Pvp: 0,
    scoExt5Cortesia: 0,
    scoExt6Descripcion: "",
    scoExt6Pvp: 0,
    scoExt6Cortesia: 0,
    scoExt7Descripcion: "",
    scoExt7Pvp: 0,
    scoExt7Cortesia: 0,
    scoExt8Descripcion: "",
    scoExt8Pvp: 0,
    scoExt8Cortesia: 0,
    scoDesc1Descripcion: "",
    scoDesc1Valor: 0,
    scoDesc2Descripcion: "",
    scoDesc2Valor: 0,
    scoDesc3Descripcion: "",
    scoDesc3Valor: 0,
    scoDesc4Descripcion: "",
    scoDesc4Valor: 0,
    scoTerminos: "",
    scoMatriculaMonto: 0,
    scoEntradaMonto: 0,
    scoEntradaPorcen: 0,
    scoFinanciarMonto: 0,
    scoSeguroVeh: 0,
    scoSeguroCuotas: 0,
    scoMuestra12Meses: 0,
    scoValor12Meses: 0,
    scoMuestra24Meses: 0,
    scoValor24Meses: 0,
    scoMuestra36Meses: 0,
    scoValor36Meses: 0,
    scoMuestra48Meses: 0,
    scoValor48Meses: 0,
    scoMuestra60Meses: 0,
    scoValor60Meses: 0,
    scoMuestra72Meses: 0,
    scoValor72Meses: 0,
    scoObservacionesCotizacion: "",
  });

  const [parametroCotizador, setParametroCotizador] = React.useState<SateliteParametroCotizadorType>({
    spcId: 0,
    spcImagen1: "",
    spcImagen2: "",
    spcNombre: "",
    spcText2: "",
    spcMarcaModeloVersion: "",
    spcCaracteristica1: "",
    spcDetalle1: "",
    spcCaracteristica2: "",
    spcDetalle2: "",
    spcCaracteristica3: "",
    spcDetalle3: "",
    spcCaracteristica4: "",
    spcDetalle4: "",
    spcCaracteristica5: "",
    spcDetalle5: "",
    spcCaracteristica6: "",
    spcDetalle6: "",
    spcCaracteristica7: "",
    spcDetalle7: "",
    spcCaracteristica8: "",
    spcDetalle8: "",
    spcDispositivo: "",
    spcDispositivoA1: 0,
    spcDispositivoA2: 0,
    spcDispositivoA3: 0,
    spcDispositivoA4: 0,
    spcDispositivoA5: 0,
    spcPlan1Tipo: "",
    spcPlan1Modelo: "",
    spcPlan1Descripcion: "",
    spcPlan1PvpNormal: 0,
    spcPlan1PvpMpc: 0,
    spcPlan1Cobertura: "",
    spcPlan2Tipo: "",
    spcPlan2Modelo: "",
    spcPlan2Descripcion: "",
    spcPlan2PvpNormal: 0,
    spcPlan2PvpMpc: 0,
    spcPlan2Cobertura: "",
    spcLink1: "",
    spcFamilia: "",
    spcDescuento1: "",
    spcDescuento1Valor: 0,
    spcDescuento2: "",
    spcDescuento2Valor: 0,
    spcDescuento3: "",
    spcDescuento3Valor: 0,
    spcDescuento4: "",
    spcDescuento4Valor: 0,
    spcDescuento5: "",
    spcDescuento5Valor: 0,
    spcPvp: 0,
    idCotItem: 0,
    spcIdDispositivo: 0,
    spcModeloEspecifico: "",
    spcGarantia: 0,
    spcExtra1Descripcion: "",
    spcExtra1Valor: 0,
    spcExtra2Descripcion: "",
    spcExtra2Valor: 0,
    spcExtra3Descripcion: "",
    spcExtra3Valor: 0,
    spcExtra4Descripcion: "",
    spcExtra4Valor: 0,
    spcSeguroPorcen: 0,
    spcSeguroSuperbanco: 0,
    spcSeguroCampesino: 0,
    spcSeguroDerechosEmision: 0,
    spcSeguroIva: 0,
  });


  type Extra = {
    descripcion: string;
    pvp: number;
    cortesia: number;
  };

  const dataCuotas = [
    { label: "12 Meses", muestra: registroCotizador.scoMuestra12Meses, valor: registroCotizador.scoValor12Meses },
    { label: "24 Meses", muestra: registroCotizador.scoMuestra24Meses, valor: registroCotizador.scoValor24Meses },
    { label: "36 Meses", muestra: registroCotizador.scoMuestra36Meses, valor: registroCotizador.scoValor36Meses },
    { label: "48 Meses", muestra: registroCotizador.scoMuestra48Meses, valor: registroCotizador.scoValor48Meses },
    { label: "60 Meses", muestra: registroCotizador.scoMuestra60Meses, valor: registroCotizador.scoValor60Meses },
    { label: "72 Meses", muestra: registroCotizador.scoMuestra72Meses, valor: registroCotizador.scoValor72Meses },
  ];

  const dataExtras: Extra[] = [
    { descripcion: registroCotizador.scoExt1Descripcion, pvp: registroCotizador.scoExt1Pvp, cortesia: registroCotizador.scoExt1Cortesia },
    { descripcion: registroCotizador.scoExt2Descripcion, pvp: registroCotizador.scoExt2Pvp, cortesia: registroCotizador.scoExt2Cortesia },
    { descripcion: registroCotizador.scoExt3Descripcion, pvp: registroCotizador.scoExt3Pvp, cortesia: registroCotizador.scoExt3Cortesia },
    { descripcion: registroCotizador.scoExt4Descripcion, pvp: registroCotizador.scoExt4Pvp, cortesia: registroCotizador.scoExt4Cortesia },
    { descripcion: registroCotizador.scoExt5Descripcion, pvp: registroCotizador.scoExt5Pvp, cortesia: registroCotizador.scoExt5Cortesia },
    { descripcion: registroCotizador.scoExt6Descripcion, pvp: registroCotizador.scoExt6Pvp, cortesia: registroCotizador.scoExt6Cortesia },
    { descripcion: registroCotizador.scoExt7Descripcion, pvp: registroCotizador.scoExt7Pvp, cortesia: registroCotizador.scoExt7Cortesia },
    { descripcion: registroCotizador.scoExt8Descripcion, pvp: registroCotizador.scoExt8Pvp, cortesia: registroCotizador.scoExt8Cortesia },
  ];

  const dataDispositivo = [
    { label: "1 Año", value: parametroCotizador.spcDispositivoA1 },
    { label: "2 Año", value: parametroCotizador.spcDispositivoA2 },
    { label: "3 Año", value: parametroCotizador.spcDispositivoA3 },
    { label: "4 Año", value: parametroCotizador.spcDispositivoA4 },
    { label: "5 Año", value: parametroCotizador.spcDispositivoA5 },
  ];

  const dataMantenimientos = [
    { label: "Tipo de plan", value1: parametroCotizador.spcPlan1Tipo, value2: parametroCotizador.spcPlan2Tipo },
    { label: "Descripción", value1: parametroCotizador.spcPlan1Descripcion, value2: parametroCotizador.spcPlan2Descripcion },
    { label: "Precio normal", value1: formatter.format(Number(parametroCotizador.spcPlan1PvpNormal)).replace("$", "$ "), value2: formatter.format(Number(parametroCotizador.spcPlan2PvpNormal)).replace("$", "$ ") },
    { label: "Precio MPC", value1: formatter.format(Number(parametroCotizador.spcPlan1PvpMpc)).replace("$", "$ "), value2: formatter.format(Number(parametroCotizador.spcPlan2PvpMpc)).replace("$", "$ ") },
    { label: "Coberturas", value1: parametroCotizador.spcPlan1Cobertura, value2: parametroCotizador.spcPlan2Cobertura },
  ];

  async function seteoRegistro(
    estadoComercial: number,
    estadoFI: number,
    newregistro: SateliteCotizacionType,
    esAprobado: boolean,
  ) {

    setLoading(true);
    setRegistroCotizador({
      ...registroCotizador,
      scoComercialEstado: estadoComercial,
      scoFiEstado: estadoFI
    });

    const nuevoRegistro: SateliteCotizacionType = {
      scoId: newregistro.scoId,
      scoFecha: newregistro.scoFecha,
      scoEnviado: newregistro.scoEnviado,
      scoComercialNombre: newregistro.scoComercialNombre,
      scoComercialTelefono: newregistro.scoComercialTelefono,
      scoComercialCorreo: newregistro.scoComercialCorreo,
      scoComercialObservacion: newregistro.scoComercialObservacion,
      scoComercialEstado: estadoComercial,
      scoFiNombre: newregistro.scoFiNombre,
      scoFiTelefono: newregistro.scoFiTelefono,
      scoFiCorreo: newregistro.scoFiCorreo,
      scoFiObservacion: newregistro.scoFiObservacion,
      scoFiEstado: estadoFI,
      scoAsesorCorreo: newregistro.scoAsesorCorreo,
      scoAsesorNombre: newregistro.scoAsesorNombre,
      scoAsesorTelefono: newregistro.scoAsesorTelefono,
      scoAsesorDireccion: newregistro.scoAsesorDireccion,
      idZona: newregistro.idZona,
      scoFilename: newregistro.scoFilename,
      idCrm: newregistro.idCrm,
      scoClienteNombre: newregistro.scoClienteNombre,
      scoClienteNit: newregistro.scoClienteNit,
      scoClienteCorreo: newregistro.scoClienteCorreo,
      scoClienteTelefono: newregistro.scoClienteTelefono,
      scoAgencia: newregistro.scoAgencia,
      spcId: newregistro.spcId,
      idColor: newregistro.idColor,
      scoColor: newregistro.scoColor,
      scoColorR: newregistro.scoColorR,
      scoColorG: newregistro.scoColorG,
      scoColorB: newregistro.scoColorB,
      scoPvpBase: newregistro.scoPvpBase,
      scoPvpEspecial: newregistro.scoPvpEspecial,
      scoExt1Descripcion: newregistro.scoExt1Descripcion,
      scoExt1Pvp: newregistro.scoExt1Pvp,
      scoExt1Cortesia: newregistro.scoExt1Cortesia,
      scoExt2Descripcion: newregistro.scoExt2Descripcion,
      scoExt2Pvp: newregistro.scoExt2Pvp,
      scoExt2Cortesia: newregistro.scoExt2Cortesia,
      scoExt3Descripcion: newregistro.scoExt3Descripcion,
      scoExt3Pvp: newregistro.scoExt3Pvp,
      scoExt3Cortesia: newregistro.scoExt3Cortesia,
      scoExt4Descripcion: newregistro.scoExt4Descripcion,
      scoExt4Pvp: newregistro.scoExt4Pvp,
      scoExt4Cortesia: newregistro.scoExt4Cortesia,
      scoExt5Descripcion: newregistro.scoExt5Descripcion,
      scoExt5Pvp: newregistro.scoExt5Pvp,
      scoExt5Cortesia: newregistro.scoExt5Cortesia,
      scoExt6Descripcion: newregistro.scoExt6Descripcion,
      scoExt6Pvp: newregistro.scoExt6Pvp,
      scoExt6Cortesia: newregistro.scoExt6Cortesia,
      scoExt7Descripcion: newregistro.scoExt7Descripcion,
      scoExt7Pvp: newregistro.scoExt7Pvp,
      scoExt7Cortesia: newregistro.scoExt7Cortesia,
      scoExt8Descripcion: newregistro.scoExt8Descripcion,
      scoExt8Pvp: newregistro.scoExt8Pvp,
      scoExt8Cortesia: newregistro.scoExt8Cortesia,
      scoDesc1Descripcion: newregistro.scoDesc1Descripcion,
      scoDesc1Valor: newregistro.scoDesc1Valor,
      scoDesc2Descripcion: newregistro.scoDesc2Descripcion,
      scoDesc2Valor: newregistro.scoDesc2Valor,
      scoDesc3Descripcion: newregistro.scoDesc3Descripcion,
      scoDesc3Valor: newregistro.scoDesc3Valor,
      scoDesc4Descripcion: newregistro.scoDesc4Descripcion,
      scoDesc4Valor: newregistro.scoDesc4Valor,
      scoTerminos: newregistro.scoTerminos,
      scoMatriculaMonto: newregistro.scoMatriculaMonto,
      scoEntradaMonto: newregistro.scoEntradaMonto,
      scoEntradaPorcen: newregistro.scoEntradaPorcen,
      scoFinanciarMonto: newregistro.scoFinanciarMonto,
      scoSeguroVeh: newregistro.scoSeguroVeh,
      scoSeguroCuotas: newregistro.scoSeguroCuotas,
      scoMuestra12Meses: newregistro.scoMuestra12Meses,
      scoValor12Meses: newregistro.scoValor12Meses,
      scoMuestra24Meses: newregistro.scoMuestra24Meses,
      scoValor24Meses: newregistro.scoValor24Meses,
      scoMuestra36Meses: newregistro.scoMuestra36Meses,
      scoValor36Meses: newregistro.scoValor36Meses,
      scoMuestra48Meses: newregistro.scoMuestra48Meses,
      scoValor48Meses: newregistro.scoValor48Meses,
      scoMuestra60Meses: newregistro.scoMuestra60Meses,
      scoValor60Meses: newregistro.scoValor60Meses,
      scoMuestra72Meses: newregistro.scoMuestra72Meses,
      scoValor72Meses: newregistro.scoValor72Meses,
      scoObservacionesCotizacion: newregistro.scoObservacionesCotizacion,
    };
    console.log(
      "registro a guardar >> ",
      JSON.stringify(nuevoRegistro, null, 4)
    );
    await postGuardarRegistro(nuevoRegistro, esAprobado);
    setLoading(false);
  }


  async function postGuardarRegistro(registro: SateliteCotizacionType, esAprobado: Boolean) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post("https://apisatelite.azurewebsites.net/satelitecotizacion", registro, {
          headers: headers,
        })
        .then((response) => {
          if (esAprobado) {
            setOpenDialogAutorizado(true);
          } else {
            setOpenDialogNegado(true);
          }
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function getRegistroCotizacion() {
    try {
      await axios
        .get<SateliteCotizacionType>(
          "https://apisatelite.azurewebsites.net/satelitecotizacion/" + String(params.id)
        )
        .then((response) => {
          setRegistroCotizador(response.data);
          getParametros(response.data.spcId);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          console.log("alerta por activar >>> en catch");
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getParametros(_id: number) {
    try {
      await axios
        .get<SateliteParametroCotizadorType>("https://apisatelite.azurewebsites.net/sateliteparametrocotizador/" + String(_id))
        .then((response) => {
          setParametroCotizador(response.data)
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  const imageName = parametroCotizador.spcImagen1;
  const imageSrc = images[imageName];
  const [loading, setLoading] = useState(false);
  const convertirTelefono = (telefono: string) => {
    if (telefono.startsWith('0')) {
      return `593${telefono.slice(1)}`; // Reemplazar el primer 0 por 593
    }
    return telefono; // Si no empieza con 0, se retorna igual
  };
  const filename = `Cotización ${registroCotizador.scoClienteNombre}.pdf`;

  const concesionarios: { [key: number]: string } = {
    1: "PROAUTO",
    2: "EMAULME",
    3: "MIRASOL"
  };

  const idZona = registroCotizador.idZona ?? 0;  // Si es undefined o null, usa 0 como valor por defecto

  const descuentoExt1 = registroCotizador.scoExt1Cortesia ? registroCotizador.scoExt1Pvp : 0;
  const descuentoExt2 = registroCotizador.scoExt2Cortesia ? registroCotizador.scoExt2Pvp : 0;
  const descuentoExt3 = registroCotizador.scoExt3Cortesia ? registroCotizador.scoExt3Pvp : 0;
  const descuentoExt4 = registroCotizador.scoExt4Cortesia ? registroCotizador.scoExt4Pvp : 0;
  const descuentoExt5 = registroCotizador.scoExt5Cortesia ? registroCotizador.scoExt5Pvp : 0;
  const descuentoExt6 = registroCotizador.scoExt6Cortesia ? registroCotizador.scoExt6Pvp : 0;
  const descuentoExt7 = registroCotizador.scoExt7Cortesia ? registroCotizador.scoExt7Pvp : 0;
  const descuentoExt8 = registroCotizador.scoExt8Cortesia ? registroCotizador.scoExt8Pvp : 0;


  //  const totalDescuento = descuentoExt1 + descuentoExt2 + descuentoExt3 + descuentoExt4 + descuentoExt5 + descuentoExt6 + descuentoExt7 + descuentoExt8 + registroCotizador.scoDesc1Valor + registroCotizador.scoDesc2Valor + registroCotizador.scoDesc3Valor + registroCotizador.scoDesc4Valor;
  const totalDescuento = registroCotizador.scoDesc1Valor + registroCotizador.scoDesc2Valor + registroCotizador.scoDesc3Valor + registroCotizador.scoDesc4Valor;

  const concesionario = (idZona in concesionarios)
    ? concesionarios[idZona]
    : "PROAUTO";

  const asunto = `Cotización ${registroCotizador.scoClienteNombre}`; // Usa el primer nombre y primer apellido

  const cuerpo =
    `Estimad@ ${registroCotizador.scoClienteNombre},<br><br>
    Nos es grato en ${concesionario} dirigirnos a usted para saludarlo y brindarle información completa del vehículo de su interés.<br><br>
    Adjunto a este correo encontrará la cotización del vehículo Chevrolet ${parametroCotizador?.spcMarcaModeloVersion}, en la cual detallamos, entre otros aspectos, la motorización, equipamiento exterior e interior, alternativas de color, precio y opciones de financiamiento.<br><br>
    En ${concesionario} estaremos gustosos de resolver cualquier inquietud que pudiese tener, coordinar una prueba de manejo y asesorarlo en el proceso.<br><br>
    Sabemos que la compra de un vehículo es una decisión importante y por ello nos ponemos a su disposición. De igual forma, le ofrecemos el mejor servicio posventa con la más amplia cobertura de talleres y repuestos genuinos Chevrolet a nivel nacional.<br><br>
    Agradezco nuevamente por su interés y espero poder ayudarle a tomar la mejor decisión en su compra.<br><br>
    Cordialmente,<br><br>
    ${registroCotizador.scoAsesorNombre}<br>
    Asesor Comercial<br>
    ${concesionario}<br>
    Teléfono: ${registroCotizador.scoAsesorTelefono}<br>
    Correo: ${registroCotizador.scoAsesorCorreo}`;

  const getFileName = (idZona: number | null): string => {
    switch (idZona) {
      case 1:
        return 'z1.png';
      case 2:
        return 'z2.png';
      case 3:
        return 'z3.png';
      default:
        return 'z1.png';
    }
  };



  const enviarDatos = async () => {
    setLoading(true);
    const telefonoConPrefijo = convertirTelefono(String(registroCotizador.scoClienteTelefono));
    const generarTextosExtras = (extras: Extra[]): any[] => {
      return extras.map((extra, index) => {
        if (index < 4) {

          const yPosition = 670 - (index * 20);
          return [
            {
              text: extra.cortesia ? `${extra.descripcion} *` : String(extra.descripcion) === "null" ? "" : `${extra.descripcion}`,
              x: 30,
              y: yPosition,
              fontSize: 10,
              isBold: true,
              pageNumber: 2,
              color: {
                r: registroCotizador.scoColorR * 255,
                g: registroCotizador.scoColorG * 255,
                b: registroCotizador.scoColorB * 255,
              },
            },
            {
              text: String(extra.descripcion) === "null" ? "" : formatter.format(extra.pvp).replace("$", "$ "),
              x: 180,
              xEnd: 250,
              y: yPosition,
              fontSize: 11,
              alignment: "right",
              isBold: false,
              pageNumber: 2,
              color: {
                r: 0.35 * 255,
                g: 0.35 * 255,
                b: 0.35 * 255,
              },
            },
          ];
        } else {
          // Manejo de los extras 5 al 8
          const yPosition = 670 - ((index - 4) * 20);
          return [
            {
              text: extra.cortesia ? `${extra.descripcion} *` : String(extra.descripcion) === "null" ? "" : `${extra.descripcion}`,
              x: 320,
              y: yPosition,
              fontSize: 10,
              isBold: true,
              pageNumber: 2,
              color: {
                r: registroCotizador.scoColorR * 255,
                g: registroCotizador.scoColorG * 255,
                b: registroCotizador.scoColorB * 255,
              },
            },
            {
              text: String(extra.descripcion) === "null" ? "" : formatter.format(extra.pvp).replace("$", "$ "),
              x: 480,
              xEnd: 550,
              y: yPosition,
              fontSize: 11,
              alignment: "right",
              isBold: false,
              pageNumber: 2,
              color: {
                r: 0.35 * 255,
                g: 0.35 * 255,
                b: 0.35 * 255,
              },
            },
          ];
        }
      }).flat();

    };


    const obtenerFechaFormateada = () => {
      const meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
      const fechaActual = new Date();
      const dia = fechaActual.getDate().toString().padStart(2, '0');
      const mes = meses[fechaActual.getMonth()];
      const año = fechaActual.getFullYear();

      return `${dia}.${mes}.${año}`;
    };

    const generarTextoPrecio = (extras: Extra[]): any => {
      const texto = totalDescuento > 0 ? "PRECIO ESPECIAL" : dataExtras[0].descripcion?.length > 0 ? "PRECIO ESPECIAL" : "PRECIO FINAL";
      return {
        text: texto,
        x: 30,
        y: 562,
        fontSize: 11,
        alignment: "left",
        isBold: true,
        pageNumber: 2,
        color: {
          r: 247,
          g: 247,
          b: 247,
        },
      };
    };

    const dynamicJson = {
      phoneNumber: telefonoConPrefijo,
      dealId: registroCotizador.idCrm,
      color: registroCotizador.idColor,
      filename: filename,
      cliente: registroCotizador.scoClienteNombre,
      concesionario: concesionario,
      destinatario: registroCotizador.scoClienteCorreo,
      modelo: parametroCotizador.spcMarcaModeloVersion,
      nombreAsesor: registroCotizador.scoAsesorNombre,
      telefonoAsesor: registroCotizador.scoAsesorTelefono,
      correoAsesor: registroCotizador.scoAsesorCorreo,
      asunto: asunto,
      cuerpo: cuerpo,
      esProduccion: true,
      pdfData: {
        texts: [
          {
            text: parametroCotizador?.spcNombre || "",
            "x": 12,
            "y": 740,
            "fontSize": 20,
            "font": "Helvetica",
            "alignment": "left",
            "isBold": true,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcText2 || "",
            "x": 12,
            "y": 720,
            "fontSize": 13,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": `${obtenerFechaFormateada()}  |  Cotización N° ${registroCotizador.idCrm}`,
            "x": 400,
            "y": 720,
            "fontSize": 9,
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35

            }
          },
          {
            "text": "Señor(a):",
            "x": 30,
            "y": 430,
            "fontSize": 11,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35

            }
          },
          {
            text: `${registroCotizador.scoClienteNombre}`,
            "x": 30,
            "y": 415,
            "fontSize": 11,
            "alignment": "left",
            "isBold": true,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: "Presente",
            x: 30,
            y: 400,
            fontSize: 11,
            alignment: "left",
            isBold: false,
            pageNumber: 1,
            color: {
              r: 0.35,
              g: 0.35,
              b: 0.35
            }
          },

          {
            "text": "Nos es grato brindarle a continuación información detallada del vehículo de su interés",
            "x": 30,
            "y": 380,
            "fontSize": 11,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35

            }
          },
          {
            text: `${parametroCotizador?.spcMarcaModeloVersion}`,
            //text: `${modelo}`,
            //text: `${response.data.spcMarcaModeloVersion}`,
            "x": 226,
            "y": 335.9,
            "fontSize": 14,
            "alignment": "left",
            "isBold": true,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${registroCotizador.scoColor}`,
            "x": 80,
            "y": 314,
            "fontSize": 11,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica1 || "",
            "x": 45,
            "y": 245,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle1 || "",
            "x": 225,
            "y": 245,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica2 || "",
            "x": 45,
            "y": 225,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle2 || "",
            "x": 225,
            "y": 225,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica3 || "",
            "x": 45,
            "y": 205,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle3 || "",
            "x": 225,
            "y": 205,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica4 || "",
            "x": 45,
            "y": 185,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle4 || "",
            "x": 225,
            "y": 185,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica5 || "",
            "x": 45,
            "y": 165,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle5 || "",
            "x": 225,
            "y": 165,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica6 || "",
            "x": 45,
            "y": 145,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle6 || "",
            "x": 225,
            "y": 145,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica7 || "",
            "x": 45,
            "y": 125,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle7 || "",
            "x": 225,
            "y": 125,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcCaracteristica8 || "",
            "x": 45,
            "y": 105,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcDetalle8 || "",
            "x": 225,
            "y": 105,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },

          {
            text: parametroCotizador?.spcNombre || "",
            "x": 12,
            "y": 740,
            "fontSize": 20,
            "alignment": "left",
            "isBold": true,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcText2 || "",
            "x": 12,
            "y": 720,
            "fontSize": 13,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": `${obtenerFechaFormateada()}  |  Cotización N° ${registroCotizador.idCrm}`,
            "x": 400,
            "y": 720,
            "fontSize": 9,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${formatter.format(registroCotizador.scoPvpBase).replace("$", "$ ")}`,
            "x": 150,
            "y": 695,
            "fontSize": 11,
            xEnd: 250,
            "alignment": "right",
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          ...generarTextosExtras(dataExtras),

          {
            "text": totalDescuento > 0 ? "DESCUENTO" : "",
            "x": 30,
            "y": 585,
            "fontSize": 11,
            "isBold": true,
            "pageNumber": 2,
            "color": {
              "r": registroCotizador.scoColorR * 255,
              "g": registroCotizador.scoColorG * 255,
              "b": registroCotizador.scoColorB * 255
            }
          },
          generarTextoPrecio(dataExtras),
          {
            "text": totalDescuento > 0 ? `${formatter.format(totalDescuento).replace("$", "$ ")}` : "",
            "x": 150,
            "y": 585,
            "fontSize": 11,
            xEnd: 250,
            "alignment": "right",
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },

          {
            "text": `${formatter.format(Number(registroCotizador.scoPvpEspecial) - Number(registroCotizador.scoDesc1Valor)).replace("$", "$ ")} Inc. IVA`,
            "x": 182,
            "y": 561,
            "fontSize": 11.5,
            "alignment": "left",
            "isBold": true,
            "pageNumber": 2,
            "color": {
              "r": 247,
              "g": 247,
              "b": 247
            }
          },
          {
            "text": "Matrícula (Valor referencial SRI)",
            "x": 30,
            "y": 465,
            "fontSize": 11,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${formatter.format(registroCotizador.scoMatriculaMonto).replace("$", "$ ")}*`,
            "x": 198,
            "y": 465,
            "fontSize": 11,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "*Una vez facturado el vehículo se confirmará el valor final incluido impuestos.",
            "x": 30,
            "y": 450,
            "fontSize": 11,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": `${registroCotizador.scoTerminos.toUpperCase()}`,
            "x": 30,
            "xEnd": 550,
            "y": 388,
            "fontSize": 12,
            "alignment": "justify",
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `ENTRADA ${registroCotizador.scoEntradaPorcen.toFixed(2)} %`,
            "x": 30,
            "y": registroCotizador.scoTerminos.length > 0 ? 325 : 388,
            "fontSize": 10,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${formatter.format(registroCotizador.scoEntradaMonto).replace("$", "$ ")}`,
            "x": 100,
            "xEnd": 240,
            "y": registroCotizador.scoTerminos.length > 0 ? 325 : 388,
            "fontSize": 10,
            "alignment": "right",
            "isBold": true,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            },

          },

          {
            text: `MONTO A FINANCIAR`,
            "x": 30,
            "y": registroCotizador.scoTerminos.length > 0 ? 311 : 372,
            "fontSize": 10,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            },

          },
          {
            text: `${formatter.format(registroCotizador.scoFinanciarMonto).replace("$", "$ ")}`,
            "x": 100,
            "xEnd": 240,
            "alignment": "right",
            "y": registroCotizador.scoTerminos.length > 0 ? 311 : 372,
            "fontSize": 10,
            "isBold": true,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            },

          },

          {
            "text": registroCotizador.scoMuestra12Meses == 1
              ? `${formatter.format(registroCotizador.scoValor12Meses).replace("$", "$ ")}` : "",
            "x": 108,
            "y": 237,
            "fontSize": 10,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": registroCotizador.scoMuestra24Meses == 1
              ? `${formatter.format(registroCotizador.scoValor24Meses).replace("$", "$ ")}` : "",
            "x": 173.5,
            "y": 237,
            "fontSize": 10,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": registroCotizador.scoMuestra36Meses == 1
              ? `${formatter.format(registroCotizador.scoValor36Meses).replace("$", "$ ")}` : "",
            "x": 240,
            "y": 237,
            "fontSize": 10,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": registroCotizador.scoMuestra48Meses == 1
              ? `${formatter.format(registroCotizador.scoValor48Meses).replace("$", "$ ")}` : "",
            "x": 305,
            "y": 237,
            "fontSize": 10,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": registroCotizador.scoMuestra60Meses == 1
              ? `${formatter.format(registroCotizador.scoValor60Meses).replace("$", "$ ")}` : "",
            "x": 370,
            "y": 237,
            "fontSize": 10,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": registroCotizador.scoMuestra72Meses == 1
              ? `${formatter.format(registroCotizador.scoValor72Meses).replace("$", "$ ")}` : "",
            "x": 437,
            "y": 237,
            "fontSize": 10,
            "isBold": false,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": `${obtenerFechaFormateada()}  |  Cotización N° ${registroCotizador.idCrm}`,
            "x": 400,
            "y": 720,
            "fontSize": 9,
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcNombre || "",
            "x": 12,
            "y": 740,
            "fontSize": 20,
            "isBold": true,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcText2 || "",
            "x": 12,
            "y": 720,
            "fontSize": 13,
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcIdDispositivo === 1 ? "SERVICIO CHEVYSTAR" : "   SERVICIO ON-STAR",
            "x": 250,
            "y": 626,
            "fontSize": 9,
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 247,
              "g": 247,
              "b": 247
            }
          },

          {
            "text": "1 año",
            "x": 266,
            "y": 603,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcDispositivoA1}`,
            "x": 296,
            "y": 603,
            "fontSize": 9.5,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "2 años",
            "x": 265,
            "y": 587,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcDispositivoA2}`,
            "x": 298,
            "y": 587,
            "fontSize": 9.5,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "3 años",
            "x": 262,
            "y": 571,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcDispositivoA3}`,
            "x": 296,
            "y": 571,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "4 años",
            "x": 262,
            "y": 555,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcDispositivoA4}`,
            "x": 296,
            "y": 555,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "5 años",
            "x": 262,
            "y": 539,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcDispositivoA5}`,
            "x": 296,
            "y": 539,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },

          {
            "text": "Vehículo",
            "x": 216,
            "y": 420,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {

            text: `${formatter.format(registroCotizador.scoSeguroVeh).replace("$", "$ ")}`,
            "x": 333,
            "y": 420,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }

          },
          {
            "text": "CUOTAS",
            "x": 216,
            "y": 400,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {

            text: `12 cuotas de ${formatter.format(registroCotizador.scoSeguroCuotas).replace("$", "$ ")}`,
            "x": 308,
            "y": 400,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Valor incluye amparo patrimonial",
            "x": 228,
            "y": 359,
            "fontSize": 9,
            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan1Tipo}`,
            "x": 66,
            "y": 262,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcFamilia},`,
            "x": 145,
            "y": 262,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan2Modelo}`,
            "x": 134.5,
            "y": 248,
            "fontSize": 7.8,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Mantenimiento",
            "x": 218,
            "y": 262,
            "fontSize": 9,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan1Descripcion}`,
            "x": 217.3,
            "y": 248,
            "fontSize": 7,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcPlan1PvpNormal}`,
            "x": 320,
            "y": 258,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcPlan1PvpMpc}`,
            "x": 402,
            "y": 258,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan1Cobertura}`,
            "x": 457,
            "y": 258,
            "fontSize": 8.3,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan2Tipo}`,
            "x": 66,
            "y": 233,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcFamilia},`,
            "x": 145,
            "y": 233,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan2Modelo}`,
            "x": 134.5,
            "y": 222,
            "fontSize": 7.8,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Mantenimiento",
            "x": 218,
            "y": 233,
            "fontSize": 9,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan2Descripcion}`,
            "x": 217.3,
            "y": 222,
            "fontSize": 7,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcPlan2PvpNormal}`,
            "x": 320,
            "y": 230,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `$${parametroCotizador.spcPlan2PvpMpc}`,
            "x": 402,
            "y": 230,
            "fontSize": 9.5,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${parametroCotizador.spcPlan2Cobertura}`,
            "x": 457,
            "y": 230,
            "fontSize": 8.3,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Cordialmente,",
            "x": 46,
            "y": 150,
            "fontSize": 11,

            "alignment": "left",
            "isBold": true,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${registroCotizador.scoAsesorNombre}`,
            "x": 46,
            "y": 135,
            "fontSize": 11,
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Su Asesor Comercial Chevrolet ",
            "x": 46,
            "y": 120,
            "fontSize": 11,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Mail:",
            "x": 46,
            "y": 105,
            "fontSize": 11,

            "alignment": "left",
            "isBold": true,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${registroCotizador.scoAsesorCorreo}`,
            "x": 76,
            "y": 105,
            "fontSize": 11,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Cel.:",
            "x": 46,
            "y": 90,
            "fontSize": 11,

            "alignment": "left",
            "isBold": true,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: `${registroCotizador.scoAsesorTelefono}`,
            "x": 76,
            "y": 90,
            "fontSize": 11,

            "alignment": "left",
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Dir.:",
            "x": 46,
            "y": 75,
            "fontSize": 11,
            "isBold": true,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": registroCotizador.scoAsesorDireccion,
            "x": 76,
            "y": 75,
            "fontSize": 11,
            "isBold": false,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": `${obtenerFechaFormateada()}  |  Cotización N° ${registroCotizador.idCrm}`,
            "x": 400,
            "y": 720,
            "fontSize": 9,
            "isBold": false,
            "pageNumber": 4,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            text: parametroCotizador?.spcNombre || "",
            "x": 12,
            "y": 742,
            "fontSize": 20,
            "isBold": true,
            "pageNumber": 4,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35

            }
          },
          {
            "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
            "x": 275,
            "y": 18,
            "fontSize": 9,
            "isBold": true,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
            "x": 275,
            "y": 18,
            "fontSize": 9,
            "isBold": true,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },

          {
            text: parametroCotizador?.spcText2 || "",
            "x": 12,
            "y": 723,
            "fontSize": 13,
            "isBold": false,
            "pageNumber": 4,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
            "x": 275,
            "y": 18,
            "fontSize": 9,
            "isBold": true,
            "pageNumber": 1,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
            "x": 275,
            "y": 18,
            "fontSize": 9,
            "isBold": true,
            "pageNumber": 2,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
            "x": 275,
            "y": 18,
            "fontSize": 9,
            "isBold": true,
            "pageNumber": 3,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },
          {
            "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
            "x": 275,
            "y": 18,
            "fontSize": 9,
            "isBold": true,
            "pageNumber": 4,
            "color": {
              "r": 0.35,
              "g": 0.35,
              "b": 0.35
            }
          },


        ],
        images: [
          {
            fileName: parametroCotizador?.spcImagen1,
            //fileName: 'montana_1.png',
            x: 0,
            y: 468,
            pageNumber: 1,
            width: 595,
            height: 240
          },
          {
            fileName: parametroCotizador?.spcImagen2,
            //fileName: 'captivaxl_2.png',
            "x": 0,
            "y": 420,
            "pageNumber": 4,
            "width": 595,
            "height": 240
          },
          {
            //fileName: parametroCotizador?.spcImagen1,
            fileName: getFileName(registroCotizador.idZona),
            x: 50,
            y: 798,
            pageNumber: 1,
            width: 125,
            height: 21
          },
          {
            fileName: getFileName(registroCotizador.idZona),
            x: 50,
            y: 798,
            pageNumber: 2,
            width: 125,
            height: 21
          },
          {
            fileName: getFileName(registroCotizador.idZona),
            x: 50,
            y: 798,
            pageNumber: 3,
            width: 125,
            height: 21
          },
          {
            fileName: getFileName(registroCotizador.idZona),
            x: 50,
            y: 798,
            pageNumber: 4,
            width: 125,
            height: 21
          },
          {
            fileName: parametroCotizador?.spcIdDispositivo === 1 ? "chevystar.png" : "onstar.png",
            x: 195,
            y: 638,
            pageNumber: 3,
            width: 80,
            height: 35
          }
          ,
          {
            fileName: "manita.png",
            x: 305,
            y: 630,
            pageNumber: 3,
            width: 100,
            height: 38
          },
          {
            fileName: "vinetas.png",
            x: 30,
            y: 100,
            pageNumber: 1,
            width: 12,
            height: 155
          },
          {
            fileName: "colores.png",
            x: 500,
            y: 105,
            pageNumber: 1,
            width: 40,
            height: 171
          },
          {
            fileName: parametroCotizador?.spcGarantia === 7 ? "7anos.png" : "8anos.png",
            x: 30,
            y: 40,
            pageNumber: 2,
            width: 120,
            height: 120
          },
        ],

        links: [
          {
            url: parametroCotizador?.spcIdDispositivo === 1 ? "https://www.proautochevrolet.com.ec/chevystar" : "https://www.proautochevrolet.com.ec/chevrolet-onstar",
            x: 225,
            y: 625,
            pageNumber: 3,
            width: 150,
            height: 50
          },
          {
            url: parametroCotizador?.spcLink1,

            x: 85,
            y: 660,
            pageNumber: 4,
            width: 150,
            height: 50
          }
        ],
      }
    };
    console.log('mi json para peticion', JSON.stringify(dynamicJson))
    try {
      await axios.post("https://apisatelite.azurewebsites.net/cotizador/sendpdf", dynamicJson)
        .then((response) => {
          console.log("mi respuesta es >> ", response);
          setOpenDialogEnviadoOk(true);
        });
    } catch (error) {
      console.log('tengo error >> ', error)
      setOpenDialogEnviadoError(true)
      if (axios.isAxiosError(error)) {
        console.error("Error al enviar los datos:", error.response?.data || error.message);
      } else {
        console.error("Error desconocido:", error);
      }
    }
  };


  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Cotización
              </Typography>
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 2
              }}
            >
              {isMobile ? (
                <>
                  <Grid>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{registroCotizador.scoFecha.toString().substring(0, 10)}{" | "}{registroCotizador.idCrm}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "100%",
                        fontSize: 26
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{parametroCotizador.spcNombre}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 3,
                        width: "100%",

                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcText2}
                    </Typography>

                    <img src={imageSrc} alt={imageName} style={{ width: "100%", height: "auto" }} />
                    <Typography
                      sx={{
                        mt: 3,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{registroCotizador.scoClienteNombre}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Documento Identidad: {registroCotizador.scoClienteNit}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Correo: {registroCotizador.scoClienteCorreo}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Teléfono: {registroCotizador.scoClienteTelefono}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                        fontSize: 18
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Marca / Modelo / Versión / Año: <strong>{parametroCotizador.spcMarcaModeloVersion}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 3,
                        width: "100%",
                        fontSize: 18
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Color: <strong>Rojo</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica1}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle1}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica2}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle2}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica3}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle3}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica4}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle4}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica5}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle5}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica6}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle6}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica7}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle7}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica8}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle8}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Precio de venta {" "}{formatter.format(registroCotizador.scoPvpBase).replace("$", "  $ ")}
                    </Typography>

                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Grid container spacing={2}>
                        {dataExtras
                          .filter((item) => (item.descripcion) != null) // Filtra elementos con descripción válida
                          .map((item, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                              <Box sx={{ padding: 0 }}>
                                <Typography sx={{ width: "75%" }} style={{ display: "inline-block" }} variant="subtitle1">◉{" "}{item.descripcion} {item.cortesia ? " (Cortesía)" : ""}</Typography>
                                <Typography sx={{ width: "25%", textAlign: "right" }} style={{ display: "inline-block" }} variant="body1">
                                  {formatter.format(item.pvp).replace("$", "$ ")}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </Box>

                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Descuento total: <strong>{formatter.format(totalDescuento).replace("$", "$ ")}</strong></strong>
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Precio especial:<strong>{formatter.format(Number(registroCotizador.scoPvpEspecial) - Number(registroCotizador.scoDesc1Valor)).replace("$", "$ ")}</strong> </strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Matricula: <strong>{formatter.format(registroCotizador.scoMatriculaMonto).replace("$", "$ ")}</strong></strong>
                    </Typography>


                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />

                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Términos de negociación</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {registroCotizador.scoTerminos}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Entrada: <strong>{formatter.format(registroCotizador.scoEntradaMonto).replace("$", "$ ")}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Monto a financiar:<strong>{formatter.format(registroCotizador.scoFinanciarMonto).replace("$", "$ ")}</strong>
                    </Typography>

                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cuotas mensuales referenciales de mercado</strong>
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly", // Distribución equitativa
                        alignItems: "center",          // Centrado vertical
                        flexWrap: "wrap",              // Permite que los elementos se ajusten si no hay espacio
                        width: "100%",               // Ocupa el ancho completo
                      }}
                    >
                      {dataCuotas.map(
                        (item, index) =>
                          item.muestra == 1 && ( // Mostrar solo si existe el dato
                            <Box
                              key={index}
                              sx={{
                                textAlign: "center",   // Centra el texto dentro de cada elemento
                                padding: 1,
                                flex: "1 1 13%",       // Controla el tamaño flexible de cada elemento
                                minWidth: "120px",     // Ancho mínimo para elementos pequeños
                              }}
                            >
                              <Typography variant="subtitle1"><strong>{item.label}</strong></Typography>
                              <Typography variant="h6">{formatter.format(item.valor).replace("$", "$ ")}</Typography>
                            </Box>
                          )
                      )}
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dispositivo {parametroCotizador.spcDispositivo}</strong>
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",           // Ocupa el 80% del ancho de la pantalla
                        margin: "0 auto",       // Margen automático para centrar
                      }}
                    >
                      <Grid container spacing={2}>
                        {dataDispositivo.map((item, index) => (
                          <Grid item xs={6} sm={2.4} key={index}>
                            <Box
                              sx={{
                                textAlign: "center", // Centrar el contenido
                              }}
                            >
                              <Typography variant="subtitle1">{<strong>{item.label}</strong>}</Typography>
                              <Typography variant="body1">{formatter.format(Number(item.value)).replace("$", "$ ")}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Seguro vehícular</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Vehículo<strong>{formatter.format(registroCotizador.scoSeguroVeh).replace("$", " $ ")}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Cuotas<strong>{formatter.format(registroCotizador.scoSeguroCuotas).replace("$", " $ ")}</strong>
                    </Typography>

                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Mantenimientos prepagados</strong>
                    </Typography>

                    <Box
                      sx={{        // Ocupa el 80% del ancho de la pantalla
                        margin: "0 auto",       // Margen automático para centrar
                        padding: 2,
                      }}
                    >
                      <Grid container spacing={2}>
                        {dataMantenimientos.map((item, index) => (
                          <Grid item xs={6} sm={2.4} key={index}>
                            <Box
                              sx={{
                                textAlign: "center", // Centrar el contenido
                              }}
                            >
                              <Typography variant="subtitle1">{<strong>{item.label}</strong>}</Typography>
                              <Typography variant="body1">{item.value1}</Typography>
                              <Typography variant="body1">{item.value2}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Cordialmente,
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {registroCotizador.scoAsesorNombre}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Su Asesor Comercial Chevrolet
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Mail:</strong>{registroCotizador.scoAsesorCorreo}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cel.:</strong>{registroCotizador.scoAsesorTelefono}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dir.:</strong>{registroCotizador.scoAsesorDireccion}
                    </Typography>

                    <Divider sx={{ mt: 4, mb: 4, ml: "3%", mr: "3%" }} />
                    {/* {username === registroCotizador.scoAsesorCorreo && (registroCotizador.scoComercialEstado === 0 || registroCotizador.scoComercialEstado === 2) && (registroCotizador.scoFiEstado === 0 || registroCotizador.scoFiEstado === 2) ? <> */}
                    {(registroCotizador.scoComercialEstado === 0 || registroCotizador.scoComercialEstado === 2) && (registroCotizador.scoFiEstado === 0 || registroCotizador.scoFiEstado === 2) ? <>
                      <Button
                        color={loading ? "inherit" : "success"}
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "80%",
                          marginLeft: "10%",
                        }}
                        onClick={async () => {
                          setLoading(true);
                          await enviarDatos();
                          setLoading(false);
                        }}
                      >
                        {loading ? (
                          <>
                            <CircularProgress size={24} sx={{ position: 'absolute' }} />
                            {"Espere por favor"}
                          </>
                        ) : (
                          "Enviar Cotización"
                        )}
                      </Button>
                    </> : <>

                    </>}

                    {username === registroCotizador.scoComercialCorreo && registroCotizador.scoComercialEstado === 1 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones: Perfil Comercial
                      </Typography>
                      <Button
                        color="success"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "45%",
                          marginLeft: "3%",
                          backgroundColor: green,
                          Colorize: "green",
                        }}
                        onClick={() => { seteoRegistro(2, registroCotizador.scoFiEstado, registroCotizador, true) }}
                      >
                        Aprobar
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "45%",
                          marginLeft: "3%",
                        }}
                        onClick={() => { seteoRegistro(9, registroCotizador.scoFiEstado, registroCotizador, false) }}
                      >
                        Denegar
                      </Button>
                    </> : <>
                    </>}
                    {registroCotizador.scoComercialEstado === 2 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones Comercial aprobados por: {registroCotizador.scoComercialNombre}
                      </Typography>
                    </> : <>
                    </>}
                    {registroCotizador.scoComercialEstado === 9 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones Comercial Negados por: {registroCotizador.scoComercialNombre}
                      </Typography>
                    </> : <>
                    </>}

                    {username === registroCotizador.scoFiCorreo && registroCotizador.scoFiEstado === 1 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones: Perfil F&I
                      </Typography>
                      <Button
                        color="success"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "45%",
                          marginLeft: "3%",
                          backgroundColor: green,
                          Colorize: "green",
                        }}
                        onClick={() => { seteoRegistro(registroCotizador.scoComercialEstado, 2, registroCotizador, true) }}
                      >
                        Aprobar
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "45%",
                          marginLeft: "3%",
                        }}
                        onClick={() => { seteoRegistro(registroCotizador.scoComercialEstado, 9, registroCotizador, false) }}
                      >
                        Denegar
                      </Button>
                    </> : <>
                    </>}
                    {registroCotizador.scoFiEstado === 2 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones F&I aprobados por: {registroCotizador.scoFiNombre}
                      </Typography>
                    </> : <>
                    </>}

                    {registroCotizador.scoFiEstado === 9 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones F&I Negados por: {registroCotizador.scoFiNombre}
                      </Typography>
                    </> : <>
                    </>}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                        marginLeft: "10%",
                        fontSize: 26
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{parametroCotizador.spcNombre}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                        textAlign: "right"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{registroCotizador.scoFecha.toString().substring(0, 10)}{" | "}{registroCotizador.idCrm}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 3,
                        width: "50%",
                        marginLeft: "10%",

                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcText2}
                    </Typography>

                    <img src={imageSrc} alt={imageName} style={{ maxWidth: "80%", marginLeft: "10%", height: "auto" }} />;
                    <Typography
                      sx={{
                        mt: 3,
                        mb: 0.5,
                        width: "60%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{registroCotizador.scoClienteNombre}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Documento Identidad: {registroCotizador.scoClienteNit}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Correo: {registroCotizador.scoClienteCorreo}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Teléfono: {registroCotizador.scoClienteTelefono}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                        fontSize: 18
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Marca / Modelo / Versión / Año: <strong>{parametroCotizador.spcMarcaModeloVersion}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 3,
                        width: "80%",
                        marginLeft: "10%",
                        fontSize: 18
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Color: <strong>Rojo</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica1}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle1}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica2}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle2}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica3}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle3}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica4}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle4}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica5}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle5}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica6}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle6}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica7}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle7}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "35%",
                        marginLeft: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      🔵 {parametroCotizador.spcCaracteristica8}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {parametroCotizador.spcDetalle8}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Precio de venta
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(registroCotizador.scoPvpBase).replace("$", "$ ")}
                    </Typography>
                    <Box
                      sx={{
                        width: "80%",
                        margin: "0 auto",
                        padding: 2,
                      }}
                    >
                      <Grid container spacing={2}>
                        {dataExtras
                          .filter((item) => (item.descripcion) != null) // Filtra elementos con descripción válida
                          .map((item, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                              <Box sx={{ padding: 0 }}>
                                <Typography sx={{ width: "70%" }} style={{ display: "inline-block" }} variant="subtitle1">◉{" "}{item.descripcion} {item.cortesia ? " (Cortesía)" : ""}</Typography>
                                <Typography sx={{ width: "20%", textAlign: "right" }} style={{ display: "inline-block" }} variant="body1">
                                  {formatter.format(item.pvp).replace("$", "$ ")}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Descuento total: </strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{formatter.format(totalDescuento).replace("$", "$ ")}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Precio especial: </strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{formatter.format(Number(registroCotizador.scoPvpEspecial) -  Number(registroCotizador.scoDesc1Valor)).replace("$", "$ ")}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Matricula: </strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{formatter.format(registroCotizador.scoMatriculaMonto).replace("$", "$ ")}</strong>
                    </Typography>

                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />

                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Términos de negociación</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {registroCotizador.scoTerminos}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Entrada:
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{formatter.format(registroCotizador.scoEntradaMonto).replace("$", "$ ")}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Monto a financiar:
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{formatter.format(registroCotizador.scoFinanciarMonto).replace("$", "$ ")}</strong>
                    </Typography>


                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cuotas mensuales referenciales de mercado</strong>
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly", // Distribución equitativa
                        alignItems: "center",          // Centrado vertical
                        flexWrap: "wrap",              // Permite que los elementos se ajusten si no hay espacio
                        width: "80%",
                        ml: "10%",                 // Ocupa el ancho completo
                        padding: 2,
                      }}
                    >
                      {dataCuotas.map(
                        (item, index) =>
                          item.muestra == 1 && ( // Mostrar solo si existe el dato
                            <Box
                              key={index}
                              sx={{
                                textAlign: "center",   // Centra el texto dentro de cada elemento
                                padding: 1,
                                flex: "1 1 13%",       // Controla el tamaño flexible de cada elemento
                                minWidth: "120px",     // Ancho mínimo para elementos pequeños
                              }}
                            >
                              <Typography variant="subtitle1"><strong>{item.label}</strong></Typography>
                              <Typography variant="h6">{formatter.format(item.valor).replace("$", "$ ")}</Typography>
                            </Box>
                          )
                      )}
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dispositivo {parametroCotizador.spcDispositivo}</strong>
                    </Typography>
                    <Box
                      sx={{
                        width: "80%",           // Ocupa el 80% del ancho de la pantalla
                        margin: "0 auto",       // Margen automático para centrar
                        padding: 2,
                      }}
                    >
                      <Grid container spacing={2}>
                        {dataDispositivo.map((item, index) => (
                          <Grid item xs={12} sm={2.4} key={index}>
                            <Box
                              sx={{
                                textAlign: "center", // Centrar el contenido
                              }}
                            >
                              <Typography variant="subtitle1">{<strong>{item.label}</strong>}</Typography>
                              <Typography variant="body1">{formatter.format(Number(item.value)).replace("$", "$ ")}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Seguro vehícular</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Vehículo
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{formatter.format(registroCotizador.scoSeguroVeh).replace("$", "$ ")}</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Cuotas
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{formatter.format(registroCotizador.scoSeguroCuotas).replace("$", "$ ")}</strong>
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Mantenimientos prepagados</strong>
                    </Typography>

                    <Box
                      sx={{
                        width: "80%",           // Ocupa el 80% del ancho de la pantalla
                        margin: "0 auto",       // Margen automático para centrar
                        padding: 2,
                      }}
                    >
                      <Grid container spacing={2}>
                        {dataMantenimientos.map((item, index) => (
                          <Grid item xs={12} sm={2.4} key={index}>
                            <Box
                              sx={{
                                textAlign: "center", // Centrar el contenido
                              }}
                            >
                              <Typography variant="subtitle1">{<strong>{item.label}</strong>}</Typography>
                              <Typography variant="body1">{item.value1}</Typography>
                              <Typography variant="body1">{item.value2}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Cordialmente,
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {registroCotizador.scoAsesorNombre}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Su Asesor Comercial Chevrolet
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Mail:</strong>{registroCotizador.scoAsesorCorreo}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cel.:</strong>{registroCotizador.scoAsesorTelefono}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        width: "80%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dir.:</strong>{registroCotizador.scoAsesorDireccion}
                    </Typography>

                    <Divider sx={{ mt: 4, mb: 4, ml: "10%", mr: "10%" }} />
                    {username === registroCotizador.scoAsesorCorreo && (registroCotizador.scoComercialEstado === 0 || registroCotizador.scoComercialEstado === 2) && (registroCotizador.scoFiEstado === 0 || registroCotizador.scoFiEstado === 2) ? <>
                      {/* {(registroCotizador.scoComercialEstado === 0 || registroCotizador.scoComercialEstado === 2) && (registroCotizador.scoFiEstado === 0 || registroCotizador.scoFiEstado === 2) ? <> */}
                      <Button
                        color={loading ? "inherit" : "success"}
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "14%",
                          marginLeft: "43%",
                        }}
                        onClick={async () => {
                          setLoading(true);
                          await enviarDatos();
                          setLoading(false);
                        }}
                      >
                        {loading ? (
                          <>
                            <CircularProgress size={24} sx={{ position: 'absolute' }} />
                            {"Espere por favor"}
                          </>
                        ) : (
                          "Enviar Cotización"
                        )}
                      </Button>
                    </> : <>

                    </>}

                    {username === registroCotizador.scoComercialCorreo && registroCotizador.scoComercialEstado === 1 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones: Perfil Comercial
                      </Typography>
                      <Button
                        color="success"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "14%",
                          marginLeft: "35%",
                          backgroundColor: green,
                          Colorize: "green",
                        }}
                        onClick={() => { seteoRegistro(2, registroCotizador.scoFiEstado, registroCotizador, true) }}
                      >
                        Aprobar
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "14%",
                          marginLeft: "2%",
                        }}
                        onClick={() => { seteoRegistro(9, registroCotizador.scoFiEstado, registroCotizador, false) }}
                      >
                        Denegar
                      </Button>
                    </> : <>
                    </>}
                    {registroCotizador.scoComercialEstado === 2 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones Comercial aprobados por: {registroCotizador.scoComercialNombre}
                      </Typography>
                    </> : <>
                    </>}
                    {registroCotizador.scoComercialEstado === 9 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones Comercial Negados por: {registroCotizador.scoComercialNombre}
                      </Typography>
                    </> : <>
                    </>}

                    {username === registroCotizador.scoFiCorreo && registroCotizador.scoFiEstado === 1 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones: Perfil F&I
                      </Typography>
                      <Button
                        color="success"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "14%",
                          marginLeft: "35%",
                          backgroundColor: green,
                          Colorize: "green",
                        }}
                        onClick={() => { seteoRegistro(registroCotizador.scoComercialEstado, 2, registroCotizador, true) }}
                      >
                        Aprobar
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 1.5,
                          width: "14%",
                          marginLeft: "2%",
                        }}
                        onClick={() => { seteoRegistro(registroCotizador.scoComercialEstado, 9, registroCotizador, false) }}
                      >
                        Denegar
                      </Button>
                    </> : <>
                    </>}
                    {registroCotizador.scoFiEstado === 2 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones F&I aprobados por: {registroCotizador.scoFiNombre}
                      </Typography>
                    </> : <>
                    </>}

                    {registroCotizador.scoFiEstado === 9 ? <>

                      <Typography sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
                        Terminos y condiciones F&I Negados por: {registroCotizador.scoFiNombre}
                      </Typography>
                    </> : <>
                    </>}
                  </Grid>
                </>
              )}






              <Dialog
                open={openDialogEnviadoOk}
                //onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Cotización enviada correctamente
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={function (event) {
                      setOpenDialogEnviadoOk(false);
                    }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openDialogEnviadoError}
                //onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Ocurrio un error
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={function (event) {
                      setOpenDialogEnviadoError(false);
                    }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              {/* aqui va la validacion de autorizacion */}

              <Dialog
                open={openDialogAutorizado}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"¡Cotización aprobada!"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={(e) => setOpenDialogAutorizado(false)}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openDialogNegado}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"¡Cotización negada!"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={(e) => setOpenDialogNegado(false)}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openDialogEnviado}
                onClose={(e) => setOpenDialogEnviado(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Enviado correctamente!"}
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                  <Button
                    // href={`/ventas/index/${data.tarNumeroCotizacion}`}
                    onClick={(e) => setOpenDialogEnviado(false)}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid
          //container
          alignItems={"center"}
          justifyContent={"center"}
        //sx={{ minHeight: "90vh" }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 1.5,

              width: "20%",
              marginLeft: "40%",
            }}
            onClick={() => handleLogin("redirect")}
          //href="/listadotablarenta"
          >
            Inicia sesión
          </Button>
        </Grid>
      </Container>
    );
  }
};
