import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    MenuItem,
    Modal,
    Paper,
    Switch,
    TextField,
    Typography,
  } from "@mui/material";
  import axios from "axios";
  import React, { useEffect, useState } from "react";
  import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
  import {
    DimLineaType,
    SateliteCotizadorAutorizaType,
    VSateliteCotizadorAutorizaType,
    bodegaType,
    marcaType,
    zonaType,
  } from "../../types";
  import { DataGrid, GridColDef } from "@mui/x-data-grid";
  import { GridToolbarQuickFilter } from "@mui/x-data-grid";
  import { green, orange, red, } from "@mui/material/colors";
  import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
  
  export const AutorizacionCotizadorPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
  
    useEffect(() => {
      if (account && account.name) {
        console.log("este son los atributos de msal >>>>>>", account);
        setName(account.name);
        setUsername(account.username);
        console.log(account);
        getListaAutoriza();
        getZonas();
      } else {
        setName("");
      }
    }, [account]);
  
    const styleModal = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
  
    const listadoMarcas: marcaType[] = [
      { marId: 1, marNombre: "CHEVROLET" },
      { marId: 2, marNombre: "GAC" },
    ];
  
    const listadoPerfil: marcaType[] = [
      { marId: 1, marNombre: "COMERCIAL" },
      { marId: 2, marNombre: "F&I" },
    ];
  
    const listadoLinea: DimLineaType[] = [
      { id: 1, linea: "LIVIANOS" },
      { id: 2, linea: "PESADOS" },
      { id: 3, linea: "FLOTAS" },
    ];
    const listadoZonas: zonaType[] = [];
    const listadoBodegas: bodegaType[] = [];
  
    const [dataBodega, setDataBodega] = useState<bodegaType[]>(listadoBodegas);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dataZona, setDataZona] = useState<zonaType[]>(listadoZonas);
    const [mostrar, setMostrar] = React.useState(false);
    const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
    const isAuthenticated = useIsAuthenticated();
    const dataListaAutoriza: VSateliteCotizadorAutorizaType[] = [];
  
    const [registroAutoriza, setRegistroAutoriza] =
      useState<VSateliteCotizadorAutorizaType>({
        scaId: 0,
        idZona: 0,
        idMarca: 0,
        scaNombre: "",
        scaCorreo: "",
        zona: "",
        marca: "",
        perfil: "",
        idBodega: 0,
        niveles: "",
        familia: "",
        scaTelefono: "",
        idLinea: 0,
        linea: "",
      });
  
  
    const [listaAutorizaRows, setListaAutorizaRows] =
      useState<VSateliteCotizadorAutorizaType[]>(dataListaAutoriza);
  
  
    const [zona, setZona] = React.useState(0);
    const [marca, setMarca] = React.useState(0);
    const [agencia, setAgencia] = React.useState(0);
    const [linea, setLinea] = React.useState(0);
    const [perfil, setPerfil] = React.useState(0);
  
    const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
      setZona(Number(event.target.value));
      getBodegas(Number(event.target.value), 0);
    };
  
    const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgencia(Number(event.target.value));
    };
  
    const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMarca(Number(event.target.value));
    };
  
    const handleChangeLinea = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLinea(Number(event.target.value));
    };
  
  
  
    async function getListaAutoriza() {
      try {
        await axios
          .get<VSateliteCotizadorAutorizaType[]>(
            "https://apisatelite.azurewebsites.net/vsatelitecotizadorautoriza"
          )
          .then((response) => {
            setListaAutorizaRows(response.data);
            setMostrar(true);
          })
          .catch((error) => {
            console.log("algo paso >> ", error);
          });
  
        console.log("data es >>", JSON.stringify(listaAutorizaRows, null, 4));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  
    async function eliminarRegistro(_id:number) {
      const headers = {
        "Content-Type": "application/json",
      };
      try {
        axios
          .delete(
            "https://apisatelite.azurewebsites.net/satelitecotizadorautoriza/eliminar/"+ String(_id)
          )
          .then((response) => {
            console.log("respuesta de eliminado >> ", response);
            getListaAutoriza();
            setOpenDialog(false);
          });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  
    async function getRegistroAutoriza(id: number) {
      try {
        await axios
          .get<VSateliteCotizadorAutorizaType>(
            "https://apisatelite.azurewebsites.net/vsatelitecotizadorautoriza/" +
              String(id)
          )
          .then((response) => {
            console.log("response de registro autoriza >> ", response.data);
            setRegistroAutoriza(response.data);
            setZona(response.data.idZona);
            setMarca(response.data.idMarca);
            getBodegas(response.data.idZona, response.data.idBodega);
            setLinea(response.data.idLinea);
            // getFamilias(response.data.idMarca, 0)
            response.data.idBodega === 0
              ? (cheked.toda_zona = true)
              : (cheked.toda_zona = false);
            setAgencia(response.data.idBodega);
            // getFamilias(response.data.idMarca, response.data.sfmId)
            response.data.perfil === "COMERCIAL"
              ? setPerfil(1)
              : setPerfil(2);
          })
          .catch((error) => {
            console.log("algo paso >> ", error);
          });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  
    function nuevo() {
      const nuevoRegistro: VSateliteCotizadorAutorizaType = {
        scaId: 0,
        idZona: 0,
        idMarca: 0,
        scaNombre: "",
        scaCorreo: "",
        zona: "",
        marca: "",
        perfil: "",
        idBodega: 0,
        niveles: "",
        familia: "",
        scaTelefono: "",
        idLinea: 0,
        linea: "",
      };
      setAgencia(0);
      setMarca(0);
      setZona(0);
      setLinea(0);
      setPerfil(0);
      setRegistroAutoriza(nuevoRegistro);
      setOpenDialog(true);
    }
  
    function setRegistro(
      scaId: number,
      idZona: number,
      idMarca: number,
    scaNombre: string,
      scaCorreo: string,
      scaPerfil: number,
      idBodega: number,
      scaTelefono: string,
      idLinea: number
    ) {
      if (scaId > 0) {
        const nuevoRegistro: SateliteCotizadorAutorizaType = {
          scaId: scaId,
          idZona: idZona,
          idMarca: idMarca,
          scaNombre: scaNombre,
          scaCorreo: scaCorreo,
          scaPerfil: scaPerfil,
          idBodega: idBodega,
          scaTelefono: scaTelefono,
          idLinea: idLinea,
        };
        console.log(
          "actualizar >> ",
          JSON.stringify(nuevoRegistro, null, 4)
        );
        postGuardarRegistro(nuevoRegistro);
      }else{
        const nuevoRegistro: SateliteCotizadorAutorizaType = {
            idZona: idZona,
            idMarca: idMarca,
            scaNombre: scaNombre,
            scaCorreo: scaCorreo,
            scaPerfil: scaPerfil,
            idBodega: idBodega,
            scaTelefono: scaTelefono,
            idLinea: idLinea,
        };
        console.log(
          "nuevo >> ",
          JSON.stringify(nuevoRegistro, null, 4)
        );
        postGuardarRegistro(nuevoRegistro);
      }        
    }
  
    async function postGuardarRegistro(registro: SateliteCotizadorAutorizaType) {
      const headers = {
        "Content-Type": "application/json",
      };
      try {
        axios
          .post("https://apisatelite.azurewebsites.net/satelitecotizadorautoriza", registro, {
            headers: headers,
          })
          .then((response) => {
            console.log("id guardado es: >>>>>>>> ", response);
            getListaAutoriza();
            setOpenDialog(false);
          });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  
    async function getBodegas(idZona: number, idBodega: number) {
      try {
        await axios
          .get<bodegaType[]>(
            "https://apisatelite.azurewebsites.net/dimbodega/zona/" +
              Number(idZona)
          )
          .then((response) => {
            setDataBodega(response.data);
            setAgencia(idBodega);
          })
          .catch((error) => {
            console.log("algo paso >> ", error);
          });
  
        console.log("data es >>", JSON.stringify(dataBodega, null, 4));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  
    async function getZonas() {
      try {
        await axios
          .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
          .then((response) => {
            setDataZona(response.data);
          })
          .catch((error) => {
            console.log("algo paso >> ", error);
          });
  
        console.log("data es >>", JSON.stringify(dataZona, null, 4));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  
    const columns: GridColDef[] = [
      {
        headerClassName: "theme-header",
        field: "scaNombre",
        headerName: "Nombre",
        editable: false,
        width: 170,
      },
      {
        headerClassName: "theme-header",
        field: "scaCorreo",
        headerName: "Correo",
        editable: false,
        width: 225,
      },
      {
        headerClassName: "theme-header",
        field: "scaTelefono",
        headerName: "Télefono",
        editable: false,
        width: 100,
      },
      {
        headerClassName: "theme-header",
        field: "zona",
        headerName: "Zona/Agencia",
        editable: false,
        width: 180,
      },
      {
        headerClassName: "theme-header",
        field: "marca",
        headerName: "Marca",
        editable: false,
        width: 125,
      },
      {
        headerClassName: "theme-header",
        field: "linea",
        headerName: "Línea",
        editable: false,
        width: 100,
      },
      {
        headerClassName: "theme-header",
        field: "perfil",
        headerName: "Perfil",
        editable: false,
        width: 100,
      },
  
      {
        headerClassName: "theme-header",
        field: "action",
        headerName: "Detalle",
        sortable: false,
        width: 100,
        renderCell: (params) => {
          //{params.row.Id}
          return (
            <Button
              //href={`/ventas/index/${params.row.idcrm}`}
              onClick={function (event) {
                getRegistroAutoriza(params.row.scaId);
                setOpenDialog(true);
              }}
            >
              Abrir
            </Button>
          );
        },
      },
    ];
  
    const [cheked, setCheked] = React.useState({
      toda_zona: false,
    });
  
    const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheked({
        ...cheked,
        [event.target.name]: event.target.checked,
      });
    };
  
    const dataHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setRegistroAutoriza({
        ...registroAutoriza,
        [e.target.name]: e.target.value,
      });
      console.log(registroAutoriza);
    };
  
    if (
      isAuthenticated 
    ) {
      return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Grid alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 2,
                }}
              >
                <Typography variant="h5" textAlign={"center"}>
                  Autorización de Cotizador
                </Typography>
              </Paper>
  
              {/* insertar validacion para mostrar */}
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    mt: 1,
                    mb: 1,
                    marginLeft: "42%",
                    width: "16%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  onClick={function (event) {
                    nuevo();
                  }}
                >
                  Nuevo
                </Button>
                {mostrar ? (
                  <>
                    <Box
                      sx={{
                        "& .theme-header": {
                          backgroundColor: "rgba(21,57,105,0.75)",
                          color: "rgba(255,255,255,1)",
                        },
                      }}
                    >
                      <DataGrid
                        getRowId={(row) => row.scaId}
                        sx={{ height: 670 }}
                        editMode="row"
                        rows={listaAutorizaRows}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        slots={{ toolbar: GridToolbarQuickFilter }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        localeText={{
                          footerTotalRows: false,
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                          toolbarQuickFilterPlaceholder: "Buscar",
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Paper>
  
              {/* modal */}
              <Modal
                open={openDialog}
                onClose={function (event) {
                  setOpenDialog(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleModal}>
                  <TextField
                    name="scaNombre"
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Nombre"
                    value={registroAutoriza.scaNombre}
                    onChange={dataHandleChange}
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "80%" }}
                  />
                  <TextField
                    name="scaCorreo"
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Correo"
                    value={registroAutoriza.scaCorreo}
                    onChange={dataHandleChange}
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "80%" }}
                  />
                    <TextField
                    name="scaTelefono"
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Télefono"
                    value={registroAutoriza.scaTelefono}
                    onChange={dataHandleChange}
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "80%", }}
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Zona"
                    value={zona}
                    onChange={handleChangeZona}
                    size="small"
                    margin="normal"
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "39%" }}
                  >
                    {dataZona.map((option) => (
                      <MenuItem key={option.zonId} value={option.zonId}>
                        {option.zonNombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    Toda la zona:
                  </Typography>
                  <Switch
                    checked={cheked.toda_zona}
                    onChange={handleCheked}
                    name="toda_zona"
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "3%" }}
                  />
                  {cheked.toda_zona ? (
                    <></>
                  ) : (
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Agencia"
                      value={agencia}
                      //helperText="Please select your currency"
                      onChange={handleChangeAgencia}
                      size="small"
                      margin="normal"
                      sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "80%" }}
                    >
                      {dataBodega.map((option) => (
                        <MenuItem key={option.bodId} value={option.bodId}>
                          {option.agencia}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Marca"
                    value={marca}
                    onChange={handleChangeMarca}
                    size="small"
                    margin="normal"
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "39%" }}
                  >
                    {dataMarca.map((option) => (
                      <MenuItem key={option.marId} value={option.marId}>
                        {option.marNombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Perfil"
                    value={perfil}
                    onChange={(e)=>{setPerfil(Number(e.target.value))}}
                    size="small"
                    margin="normal"
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "2%", width: "39%" }}
                  >
                    {listadoPerfil.map((option) => (
                      <MenuItem key={option.marId} value={option.marId}>
                        {option.marNombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Línea"
                    value={linea}
                    //helperText="Please select your currency"
                    onChange={handleChangeLinea}
                    size="small"
                    margin="normal"
                    sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "39%" }}
                  >
                    {listadoLinea.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.linea}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1,
                      mb: 1,
                      marginLeft: "8%",
                      width: "16%",
                      backgroundColor: "rgba(21,57,105,0.75)",
                    }}
                    onClick={function (event) {
                      setRegistro(
                        registroAutoriza.scaId,
                        zona,
                        marca,
                        registroAutoriza.scaNombre,
                        registroAutoriza.scaCorreo,
                        perfil,
                        agencia,
                        registroAutoriza.scaTelefono,
                        linea
                      );
                    }}
                  >
                    Guardar
                  </Button>
                  {registroAutoriza.scaId === 0 ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <Button
                        variant="contained"
                        sx={{
                          mt: 1,
                          mb: 1,
                          marginLeft: "1%",
                          width: "16%",
                          backgroundColor: "rgba(21,57,105,0.75)",
                        }}
                        onClick={function (event) {eliminarRegistro(registroAutoriza.scaId)}}
                      >
                        Eliminar
                      </Button>
                    </>
                  )}
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </Container>
      );
    } else {
      return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Paper>
            <Grid
              //container
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                sx={{ mt: 2, mb: 1.5 }}
                variant="h6"
                textAlign={"center"}
              >
                Acceso denegado
              </Typography>
            </Grid>
          </Paper>
        </Container>
      );
    }
  };
  