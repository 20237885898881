import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    Modal,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { MesType, SateliteModeloFamiliaType, SateliteParametroCotizadorType, VSateliteModeloFamiliaType } from "../../types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ParametrosCotizadorPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getListadoModelos();
        } else {
            setName("");
        }
    }, [account]);


    const [openDialog, setOpenDialog] = React.useState(false);
    const [mostrar, setMostrar] = React.useState(false);
    const [selectedModelo, setSelectedModelo] = React.useState(0);
    const [modelos, setModelos] = useState<SateliteParametroCotizadorType[]>([]);
    const [parametroCotizador, setParametroCotizador] = useState<SateliteParametroCotizadorType>({
        spcId: 0,
        spcImagen1: "",
        spcImagen2: "",
        spcNombre: "",
        spcText2: "",
        spcMarcaModeloVersion: "",
        spcCaracteristica1: "",
        spcDetalle1: "",
        spcCaracteristica2: "",
        spcDetalle2: "",
        spcCaracteristica3: "",
        spcDetalle3: "",
        spcCaracteristica4: "",
        spcDetalle4: "",
        spcCaracteristica5: "",
        spcDetalle5: "",
        spcCaracteristica6: "",
        spcDetalle6: "",
        spcCaracteristica7: "",
        spcDetalle7: "",
        spcCaracteristica8: "",
        spcDetalle8: "",
        spcDispositivo: "",
        spcDispositivoA1: 0,
        spcDispositivoA2: 0,
        spcDispositivoA3: 0,
        spcDispositivoA4: 0,
        spcDispositivoA5: 0,
        spcPlan1Tipo: "",
        spcPlan1Modelo: "",
        spcPlan1Descripcion: "",
        spcPlan1PvpNormal: 0,
        spcPlan1PvpMpc: 0,
        spcPlan1Cobertura: "",
        spcPlan2Tipo: "",
        spcPlan2Modelo: "",
        spcPlan2Descripcion: "",
        spcPlan2PvpNormal: 0,
        spcPlan2PvpMpc: 0,
        spcPlan2Cobertura: "",
        spcLink1: "",
        spcFamilia: "",
        spcDescuento1: "",
        spcDescuento1Valor: 0,
        spcDescuento2: "",
        spcDescuento2Valor: 0,
        spcDescuento3: "",
        spcDescuento3Valor: 0,
        spcDescuento4: "",
        spcDescuento4Valor: 0,
        spcDescuento5: "",
        spcDescuento5Valor: 0,
        spcPvp: 0,
        idCotItem: 0,
        spcIdDispositivo: 0,
        spcModeloEspecifico: "",
        spcGarantia: 0,
        spcExtra1Descripcion: "",
        spcExtra1Valor: 0,
        spcExtra2Descripcion: "",
        spcExtra2Valor: 0,
        spcExtra3Descripcion: "",
        spcExtra3Valor: 0,
        spcExtra4Descripcion: "",
        spcExtra4Valor: 0,
        spcSeguroPorcen: 0,
        spcSeguroSuperbanco: 0,
        spcSeguroCampesino: 0,
        spcSeguroDerechosEmision: 0,
        spcSeguroIva: 0,
    })

    const styleModal = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1200,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const HandleChangeParametroCotizador = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParametroCotizador({
            ...parametroCotizador,
            [e.target.name]: e.target.value,
            //   mkcId: idModeloKmat,
        });

    };
    async function getListadoModelos() {
        try {
            await axios
                .get<SateliteParametroCotizadorType[]>("https://apisatelite.azurewebsites.net/sateliteparametrocotizador/parametro")
                .then((response) => {
                    setModelos(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getParametros(_id: number) {
        try {
            await axios
                .get<SateliteParametroCotizadorType>("https://apisatelite.azurewebsites.net/sateliteparametrocotizador/idcotitem/" + String(_id))
                .then((response) => {
                    setParametroCotizador(response.data);
                    setMostrar(true);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function postGuardarRegistro(registro: SateliteParametroCotizadorType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post(
                    "https://apisatelite.azurewebsites.net/sateliteparametrocotizador",
                    registro,
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    console.log("id guardado es: ", response);
                    setOpenDialog(true);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }


    const listadoDispositivo: MesType[] = [{ id: 1, mes: "ChevyStar" }, { id: 2, mes: "OnStar" },];
    const listadoGarantia: MesType[] = [{ id: 7, mes: "7 Años" }, { id: 8, mes: "8 Años (eléctrico)" },];

    const listadoModelosVh: VSateliteModeloFamiliaType[] = [];
    const [dataModelosVh, setDataModelosVh] =
        useState<VSateliteModeloFamiliaType[]>(listadoModelosVh);
    const [modeloVh, setModeloVh] = useState<SateliteModeloFamiliaType>({
        smfId: 0,
        idMarca: 0,
        smfFamiliaCrm: "",
        smfFamiliaDms: "",
        smfFamiliaCrm2: "",
    });



    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,1)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Parametros de Cotizador
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,1)",
                                mt: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "10%",
                                    marginLeft: "20%",
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Modelo:
                            </Typography>



                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                margin="normal"
                                label="Modelo"
                                value={selectedModelo}
                                style={{ backgroundColor: "rgb(255,255,255)" }}
                                onChange={(e) => {
                                    setSelectedModelo(Number(e.target.value));
                                    setParametroCotizador({
                                        ...parametroCotizador,
                                        spcId: 0,
                                        spcImagen1: "",
                                        spcImagen2: "",
                                        spcNombre: "",
                                        spcText2: "",
                                        spcMarcaModeloVersion: "",
                                        spcCaracteristica1: "",
                                        spcDetalle1: "",
                                        spcCaracteristica2: "",
                                        spcDetalle2: "",
                                        spcCaracteristica3: "",
                                        spcDetalle3: "",
                                        spcCaracteristica4: "",
                                        spcDetalle4: "",
                                        spcCaracteristica5: "",
                                        spcDetalle5: "",
                                        spcCaracteristica6: "",
                                        spcDetalle6: "",
                                        spcCaracteristica7: "",
                                        spcDetalle7: "",
                                        spcCaracteristica8: "",
                                        spcDetalle8: "",
                                        spcDispositivo: "",
                                        spcDispositivoA1: 0,
                                        spcDispositivoA2: 0,
                                        spcDispositivoA3: 0,
                                        spcDispositivoA4: 0,
                                        spcDispositivoA5: 0,
                                        spcPlan1Tipo: "",
                                        spcPlan1Modelo: "",
                                        spcPlan1Descripcion: "",
                                        spcPlan1PvpNormal: 0,
                                        spcPlan1PvpMpc: 0,
                                        spcPlan1Cobertura: "",
                                        spcPlan2Tipo: "",
                                        spcPlan2Modelo: "",
                                        spcPlan2Descripcion: "",
                                        spcPlan2PvpNormal: 0,
                                        spcPlan2PvpMpc: 0,
                                        spcPlan2Cobertura: "",
                                        spcLink1: "",
                                        spcFamilia: "",
                                        spcDescuento1: "",
                                        spcDescuento1Valor: 0,
                                        spcDescuento2: "",
                                        spcDescuento2Valor: 0,
                                        spcDescuento3: "",
                                        spcDescuento3Valor: 0,
                                        spcDescuento4: "",
                                        spcDescuento4Valor: 0,
                                        spcDescuento5: "",
                                        spcDescuento5Valor: 0,
                                        spcPvp: 0,
                                        idCotItem: 0,
                                        spcIdDispositivo: 0,
                                        spcModeloEspecifico: ""
                                    });
                                    setMostrar(false);
                                }}
                                sx={{ mt: 0.5, mb: 0.5, width: "50%" }}
                                SelectProps={{
                                    MenuProps: { disableScrollLock: true, style: { maxHeight: 300 } },
                                }}
                            >
                                <MenuItem value="">
                                    <em>Seleccione un modelo</em>
                                </MenuItem>
                                {modelos.map((item) => (
                                    <MenuItem key={item.idCotItem} value={item.idCotItem}>
                                        {item.spcModeloEspecifico}
                                    </MenuItem>
                                ))}
                            </TextField>




                            <Button
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 1,
                                    width: "12%",
                                    marginLeft: "44%",
                                }}
                                onClick={(e) => getParametros(selectedModelo)}
                            >
                                Consultar
                            </Button>
                        </Paper>
                        <Dialog
                            open={openDialog}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Datos guardados correctamente
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    //href="/politicacomercial/lista"
                                    onClick={function (event) {
                                        setOpenDialog(false);
                                        setMostrar(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {mostrar ? (
                            <Paper
                                sx={{
                                    padding: "1.2em",
                                    borderRadius: "0.5em",
                                    backgroundColor: "rgb(255,255,255,0.9)",
                                    mt: 1,
                                    mb: 4,
                                }}
                            >
                                <TextField
                                    label="Título"
                                    size="small"
                                    margin="normal"
                                    name="spcNombre"
                                    value={parametroCotizador.spcNombre}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "10%", width: "39%" }}
                                />

                                <TextField
                                    label="Subtítulo"
                                    size="small"
                                    margin="normal"
                                    name="spcText2"
                                    value={parametroCotizador.spcText2}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />

                                <TextField
                                    label="Marca/Modelo/Version/Año"
                                    size="small"
                                    margin="normal"
                                    name="spcMarcaModeloVersion"
                                    value={parametroCotizador.spcMarcaModeloVersion}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "10%", width: "80%" }}
                                />

                                <Accordion sx={{ mt: 2, mb: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography textAlign={"center"} sx={{ ml: "10%" }}><strong>Elementos multimedia</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "20%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dispositivo:
                                        </Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            onChange={(e) => setParametroCotizador({
                                                ...parametroCotizador,
                                                spcIdDispositivo: Number(e.target.value),
                                                //   mkcId: idModeloKmat,
                                            })}
                                            value={parametroCotizador.spcIdDispositivo}
                                            size="small"
                                            margin="normal"
                                            SelectProps={{
                                                MenuProps: {
                                                    disableScrollLock: true,
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                },
                                            }}
                                            sx={{ mt: 1, mb: 1, width: "45%", mr: "15%" }}
                                        >
                                            {listadoDispositivo.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.mes}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "20%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Garantia:
                                        </Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            onChange={(e) => setParametroCotizador({
                                                ...parametroCotizador,
                                                spcGarantia: Number(e.target.value),
                                            })}
                                            value={parametroCotizador.spcGarantia}
                                            size="small"
                                            margin="normal"
                                            SelectProps={{
                                                MenuProps: {
                                                    disableScrollLock: true,
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                },
                                            }}
                                            sx={{ mt: 1, mb: 1, width: "45%", mr: "15%" }}
                                        >
                                            {listadoGarantia.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.mes}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "20%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Enlace ficha:
                                        </Typography>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            name="spcLink1"
                                            value={parametroCotizador.spcLink1}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "45%", mr: "15%" }}
                                        />
                                    </AccordionDetails>
                                </Accordion>


                                <Accordion sx={{ mt: 2, mb: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography textAlign={"center"} sx={{ ml: "10%" }}><strong>Datos generales</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 1:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica1"
                                            value={parametroCotizador.spcCaracteristica1}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle1"
                                            value={parametroCotizador.spcDetalle1}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 2:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica2"
                                            value={parametroCotizador.spcCaracteristica2}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle2"
                                            value={parametroCotizador.spcDetalle2}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 3:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica3"
                                            value={parametroCotizador.spcCaracteristica3}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle3"
                                            value={parametroCotizador.spcDetalle3}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 4:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica4"
                                            value={parametroCotizador.spcCaracteristica4}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle4"
                                            value={parametroCotizador.spcDetalle4}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 5:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica5"
                                            value={parametroCotizador.spcCaracteristica5}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle5"
                                            value={parametroCotizador.spcDetalle5}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 6:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica6"
                                            value={parametroCotizador.spcCaracteristica6}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle6"
                                            value={parametroCotizador.spcDetalle6}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 7:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica7"
                                            value={parametroCotizador.spcCaracteristica7}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle7"
                                            value={parametroCotizador.spcDetalle7}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "7%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dato 8:
                                        </Typography>
                                        <TextField
                                            label="Caracteristica"
                                            size="small"
                                            margin="normal"
                                            name="spcCaracteristica8"
                                            value={parametroCotizador.spcCaracteristica8}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "32%" }}
                                        />
                                        <TextField
                                            label="Detalle"
                                            size="small"
                                            margin="normal"
                                            name="spcDetalle8"
                                            value={parametroCotizador.spcDetalle8}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                        />



                                    </AccordionDetails>
                                </Accordion>


                                <Accordion sx={{ mt: 2, mb: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography textAlign={"center"} sx={{ ml: "10%" }}><strong>Mantenimientos prepagados</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>


                                        <TextField
                                            label="Tipo de plan"
                                            size="small"
                                            margin="normal"
                                            name="spcPlan1Tipo"
                                            value={parametroCotizador.spcPlan1Tipo}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "10%", width: "15%" }}
                                        />
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcPlan1Descripcion"
                                            value={parametroCotizador.spcPlan1Descripcion}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "20%" }}
                                        />
                                        <TextField
                                            label="Precio Normal"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcPlan1PvpNormal"
                                            value={parametroCotizador.spcPlan1PvpNormal}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Precio MPC"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcPlan1PvpMpc"
                                            value={parametroCotizador.spcPlan1PvpMpc}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Cobertura"
                                            size="small"
                                            margin="normal"
                                            name="spcPlan1Cobertura"
                                            value={parametroCotizador.spcPlan1Cobertura}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "20%", mr: "10" }}
                                        />
                                        <TextField
                                            label="Tipo de plan"
                                            size="small"
                                            margin="normal"
                                            name="spcPlan2Tipo"
                                            value={parametroCotizador.spcPlan2Tipo}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "10%", width: "15%" }}
                                        />
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcPlan2Descripcion"
                                            value={parametroCotizador.spcPlan2Descripcion}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "20%" }}
                                        />
                                        <TextField
                                            label="Precio Normal"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcPlan2PvpNormal"
                                            value={parametroCotizador.spcPlan2PvpNormal}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Precio MPC"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcPlan2PvpMpc"
                                            value={parametroCotizador.spcPlan2PvpMpc}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Cobertura"
                                            size="small"
                                            margin="normal"
                                            name="spcPlan2Cobertura"
                                            value={parametroCotizador.spcPlan2Cobertura}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "1%", width: "20%", mr: "10" }}
                                        />

                                    </AccordionDetails>
                                </Accordion>


                                <Accordion sx={{ mt: 2, mb: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography textAlign={"center"} sx={{ ml: "10%" }}><strong>Descuentos</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>



                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Descuento 1:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento1"
                                            value={parametroCotizador.spcDescuento1}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento1Valor"
                                            value={parametroCotizador.spcDescuento1Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Descuento 2:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento2"
                                            value={parametroCotizador.spcDescuento2}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento2Valor"
                                            value={parametroCotizador.spcDescuento2Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Descuento 3:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento1"
                                            value={parametroCotizador.spcDescuento3}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento3Valor"
                                            value={parametroCotizador.spcDescuento3Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Descuento 4:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento4"
                                            value={parametroCotizador.spcDescuento4}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento4Valor"
                                            value={parametroCotizador.spcDescuento4Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Descuento 5:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento5"
                                            value={parametroCotizador.spcDescuento5}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcDescuento5Valor"
                                            value={parametroCotizador.spcDescuento5Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />


                                    </AccordionDetails>
                                </Accordion>


                                <Accordion sx={{ mt: 2, mb: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography textAlign={"center"} sx={{ ml: "10%" }}><strong>Extras / Accesorios</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Extra 1:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra1Descripcion"
                                            value={parametroCotizador.spcExtra1Descripcion}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra1Valor"
                                            value={parametroCotizador.spcExtra1Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Extra 2:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra2Descripcion"
                                            value={parametroCotizador.spcExtra2Descripcion}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra2Valor"
                                            value={parametroCotizador.spcExtra2Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Extra 3:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra3Descripcion"
                                            value={parametroCotizador.spcExtra3Descripcion}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra3Valor"
                                            value={parametroCotizador.spcExtra3Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "8%",
                                                ml: "25%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Extra 4:
                                        </Typography>
                                        <TextField
                                            label="Descripción"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra4Descripcion"
                                            value={parametroCotizador.spcExtra4Descripcion}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "30%" }}
                                        />
                                        <TextField
                                            label="Valor"
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            name="spcExtra4Valor"
                                            value={parametroCotizador.spcExtra4Valor}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, ml: "2%", width: "10%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion sx={{ mt: 2, mb: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography textAlign={"center"} sx={{ ml: "10%" }}><strong>Valores de Seguro</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Tasa seguro:
                                        </Typography>
                                        <TextField
                                            label="%"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcSeguroPorcen"
                                            value={parametroCotizador.spcSeguroPorcen}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "15%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Tasa SuperBancos:
                                        </Typography>
                                        <TextField
                                            label="%"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcSeguroSuperbanco"
                                            value={parametroCotizador.spcSeguroSuperbanco}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "15%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Tasa seguro campesino:
                                        </Typography>
                                        <TextField
                                            label="%"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcSeguroCampesino"
                                            value={parametroCotizador.spcSeguroCampesino}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "15%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Derechos de emisión:
                                        </Typography>
                                        <TextField
                                            label="$"
                                            size="small"
                                            margin="normal"
                                            type="number"
                                            name="spcSeguroDerechosEmision"
                                            value={parametroCotizador.spcSeguroDerechosEmision}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "15%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                width: "15%",
                                                ml: "10%"
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Seguro IVA:
                                        </Typography>
                                        <TextField
                                            label="%"
                                            size="small"
                                            margin="normal"
                                            name="spcSeguroIva"
                                            type="number"
                                            value={parametroCotizador.spcSeguroIva}
                                            onChange={HandleChangeParametroCotizador}
                                            sx={{ mt: 1, mb: 1, width: "15%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Button
                                    disabled={parametroCotizador.idCotItem > 0 ? false : true}
                                    onClick={(e) => postGuardarRegistro(parametroCotizador)}
                                    variant="contained" sx={{
                                        mt: 3,
                                        mb: 1,
                                        width: "12%",
                                        marginLeft: "44%",
                                    }}>
                                    Guardar
                                </Button>
                            </Paper>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
