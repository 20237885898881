import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Grid,
    Paper,
    Box,
    Typography,
    TextField,
    Divider,
    Switch,
    Tooltip,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MenuItem,
} from "@mui/material";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useParams } from "react-router-dom";
import {
    NotificacionWPPType,
    SateliteReservarVhType,
    TablaRentaType,
    TypeComplementos,
    TypeNuevoPVP,
    VAccesoriosPorVin,
    VTablaRentaAutorizaType,
    bodegaType,
} from "../../types";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import internal from "stream";


const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const TablaRentaNewPage: React.FC<{}> = () => {


    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [parametroPag, setParametroPag] = useState("");
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState("");
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();
    const [open, setOpen] = React.useState(false);
    const [openCorreo, setOpenCorreo] = React.useState(false);
    const [openDialogAccPorVin, setOpenDialogAccPorVin] = React.useState(false);
    const [openReserva, setOpenReserva] = React.useState(false);
    const [estaReservado, setEstaReservado] = React.useState(false);
    const params = useParams();
    const [openKit, setOpenKit] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogEstaReservado, setOpenDialogEstaReservado] = React.useState(false);
    const [openDialogAnularReserva, setOpenDialogAnularReserva] = React.useState(false);
    const [esModeloSinVin, setEsModeloSinVin] = React.useState(false);
    const [openDialogReservaOk, setOpenDialogReservaOk] = React.useState(false);
    const [openDialogError, setOpenDialogError] = React.useState(false);
    const [costoOriginal, setCostoOriginal] = React.useState(0);
    const [valorComplementoAcumulado, setValorComplementoAcumulado] = React.useState(0);

    const dataListaAutoriza: VTablaRentaAutorizaType[] = [];
    const [listaAutorizaRows, setListaAutorizaRows] =
        useState<VTablaRentaAutorizaType[]>(dataListaAutoriza);


    const handleLogin = (loginType: string) => {
        setAnchorEl(null);
        if (loginType === "popup") {
            instance.loginPopup(loginRequest);
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest);
        }
    };

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            String(params.idcrm) != "0"
                ? getvTablaRentaCrm(String(params.idcrm))
                : console.log("");
            setParametroPag(String(params.idcrm));
            getBodegas();
            getReservasVh();
            if (Number(params.nuevo) > 0) {
                getTablaRenta(Number(Number(params.nuevo)));
            }
        } else {
            setName("");
        }
    }, []);
    const [openDialogPVP, setOpenDialogPVP] = React.useState(false);
    const handleClickOpenPVP = () => {
        setOpenDialogPVP(true);
    };


    const columnsModelo: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "vin",
            headerName: "VIN",
            editable: false,
            width: 150,
        },
        {
            headerClassName: "theme-header",
            field: "modelo",
            headerName: "Modelo",
            editable: false,
            width: 220,
        },
        {
            headerClassName: "theme-header",
            field: "anio",
            headerName: "Año",
            editable: false,
            width: 50,
        },
        {
            headerClassName: "theme-header",
            field: "color",
            headerName: "Color",
            editable: false,
            width: 70,
        },
        {
            headerClassName: "theme-header",
            field: "dias",
            headerName: "Días",
            editable: false,
            width: 40,
        },
        {
            headerClassName: "theme-header",
            field: "fechaCompra",
            headerName: "F.Compra",
            editable: false,
            width: 80,
            align: "right",
            renderCell: (params) => {
                return <div>{String(params.row.fechaCompra).length > 10 ? params.row.fechaCompra.substring(0, 10) : ""}</div>;
            },
        },
        {
            headerClassName: "theme-header",
            field: "costo",
            headerName: "Costo",
            editable: false,
            width: 80,
            align: "right",
            renderCell: (params) => {
                return (
                    <div>{formatter.format(params.row.costo).replace("$", "$  ")}</div>
                );
            },
        },
        {
            headerClassName: "theme-header",
            field: "pvp",
            headerName: "PVP",
            editable: false,
            width: 80,
            align: "right",
            renderCell: (params) => {
                return (
                    <div>
                        <strong>
                            {formatter.format(params.row.pvp).replace("$", "$  ")}
                        </strong>
                    </div>
                );
            },
        },
        {
            headerClassName: "theme-header",
            field: "rebate",
            headerName: "Rebate",
            editable: false,
            width: 80,
            align: "right",
            renderCell: (params) => {
                return (
                    <div>{formatter.format(params.row.rebate).replace("$", "$  ")}</div>
                );
            },
        },

        {
            headerClassName: "theme-header",
            field: "bodega",
            headerName: "Bodega",
            editable: false,
            width: 100,
        },

        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Seleccionar",
            sortable: false,
            width: 80,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            String(params.row.correoReserva).length > 0
                                ? "Vh reservado por " + params.row.correoReserva
                                : ""
                        }
                    >
                        <span>
                            <Button
                                sx={{ width: "80%" }}
                               // disabled={Number(params.row.validador) === 1 && Number(params.row.disponible) === 1 ? false : true} //validad dias inv // aquii
                                onClick={function (event) {
                                    setCostoOriginal(params.row.costo);
                                    setTablaValoresData({
                                        ...tablaValoresData,
                                        descuento_circular: 0,
                                        descuento_circular_porcen: 0,
                                        descuento_adicional: 0,
                                        descuento_adicional_porcen: 0,
                                        descuento_asesor: 0,
                                        descuento_asesor_porcen: 0,
                                        total_descuentos: 0,
                                        total_descuentos_porcen: 0,
                                        rebate_gm: 0,
                                        rebate_gm_porcen: 0,
                                        utilidad_bruta: 0,
                                        utilidad_bruta_porcen: 0,
                                        regalos: 0,
                                    });
                                    selectModelo(
                                        params.row.id,
                                        params.row.costo, //+ valorComplementoAcumulado,
                                        params.row.modelo,
                                        params.row.anio,
                                        params.row.pvp,
                                        params.row.dias,
                                        params.row.marca,
                                        params.row.rebate,
                                        params.row.orden,
                                        params.row.fechaCompra,
                                        params.row.idCotItem,
                                        params.row.smfId,
                                        params.row.color,
                                        params.row.vin,
                                        params.row.porcentajeIva
                                    );
                                    getListaAccesorioPorVin(params.row.vin);
                                }}
                            >
                                ✔
                            </Button>
                        </span>
                    </Tooltip>
                );
            },
        },
    ];

    const columnsModeloSinVin: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "modelo",
            headerName: "Modelo",
            editable: false,
            width: 425,
        },
        {
            headerClassName: "theme-header",
            field: "anio",
            headerName: "Año",
            editable: false,
            width: 125,
        },
        {
            headerClassName: "theme-header",
            field: "costo",
            headerName: "Costo",
            editable: false,
            width: 125,
            align: "right",
            renderCell: (params) => {
                return (
                    <div>{formatter.format(params.row.costo).replace("$", "$  ")}</div>
                );
            },
        },
        {
            headerClassName: "theme-header",
            field: "pvp",
            headerName: "PVP",
            editable: false,
            width: 150,
            align: "right",
            renderCell: (params) => {
                return (
                    <div>
                        <strong>
                            {formatter.format(params.row.pvp).replace("$", "$  ")}
                        </strong>
                    </div>
                );
            },
        },
        {
            headerClassName: "theme-header",
            field: "rebate",
            headerName: "Rebate",
            editable: false,
            width: 100,
            align: "right",
            renderCell: (params) => {
                return (
                    <div>{formatter.format(params.row.rebate).replace("$", "$  ")}</div>
                );
            },
        },

        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Seleccionar",
            sortable: false,
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Button
                        sx={{ width: "80%" }}
                        // disabled={Number(params.row.validador) === 1 && Number(params.row.disponible) === 1 ? false : true}
                        onClick={function (event) {
                            setCostoOriginal(params.row.costo);
                            setTablaValoresData({
                                ...tablaValoresData,
                                descuento_circular: 0,
                                descuento_circular_porcen: 0,
                                descuento_adicional: 0,
                                descuento_adicional_porcen: 0,
                                descuento_asesor: 0,
                                descuento_asesor_porcen: 0,
                                total_descuentos: 0,
                                total_descuentos_porcen: 0,
                                rebate_gm: 0,
                                rebate_gm_porcen: 0,
                                utilidad_bruta: 0,
                                utilidad_bruta_porcen: 0,
                                regalos: 0,
                            });
                            selectModelo(
                                params.row.id,
                                params.row.costo, //+ valorComplementoAcumulado,
                                params.row.modelo,
                                params.row.anio,
                                params.row.pvp,
                                params.row.dias,
                                params.row.marca,
                                params.row.rebate,
                                params.row.orden,
                                params.row.fechaCompra,
                                params.row.idCotItem,
                                params.row.smfId,
                                params.row.color,
                                params.row.vin,
                                params.row.porcentajeIva
                            );
                            setOpen(false);
                        }}
                    >
                        ✔
                    </Button>

                );
            },
        },
    ];

    const columnsAccPorVin: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "fechaAccesorizacion",
            headerName: "F.Accesorización",
            editable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{String(params.row.fechaAccesorizacion).substring(0, 10)}</div>
                );
            },
        },
        {
            headerClassName: "theme-header",
            field: "codigo",
            headerName: "Código",
            editable: false,
            width: 150,
        },
        {
            headerClassName: "theme-header",
            field: "descripcionAccesorio",
            headerName: "Accesorio",
            editable: false,
            width: 240,
        },
        {
            headerClassName: "theme-header",
            field: "cantidad",
            headerName: "Cantidad",
            editable: false,
            width: 75,
            align: "center",
        },
        {
            headerClassName: "theme-header",
            field: "pvp",
            headerName: "PVP",
            editable: false,
            width: 75,
            align: "right",
            renderCell: (params) => {
                return (
                    <div>
                        <strong>
                            {formatter
                                .format(Number(params.row.pvp) * Number(params.row.cantidad))
                                .replace("$", "$  ")}
                        </strong>
                    </div>
                );
            },
        },
    ];


    const [nuevoPVP, setNuevoPVP] = React.useState<TypeNuevoPVP>({
        nuevoPVP: -1,
        nuevoRebate: -1,
    });

    const dataNuevoPVP = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNuevoPVP({
            ...nuevoPVP,
            [e.target.name]: e.target.value,
        });
    };

    const handleCloseDialogPVP = () => {
        selectModelo(
            data2.id,
            data2.costo,
            data2.modelo,
            data2.anioModelo,
            nuevoPVP.nuevoPVP > -1 ? nuevoPVP.nuevoPVP : data2.pvp,
            data2.dias,
            data2.marca,
            nuevoPVP.nuevoRebate > -1 ? nuevoPVP.nuevoRebate : data2.rebate,
            data2.orden,
            data2.fechaCompra,
            data2.idCotItem,
            data2.smfId,
            data2.color,
            data2.vin,
            data2.porcentajeIva
        );
        setOpenDialogPVP(false);
    };




    const handleCloseDialog = () => {
        setOpenDialog(false);
    };



    const [cheked, setCheked] = React.useState({
        accesorios_incluye: true,
        dispositivos_incluye: true,
        mantenimientos_incluye: true,
        complement_incluye: true,
    });

    const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheked({
            ...cheked,
            [event.target.name]: event.target.checked,
        });
    };

    type vTablaRentaCrmResponse = {
        id: number;
        agencia: string;
        idAsesor: number;
        apellidoAsesor: string;
        nombreAsesor: string;
        cedulaCliente: string;
        apellidoCliente: string;
        nombreCliente: string;
        modelo: string;
        modeloAnio: number;
        formaPago: string;
        entrada: number;
        financiera: string;
        estado_financiera: string;
        valor_financiera: number;
        idBodega: number;
        idZona: number;
        idLinea: number;
    };

    type vTablaRentaInvResponse = {
        id: number;
        costo: number;
        modelo: string;
        anioModelo: number;
        pvp: number;
        dias: number;
        marca: string;
        rebate: number;
        orden: number;
        fechaCompra: string;
        idCotItem: number;
        smfId: number;
        color: string;
        vin: string;
        porcentajeIva: number;
    };

    type nivelesType = {
        smrId: number;
        smfId: number;
        idCotItem: number;
        smrModelo: string;
        smrAno: number;
        rojo: number;
        naranja: number;
        verde: number;
    };

    type parametrosCrmType = {
        idCotizacion: string;
    };

    type listaAccDispDataType = {
        total_acc: number;
        total_disp: number;
        total_mant: number;
    };

    type datosComplementoType = {
        correo_destino: string;
        correo_loggeo: string;
        accesorios: string;
        acc_incluye: number;
        dispositivos: string;
        disp_incluye: number;
        observaciones: string;
        color: string;
        escenario_flota: string;
        escenario_flota_valor: number;
        mantenimiento_prepa: number;
        prepagados: string;
    };

    const [listaAccDispData, setListaAccDispData] =
        React.useState<listaAccDispDataType>({
            total_acc: 0,
            total_disp: 0,
            total_mant: 0,
        });

    const [datosComplemento, setDatosComplemento] =
        React.useState<datosComplementoType>({
            correo_destino: "",
            correo_loggeo: "",
            accesorios: "",
            acc_incluye: 0,
            dispositivos: "",
            disp_incluye: 0,
            observaciones: "",
            color: "",
            escenario_flota: "",
            escenario_flota_valor: 0,
            mantenimiento_prepa: 0,
            prepagados: "",
        });

    const [parametrosCrmData, setParametrosCrmData] =
        React.useState<parametrosCrmType>({
            idCotizacion: "",
        });

    const dataListaAccDisp = (e: React.ChangeEvent<HTMLInputElement>) => {
        setListaAccDispData({
            ...listaAccDispData,
            [e.target.name]: e.target.value,
        });
        console.log(listaAccDispData);
    };

    const dataDatosComplemento = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDatosComplemento({
            ...datosComplemento,
            [e.target.name]: e.target.value,
        });
        console.log(datosComplemento);
        if (e.target.name === "color" && esModeloSinVin) {
            setData2({
                ...data2,
                [e.target.name]: e.target.value
            })
        }
    };

    const dataCrm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParametrosCrmData({
            ...parametrosCrmData,
            [e.target.name]: e.target.value,
        });
        console.log(parametrosCrmData);
        setAlert(false);
    };

    const [data2, setData2] = React.useState<vTablaRentaInvResponse>({
        id: 0,
        dias: 0,
        costo: 0,
        modelo: "",
        anioModelo: 0,
        pvp: 0,
        marca: "",
        rebate: 0,
        orden: 0,
        fechaCompra: "",
        idCotItem: 0,
        smfId: 0,
        color: "",
        vin: "",
        porcentajeIva: 0,
    });

    const [dataNivel, setDataNivel] = React.useState<nivelesType>({
        smrId: 0,
        smfId: 0,
        idCotItem: 0,
        smrModelo: "",
        smrAno: 0,
        rojo: 0,
        naranja: 0,
        verde: 0,
    });

    const [dataConCopiaNiv2, setDataConCopiaNiv2] =
        useState<VTablaRentaAutorizaType>({
            traId: 0,
            idZona: 0,
            idMarca: 0,
            traNombre: "",
            traCorreo: "",
            zona: "",
            marca: "",
            nivel: "",
            idBodega: 0,
            niveles: "",
            familia: "",
            traTelefono: "",
            idLinea: 0,
            linea: "",
        });

    const [dataConCopiaNiv3, setDataConCopiaNiv3] =
        useState<VTablaRentaAutorizaType>({
            traId: 0,
            idZona: 0,
            idMarca: 0,
            traNombre: "",
            traCorreo: "",
            zona: "",
            marca: "",
            nivel: "",
            idBodega: 0,
            niveles: "",
            familia: "",
            traTelefono: "",
            idLinea: 0,
            linea: "",
        });

    function AcumularComplemento(valor: number) {
        setValorComplementoAcumulado(valor + valorComplementoAcumulado);
        console.log("el valor es >>>", valor);
        console.log("el nuevo valor es >>>", valorComplementoAcumulado);
        console.log("el switch es >>>", cheked.complement_incluye);
        selectModelo(
            data.id,
            data2.costo + valor,
            data2.modelo,
            data2.anioModelo,
            data2.pvp,
            data2.dias,
            data2.marca,
            data2.rebate,
            data2.orden,
            data2.fechaCompra,
            data2.idCotItem,
            data2.smfId,
            data2.color,
            data2.vin,
            data2.porcentajeIva
        );
    }

    function BorrarComplemento() {
        selectModelo(
            1,
            costoOriginal,
            data2.modelo,
            data2.anioModelo,
            data2.pvp,
            data2.dias,
            data2.marca,
            data2.rebate,
            data2.orden,
            data2.fechaCompra,
            data2.idCotItem,
            data2.smfId,
            data2.color,
            data2.vin,
            data2.porcentajeIva
        );
        console.log("el nuevo valor es >>>", valorComplementoAcumulado);
    }

    const [data, setData] = React.useState<vTablaRentaCrmResponse>({
        id: 0,
        agencia: "",
        idAsesor: 0,
        apellidoAsesor: "",
        nombreAsesor: "",
        cedulaCliente: "",
        apellidoCliente: "",
        nombreCliente: "",
        modelo: "",
        modeloAnio: 0,
        formaPago: "",
        entrada: 0,
        financiera: "",
        estado_financiera: "",
        valor_financiera: 0,
        idBodega: 0,
        idZona: 0,
        idLinea: 0,
    })
    const [dataTabla, setDataTabla] = React.useState<TablaRentaType>({
        tarId: 0,
        tarFecha: "",
        tarNumeroCotizacion: "",
        tarAgencia: "",
        tarAsesor: "",
        tarCedulaCliente: "",
        tarNombreCliente: "",
        tarModelo: "",
        tarModeloAnio: 0,
        tarColor: "",
        tarDiasInv: 0,
        tarEscenarioFlota: "",
        tarEscenarioFlotaPorcentaje: 0,
        tarFormaPago: "",
        tarAnticipos: 0,
        tarPvpMarca: 0,
        tarDealerNet: 0,
        tarDealerNetValor: 0,
        tarMargenBruto: 0,
        tarMargenBrutoValor: 0,
        tarDescCirular: 0,
        tarDescCirularValor: 0,
        tarDescConcecionario: 0,
        tarDescConcecionarioValor: 0,
        tarDescTotal: 0,
        tarDescTotalValor: 0,
        tarRebate: 0,
        tarRebateValor: 0,
        tarUtilidadBruta: 0,
        tarUtilidadBrutaValor: 0,
        tarTotalFacturar: 0,
        tarNcConcesionario: 0,
        tarNcMarca: 0,
        tarValorObsequios: 0,
        tarAccDescripcion: "",
        tarAccIncluyeFac: 0,
        tarAccValor: 0,
        tarDispDescripcion: "",
        tarDispIncluyeFac: 0,
        tarDispValor: 0,
        tarObservaciones: "",
        tarDestinatario: "",
        tarCorreo: "",
        tarCorreoRemitente: "",
        tarEnviado: 0,
        tarNotificado: 0,
        tarEstado: 0,
        tarMantenimientosPrepa: 0,
        tarCorreoSupervisa: "",
        tarObservacionNotificar: "",
        idBodega: 0,
        idMarca: 0,
        margenNivel: 0,
        tarMantenimientosPrepaDescripcion: "",
        tarMantenimientosPrepaIncluye: 0,
        fechaCompra: "",
        valor_transporte: 0,
        conCopiaNiv2: "",
        conCopiaNiv3: "",
        tarVin: "",
        tarVinAcc: "",
        tarVinAccValor: 0,
        idBodegaVeh: 0,
        idLinea: 0,
        tarEstadoTabla: 0,
        idCotItem: 0,
        idZona: 0,
    });

    async function getConCopiaNiv3(bodega: number, marca: number, linea: number) {
        try {
            await axios
                .get<VTablaRentaAutorizaType>(
                    "https://apisatelite.azurewebsites.net/tablarentaautoriza/buscarccagencia/" +
                    String(bodega) +
                    "/" +
                    String(marca) +
                    "/3/" +
                    String(linea)
                )
                .then((response) => {
                    setDataConCopiaNiv3(response.data);
                })
                .catch((error) => {
                    console.log("algo paso con copia 2>> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getConCopiaNiv2(
        zona: number,
        bodega: number,
        marca: number,
        linea: number
    ) {
        try {
            await axios
                .get<VTablaRentaAutorizaType>(
                    "https://apisatelite.azurewebsites.net/tablarentaautoriza/buscarcczona/" +
                    String(zona) +
                    "/" +
                    String(bodega) +
                    "/" +
                    String(marca) +
                    "/2/" +
                    String(linea)
                )
                .then((response) => {
                    setDataConCopiaNiv2(response.data);
                    getConCopiaNiv3(bodega, marca, linea);
                })
                .catch((error) => {
                    console.log("algo paso con copia 2>> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getvListaAprueba(
        zona: number,
        bodega: number,
        marca: number,
        nivel: number,
        idLinea: number
    ) {
        console.log(
            "zona >>",
            zona,
            ", bodega >>",
            bodega,
            ", marca >>",
            marca,
            ", nivel >>",
            nivel,
            ", linea >>",
            idLinea
        );
        try {
            await axios
                .get<VTablaRentaAutorizaType[]>(
                    "https://apisatelite.azurewebsites.net/tablarentaautoriza/buscar/" +
                    String(zona) +
                    "/" +
                    String(bodega) +
                    "/" +
                    String(marca) +
                    "/" +
                    String(nivel) +
                    "/" +
                    String(idLinea)
                )
                .then((response) => {
                    setListaAutorizaRows(response.data);
                    nivel < 3
                        ? getConCopiaNiv2(zona, bodega, marca, idLinea)
                        : console.log("");
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(listaAutorizaRows, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }


    async function getTablaRenta(_id: number) {
        try {
            await axios
                .get<TablaRentaType>(
                    "https://apisatelite.azurewebsites.net/tablarenta/" + String(_id)
                )
                .then((response) => {
                    getvTablaRentaCrm(String(response.data.tarNumeroCotizacion));
                    setData2({
                        ...data2,
                        dias: response.data.tarDiasInv,
                        costo: response.data.tarDealerNet,
                        modelo: response.data.tarModelo,
                        anioModelo: response.data.tarModeloAnio,
                        pvp: response.data.tarPvpMarca,
                        marca: response.data.idMarca === 1 ? "CHEVROLET" : response.data.idMarca === 2 ? "GAC" : response.data.idMarca === 3 ? "VOLKSWAGEN" : "",
                        rebate: response.data.tarRebateValor,
                        orden: 0,
                        fechaCompra: response.data.fechaCompra,
                        idCotItem: 0,
                        smfId: 0,
                        color: response.data.tarColor,
                        vin: response.data.tarVin,
                        porcentajeIva: 15,
                    });
                    setTablaValoresData({
                        pvp_iva_gm: Number(response.data.tarPvpMarca),
                        dealear_net: Number(response.data.tarDealerNet),// - (Number(response.data.tarDealerNet)/0.15),
                        iva_porcen: 15,
                        dealear_net_iva: 0,
                        dealear_net_iva_porcen: 15,
                        margen_bruto: 0,
                        margen_bruto_procen: 0,
                        descuento_circular: Number(response.data.tarDescCirularValor),
                        descuento_circular_porcen: 0,
                        descuento_adicional: Number(response.data.tarDescConcecionarioValor),
                        descuento_adicional_porcen: 0,
                        descuento_asesor: 0,
                        descuento_asesor_porcen: 0,
                        total_descuentos: 0,
                        total_descuentos_porcen: Number(response.data.tarDescTotalValor),
                        rebate_gm: Number(response.data.tarRebateValor),
                        rebate_gm_porcen: 0,
                        utilidad_bruta: 0,
                        utilidad_bruta_porcen: 0,
                        nc_descuento_proauto: Number(response.data.tarNcConcesionario),
                        nc_descuento_gm: Number(response.data.tarNcMarca),
                        total_factura: Number(response.data.tarTotalFacturar),
                        regalos: Number(response.data.tarValorObsequios),
                        valor_transporte: Number(response.data.valor_transporte),
                    });
                    setDatosComplemento({
                        ...datosComplemento,
                        accesorios: response.data.tarAccDescripcion,
                        dispositivos: response.data.tarDispDescripcion,
                        observaciones: response.data.tarObservaciones,
                    });
                    setListaAccDispData({
                        ...listaAccDispData,
                        total_acc: response.data.tarAccValor,
                        total_disp: response.data.tarDispValor,
                        total_mant: response.data.tarMantenimientosPrepa
                    });
                    setCheked({
                        ...cheked,
                        accesorios_incluye: response.data.tarAccIncluyeFac === 1 ? true : false,
                        dispositivos_incluye: response.data.tarDispIncluyeFac === 1 ? true : false,
                        mantenimientos_incluye: response.data.tarMantenimientosPrepaIncluye === 1 ? true : false,
                    });
                    getNiveles(response.data.idCotItem, response.data.tarModeloAnio);
                    if (response.data.tarVin === "") {
                        setEsModeloSinVin(true)
                    }
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                    console.log("alerta por activar >>> en catch");
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getvTablaRentaCrm(idCrm: string) {
        try {
            await axios
                .get<vTablaRentaCrmResponse>(
                    "https://apisatelite.azurewebsites.net/tablarentacrm/" + idCrm
                )
                .then((response) => {
                    setData(response.data);
                    setAgencia(response.data.idBodega);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                    console.log("alerta por activar >>> en catch");
                    setAlertContent("Negocio no encontrado!");
                    setAlert(true);
                });

            console.log("data es >>", JSON.stringify(data, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }
    function consultar(id: string) {
        getvTablaRentaCrm(id);
        console.log("alerta >>", data.id);
    }

    function consultarMod() {
        const auxmodelos: modelo[] = [];
        setRowsMod(auxmodelos);
        getvTablaRentaModelo();
    }

    function consultarComplemento() {
        const auxmodelos: TypeComplementos[] = [];
        setComplementoRows(auxmodelos);
        getvTablaRentaComplemento();
    }

    function selectModelo(
        id: number,
        costo: number,
        modelo: string,
        anioModelo: number,
        pvp: number,
        dias: number,
        marca: string,
        rebate: number,
        orden: number,
        fechaCompra: string,
        idCotItem: number,
        smfId: number,
        color: string,
        vin: string,
        porcentajeIva: number
    ) {
        console.log("vehiculo soleccionado >> ", modelo, anioModelo);
        const respons: vTablaRentaInvResponse = {
            id: 1,
            costo: costo,
            modelo: modelo,
            anioModelo: anioModelo,
            pvp: pvp,
            dias: dias,
            marca: marca,
            rebate: rebate,
            orden: orden,
            fechaCompra: fechaCompra,
            idCotItem: idCotItem,
            smfId: smfId,
            color: color,
            vin: vin,
            porcentajeIva: porcentajeIva,
        };

        setData2(respons);

        const respons2: tablaValores = {
            pvp_iva_gm: pvp,
            dealear_net: costo,
            iva_porcen: 15,
            dealear_net_iva: costo * ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1),
            dealear_net_iva_porcen: 0,
            margen_bruto: 0,
            margen_bruto_procen: 0,
            descuento_circular: 0,
            descuento_circular_porcen: 0,
            descuento_adicional: 0,
            descuento_adicional_porcen: 0,
            descuento_asesor: 0,
            descuento_asesor_porcen: 0,
            total_descuentos: 0,
            total_descuentos_porcen: 0,
            rebate_gm: 0,
            rebate_gm_porcen: 0,
            utilidad_bruta: 0,
            utilidad_bruta_porcen: 0,
            nc_descuento_proauto: 0,
            nc_descuento_gm: 0,
            total_factura: 0,
            regalos: 0,
            valor_transporte: 0,
        };
        setTablaValoresData(respons2);
        getNiveles(idCotItem, anioModelo);
    }

    function EnviarNotificacionWPP(
        typing_time: number,
        to: string,
        body: string
    ) {
        const nuevoRegistro: NotificacionWPPType = {
            typing_time: typing_time,
            to: to,
            body: body,
        };
        console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
        postNotificarWPP(nuevoRegistro);
    }

    async function postNotificarWPP(nuevoRegistro: NotificacionWPPType) {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + "gMlELccOPZC3Uwy9zIyvQoe8feZ0xRjD",
        };
        try {
            axios
                .post<any>("https://gate.whapi.cloud/messages/text", nuevoRegistro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("mi wpp: ", response);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error peticion crear cli dms: ", error.message);
                return error.message;
            } else {
                console.log("error peticion crear cli dms: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    function setReservaVh(
        srvId: number,
        idCrm: string,
        srvVin: string,
        srvCorreoReserva: string,
        srvFechaReserva: Date,
        srvObservacionReserva: string,
        srvCorreoAnula: string,
        srvFechaAnula: Date,
        srvObservacionAnula: string,
        srvAnulado: number,
        srvModelo: string
    ) {
        console.log("tabla a guardar >> ");
        if (srvId > 0) {
            const nuevaRegistro: SateliteReservarVhType = {
                srvId: srvId,
                idCrm: idCrm,
                srvVin: srvVin,
                srvCorreoReserva: srvCorreoReserva,
                srvFechaReserva: srvFechaReserva,
                srvObservacionReserva: srvObservacionReserva,
                srvCorreoAnula: srvCorreoAnula,
                srvFechaAnula: srvFechaAnula,
                //srvObservacionAnula: srvObservacionAnula,
                srvAnulado: srvAnulado,
                srvModelo: srvModelo,
                srvObservacionAnula: srvObservacionReserva,
            };
            console.log("reserva a guardar >> ", nuevaRegistro);
            postGuardarReserva(nuevaRegistro);
        } else {
            const nuevaRegistro: SateliteReservarVhType = {
                idCrm: idCrm,
                srvVin: srvVin,
                srvCorreoReserva: srvCorreoReserva,
                srvFechaReserva: srvFechaReserva,
                srvObservacionReserva: srvObservacionReserva,
                srvAnulado: srvAnulado,
                srvModelo: srvModelo,
            };
            console.log("reserva a guardar >> ", nuevaRegistro);
            postGuardarReserva(nuevaRegistro);
        }
    }

    async function postGuardarReserva(registro: SateliteReservarVhType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post(
                    "https://apisatelite.azurewebsites.net/satelitereservavh",
                    registro,
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    setOpenReserva(false);
                    console.log("id guardado es: ", Number(response.data.srvId));
                    registro.srvAnulado === 1
                        ? setEstaReservado(false)
                        : setEstaReservado(true);
                    setOpenDialogAnularReserva(false);
                    getReservasVh();
                    Number(registro.srvId) > 0
                        ? setOpenDialogEstaReservado(false)
                        : setOpenDialogReservaOk(true);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getReservasVh() {
        try {
            await axios
                .get<SateliteReservarVhType>(
                    "https://apisatelite.azurewebsites.net/satelitereservavh/idcrm/" +
                    String(params.idcrm)
                )
                .then((response) => {
                    console.log("este es mi reserva >> ", response.data);
                    setSateliteReservarVh(response.data);
                    if (String(response.data.srvId) != "") {
                        ///setOpenDialogEstaReservado(true)
                    }
                })
                .catch((error) => {
                    console.log("algo paso con copia 2>> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getModeloReservado(_vin: string) {
        try {
            await axios
                .get<modelo>(
                    "https://apisatelite.azurewebsites.net/tablarentamodelo/vin/" + _vin
                )
                .then((response) => {
                    console.log("este es mi reserva >> ", response.data);

                    setCostoOriginal(response.data.costo);
                    setTablaValoresData({
                        ...tablaValoresData,
                        descuento_circular: 0,
                        descuento_circular_porcen: 0,
                        descuento_adicional: 0,
                        descuento_adicional_porcen: 0,
                        descuento_asesor: 0,
                        descuento_asesor_porcen: 0,
                        total_descuentos: 0,
                        total_descuentos_porcen: 0,
                        rebate_gm: 0,
                        rebate_gm_porcen: 0,
                        utilidad_bruta: 0,
                        utilidad_bruta_porcen: 0,
                        regalos: 0,
                    });
                    selectModelo(
                        response.data.id, // params.row.id,
                        response.data.costo, // params.row.costo, //+ valorComplementoAcumulado,
                        response.data.modelo,
                        response.data.anio,
                        response.data.pvp,
                        response.data.dias,
                        response.data.marca,
                        response.data.rebate,
                        response.data.orden,
                        response.data.fechaCompra,
                        response.data.idCotItem,
                        response.data.smfId,
                        response.data.color,
                        response.data.vin,
                        response.data.porcentajeIva
                    );
                    getListaAccesorioPorVin(response.data.vin);
                    setOpenDialogEstaReservado(false);
                })
                .catch((error) => {
                    console.log("algo paso con copia 2>> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    function setTablaRenta(
        esEnvio: boolean,
        tarId: number,
        tarFecha: string,
        tarNumeroCotizacion: string,
        tarAgencia: string,
        tarAsesor: string,
        tarCedulaCliente: string,
        tarNombreCliente: string,
        tarModelo: string,
        tarModeloAnio: number,
        tarColor: string,
        tarDiasInv: number,
        tarEscenarioFlota: string,
        tarEscenarioFlotaPorcentaje: number,
        tarFormaPago: string,
        tarAnticipos: number,
        tarPvpMarca: number,
        tarDealerNet: number,
        tarDealerNetValor: number,
        tarMargenBruto: number,
        tarMargenBrutoValor: number,
        tarDescCirular: number,
        tarDescCirularValor: number,
        tarDescConcecionario: number,
        tarDescConcecionarioValor: number,
        tarDescTotal: number,
        tarDescTotalValor: number,
        tarRebate: number,
        tarRebateValor: number,
        tarUtilidadBruta: number,
        tarUtilidadBrutaValor: number,
        tarTotalFacturar: number,
        tarNcConcesionario: number,
        tarNcMarca: number,
        tarValorObsequios: number,
        tarAccDescripcion: string,
        tarAccIncluyeFac: number,
        tarAccValor: number,
        tarDispDescripcion: string,
        tarDispIncluyeFac: number,
        tarDispValor: number,
        tarObservaciones: string,
        tarDestinatario: string,
        tarCorreo: string,
        tarCorreoRemitente: string,
        tarEnviado: number,
        tarNotificado: number,
        tarEstado: number,
        tarMantenimientosPrepa: number,
        tarCorreoSupervisa: string,
        tarObservacionNotificar: string,
        idBodega: number,
        idMarca: number,
        margenNivel: number,
        tarMantenimientosPrepaDescripcion: string,
        tarMantenimientosPrepaIncluye: number,
        fechaCompra: string,
        valor_transporte: number,
        conCopiaNiv2: string,
        conCopiaNiv3: string,
        tarVin: string,
        tarVinAcc: string,
        tarVinAccValor: number,
        idBodegaVeh: number,
        traCedularAutoriza: string,
        idLinea: number,
        tarEstadoTabla: number,
        idCotItem: number,
        idZona: number,
    ) {
        if (tarId > 0) {
            console.log("tabla a guardar >> ");
            const nuevaTabla: TablaRentaType = {
                tarId: tarId,
                tarFecha: tarFecha,
                tarNumeroCotizacion: tarNumeroCotizacion,
                tarAgencia: tarAgencia,
                tarAsesor: tarAsesor,
                tarCedulaCliente: tarCedulaCliente,
                tarNombreCliente: tarNombreCliente,
                tarModelo: tarModelo,
                tarModeloAnio: tarModeloAnio,
                tarColor: tarColor,
                tarDiasInv: tarDiasInv,
                tarEscenarioFlota: tarEscenarioFlota,
                tarEscenarioFlotaPorcentaje: tarEscenarioFlotaPorcentaje,
                tarFormaPago: tarFormaPago,
                tarAnticipos: tarAnticipos,
                tarPvpMarca: tarPvpMarca,
                tarDealerNet: tarDealerNet,
                tarDealerNetValor: tarDealerNetValor,
                tarMargenBruto: tarMargenBruto,
                tarMargenBrutoValor: tarMargenBrutoValor,
                tarDescCirular: tarDescCirular,
                tarDescCirularValor: tarDescCirularValor,
                tarDescConcecionario: tarDescConcecionario,
                tarDescConcecionarioValor: tarDescConcecionarioValor,
                tarDescTotal: tarDescTotal,
                tarDescTotalValor: tarDescTotalValor,
                tarRebate: tarRebate,
                tarRebateValor: tarRebateValor,
                tarUtilidadBruta: tarUtilidadBruta,
                tarUtilidadBrutaValor: tarUtilidadBrutaValor,
                tarTotalFacturar: tarTotalFacturar,
                tarNcConcesionario: tarNcConcesionario,
                tarNcMarca: tarNcMarca,
                tarValorObsequios: tarValorObsequios,
                tarAccDescripcion: tarAccDescripcion,
                tarAccIncluyeFac: tarAccIncluyeFac,
                tarAccValor: tarAccValor,
                tarDispDescripcion: tarDispDescripcion,
                tarDispIncluyeFac: tarDispIncluyeFac,
                tarDispValor: tarDispValor,
                tarObservaciones: tarObservaciones,
                tarDestinatario: tarDestinatario,
                tarCorreo: tarCorreo,
                tarCorreoRemitente: tarCorreoRemitente,
                tarEnviado: tarEnviado,
                tarNotificado: tarNotificado,
                tarEstado: tarEstado,
                tarMantenimientosPrepa: tarMantenimientosPrepa,
                tarCorreoSupervisa: tarCorreoSupervisa,
                tarObservacionNotificar: tarObservacionNotificar,
                idBodega: idBodega,
                idMarca: idMarca,
                margenNivel: margenNivel,
                tarMantenimientosPrepaDescripcion: tarMantenimientosPrepaDescripcion,
                tarMantenimientosPrepaIncluye: tarMantenimientosPrepaIncluye,
                fechaCompra: fechaCompra,
                valor_transporte: valor_transporte,
                conCopiaNiv2: conCopiaNiv2,
                conCopiaNiv3: conCopiaNiv3,
                tarVin: tarVin,
                tarVinAcc: tarVinAcc,
                tarVinAccValor: tarVinAccValor,
                idBodegaVeh: idBodegaVeh,
                idLinea: idLinea,
                tarEstadoTabla: tarEstadoTabla,
                idCotItem: idCotItem,
                idZona: idZona
            };
            console.log("tabla a guardar >> ", nuevaTabla);
            postGuardarTabla(nuevaTabla, traCedularAutoriza, esEnvio);

        } else {
            console.log("tabla a guardar >> ");
            const nuevaTabla: TablaRentaType = {
                tarFecha: tarFecha,
                tarNumeroCotizacion: tarNumeroCotizacion,
                tarAgencia: tarAgencia,
                tarAsesor: tarAsesor,
                tarCedulaCliente: tarCedulaCliente,
                tarNombreCliente: tarNombreCliente,
                tarModelo: tarModelo,
                tarModeloAnio: tarModeloAnio,
                tarColor: tarColor,
                tarDiasInv: tarDiasInv,
                tarEscenarioFlota: tarEscenarioFlota,
                tarEscenarioFlotaPorcentaje: tarEscenarioFlotaPorcentaje,
                tarFormaPago: tarFormaPago,
                tarAnticipos: tarAnticipos,
                tarPvpMarca: tarPvpMarca,
                tarDealerNet: tarDealerNet,
                tarDealerNetValor: tarDealerNetValor,
                tarMargenBruto: tarMargenBruto,
                tarMargenBrutoValor: tarMargenBrutoValor,
                tarDescCirular: tarDescCirular,
                tarDescCirularValor: tarDescCirularValor,
                tarDescConcecionario: tarDescConcecionario,
                tarDescConcecionarioValor: tarDescConcecionarioValor,
                tarDescTotal: tarDescTotal,
                tarDescTotalValor: tarDescTotalValor,
                tarRebate: tarRebate,
                tarRebateValor: tarRebateValor,
                tarUtilidadBruta: tarUtilidadBruta,
                tarUtilidadBrutaValor: tarUtilidadBrutaValor,
                tarTotalFacturar: tarTotalFacturar,
                tarNcConcesionario: tarNcConcesionario,
                tarNcMarca: tarNcMarca,
                tarValorObsequios: tarValorObsequios,
                tarAccDescripcion: tarAccDescripcion,
                tarAccIncluyeFac: tarAccIncluyeFac,
                tarAccValor: tarAccValor,
                tarDispDescripcion: tarDispDescripcion,
                tarDispIncluyeFac: tarDispIncluyeFac,
                tarDispValor: tarDispValor,
                tarObservaciones: tarObservaciones,
                tarDestinatario: tarDestinatario,
                tarCorreo: tarCorreo,
                tarCorreoRemitente: tarCorreoRemitente,
                tarEnviado: tarEnviado,
                tarNotificado: tarNotificado,
                tarEstado: tarEstado,
                tarMantenimientosPrepa: tarMantenimientosPrepa,
                tarCorreoSupervisa: tarCorreoSupervisa,
                tarObservacionNotificar: tarObservacionNotificar,
                idBodega: idBodega,
                idMarca: idMarca,
                margenNivel: margenNivel,
                tarMantenimientosPrepaDescripcion: tarMantenimientosPrepaDescripcion,
                tarMantenimientosPrepaIncluye: tarMantenimientosPrepaIncluye,
                fechaCompra: fechaCompra,
                valor_transporte: valor_transporte,
                conCopiaNiv2: conCopiaNiv2,
                conCopiaNiv3: conCopiaNiv3,
                tarVin: tarVin,
                tarVinAcc: tarVinAcc,
                tarVinAccValor: tarVinAccValor,
                idBodegaVeh: idBodegaVeh,
                idLinea: idLinea,
                tarEstadoTabla: tarEstadoTabla,
                idCotItem: idCotItem,
                idZona: idZona,
            };
            console.log("tabla a guardar >> ", nuevaTabla);
            postGuardarTabla(nuevaTabla, traCedularAutoriza, esEnvio);
        }

    }

    async function postGuardarTabla(
        tablaGuardar: TablaRentaType,
        telefono: string, esEnvio: boolean
    ) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post(
                    "https://apisatelite.azurewebsites.net/tablarenta",
                    tablaGuardar,
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    console.log("id guardado es: ", Number(response.data.tarId));
                    setOpenDialog(true);
                    if (esEnvio) {
                        EnviarNotificacionWPP(
                            0,
                            telefono,
                            (tablaGuardar.margenNivel === 1
                                ? "🚦🔴"
                                : tablaGuardar.margenNivel === 2
                                    ? "🚦🟡"
                                    : "🚦🟢") +
                            " Hola " +
                            tablaGuardar.tarDestinatario.trim() +
                            ", tienes una tabla de rentabilidad pendiente de aprobar de *" +
                            (username === 'magaly.quinde@mirasol.com.ec' ? 'MAGALY QUINDE':tablaGuardar.tarAsesor) +
                            "* de " +
                            tablaGuardar.tarAgencia +
                            ". Modelo " +
                            tablaGuardar.tarModelo +
                            " - " +
                            String(tablaGuardar.tarModeloAnio) +
                            " con una rentabilidad de *" +
                            tablaGuardar.tarUtilidadBruta +
                            " %*. Valor a facturar: *" +
                            formatter
                                .format(tablaGuardar.tarTotalFacturar)
                                .replace("$", "$ ") +
                            "*. Enlace: https://satelite.corporacionproauto.com.ec/tablarenta/" +
                            String(Number(response.data.tarId))
                        );
                    }

                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }


    const now = new Date(Date.now() - 5 * 60 * 60 * 1000); // Restar 5 horas
    const isoString = now.toISOString();
    const [dateText, timeText] = isoString.split('T');
    const formattedTimeText = timeText.split('.')[0];

    const [sateliteReservarVh, setSateliteReservarVh] =
        useState<SateliteReservarVhType>({
            srvId: 0,
            idCrm: "",
            srvVin: "",
            srvCorreoReserva: "",
            srvFechaReserva: new Date(Date.now() - 5 * 60 * 60 * 1000),
            srvObservacionReserva: "",
            srvCorreoAnula: "",
            //srvFechaAnula: new Date(),
            srvObservacionAnula: "",
            srvAnulado: 0,
            srvModelo: "",
        });







    async function getNiveles(id_cot_item: number, ano: number) {
        console.log("id_cot_item >>", id_cot_item);
        try {
            await axios
                .get<nivelesType>(
                    "https://apisatelite.azurewebsites.net/vtablarentanivel/" +
                    String(id_cot_item) +
                    "/" +
                    String(ano)
                )
                .then((response) => {
                    setDataNivel(response.data);
                    console.log("data es >>", JSON.stringify(dataNivel, null, 4));
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const styleModal = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1050,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const styleModalAccPorVin = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 650,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };



    //Aqui empieza calculos

    type tablaValores = {
        pvp_iva_gm: number;
        dealear_net: number;
        iva_porcen: number;
        dealear_net_iva: number;
        dealear_net_iva_porcen: number;
        margen_bruto: number;
        margen_bruto_procen: number;
        descuento_circular: number;
        descuento_circular_porcen: number;
        descuento_adicional: number;
        descuento_adicional_porcen: number;
        descuento_asesor: number;
        descuento_asesor_porcen: number;
        total_descuentos: number;
        total_descuentos_porcen: number;
        rebate_gm: number;
        rebate_gm_porcen: number;
        utilidad_bruta: number;
        utilidad_bruta_porcen: number;
        nc_descuento_gm: number;
        nc_descuento_proauto: number;
        total_factura: number;
        regalos: number;
        valor_transporte: number;
    };

    type parametroBuqueda = {
        parametro: string;
    };

    const [tablaValoresData, setTablaValoresData] = React.useState<tablaValores>({
        pvp_iva_gm: 0,
        dealear_net: 0,
        iva_porcen: 15,
        dealear_net_iva: 0,
        dealear_net_iva_porcen: 0,
        margen_bruto: 0,
        margen_bruto_procen: 0,
        descuento_circular: 0,
        descuento_circular_porcen: 0,
        descuento_adicional: 0,
        descuento_adicional_porcen: 0,
        descuento_asesor: 0,
        descuento_asesor_porcen: 0,
        total_descuentos: 0,
        total_descuentos_porcen: 0,
        rebate_gm: 0,
        rebate_gm_porcen: 0,
        utilidad_bruta: 0,
        utilidad_bruta_porcen: 0,
        nc_descuento_proauto: 0,
        nc_descuento_gm: 0,
        total_factura: 0,
        regalos: 0,
        valor_transporte: 0,
    });

    const [parametroBusqueda, setParametroBusqueda] =
        React.useState<parametroBuqueda>({
            parametro: "",
        });

    const [parametroBusquedaComplemento, setParametroBusquedaComplemento] =
        React.useState<parametroBuqueda>({
            parametro: "",
        });

    let color_margen: string = "black";

    const listadoAgencias: bodegaType[] = [];
    const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoAgencias);
    const [agencia, setAgencia] = React.useState(0);

    const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgencia(Number(event.target.value));
    };

    const dataTablaValores = (e: React.ChangeEvent<HTMLInputElement>) => {
        tablaValoresData.utilidad_bruta_porcen >= 7
            ? (color_margen = "green")
            : tablaValoresData.utilidad_bruta_porcen > 5
                ? (color_margen = "red")
                : (color_margen = "orange");
        setTablaValoresData({
            ...tablaValoresData,
            [e.target.name]: e.target.value,
        });
        console.log("aqui vamos >>>", tablaValoresData);
        console.log("aqui vamos >>>", color_margen);
    };

    const dataReservaVh = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSateliteReservarVh({
            ...sateliteReservarVh,
            [e.target.name]: e.target.value,
        });
        console.log(
            "mi motivo >> ",
            String(sateliteReservarVh.srvObservacionAnula)
        );
    };

    interface modelo {
        id: number;
        modelo: string;
        costo: number;
        anio: number;
        dias: number;
        pvp: number;
        marca: string;
        rebate: number;
        orden: number;
        fechaCompra: string;
        idCotItem: number;
        smfId: number;
        color: string;
        vin: string;
        porcentajeIva: number;
        validador: number;
        pagado: string;
        bodega: string;
        correoReserva: string;
        disponible: number;
    }

    const originalRowsMod: modelo[] = [];
    const [rowsMod, setRowsMod] = useState<modelo[]>(originalRowsMod);
    const [rowsModSinVin, setRowsModSinVin] = useState<modelo[]>(originalRowsMod);
    const dataComplementoRows: TypeComplementos[] = [];

    const [complementoRows, setComplementoRows] =
        useState<TypeComplementos[]>(dataComplementoRows);

    const dataBusqueda = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParametroBusqueda({
            ...parametroBusqueda,
            [e.target.name]: e.target.value,
        });
    };

    const dataBusquedaComplemento = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParametroBusquedaComplemento({
            ...parametroBusquedaComplemento,
            [e.target.name]: e.target.value,
        });
    };



    async function getBodegas() {
        try {
            await axios
                .get<bodegaType[]>(
                    "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
                )
                .then((response) => {
                    setDataAgencia(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

            console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getvTablaRentaModelo() {
        const urlapi: string = esModeloSinVin ?
            "https://apisatelite.azurewebsites.net/tablarentamodelosinvin/todo/" + username + "/" +
            parametroBusqueda.parametro
            :
            "https://apisatelite.azurewebsites.net/tablarentamodelo/todo/" + username + "/" +
            parametroBusqueda.parametro;
        try {
            await axios
                .get<modelo[]>(
                    urlapi
                )
                .then((response) => {
                    if (esModeloSinVin) {
                        setRowsModSinVin(response.data);
                    } else {
                        setRowsMod(response.data);
                    }
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                    //setError(error);
                });

            console.log("data es >>", JSON.stringify(data2, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getvTablaRentaComplemento() {
        //obtine modelos para el modal
        try {
            await axios
                .get<TypeComplementos[]>(
                    "https://apisatelite.azurewebsites.net/tablarentacomplemento/" +
                    parametroBusquedaComplemento.parametro
                )
                .then((response) => {
                    setComplementoRows(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                    //setError(error);
                });

            console.log("data es >>", JSON.stringify(data2, null, 4));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }


    const listadoAccPorVin: VAccesoriosPorVin[] = [];
    const [dataAccPorVin, setDataAccPorVin] =
        useState<VAccesoriosPorVin[]>(listadoAccPorVin);

    const [totalAccPorVin, setTotalAccPorVin] = React.useState(0);
    var totalAccPorVinUnidad: number = 0;

    async function getListaAccesorioPorVin(vin: string) {
        try {
            await axios
                .get<VAccesoriosPorVin[]>(
                    "https://apisatelite.azurewebsites.net/vaccesoriosporvin/" + vin
                )
                .then((response) => {
                    setDataAccPorVin(response.data);
                    response.data.forEach(function (value) {
                        const ventasUnit = Number(value.pvp) * Number(value.cantidad);
                        totalAccPorVinUnidad = totalAccPorVinUnidad + ventasUnit;
                        console.log("pvp total", totalAccPorVinUnidad, ventasUnit);
                        setTotalAccPorVin(totalAccPorVinUnidad);
                    });
                    setOpen(false);
                    response.data?.length > 0
                        ? setOpenDialogAccPorVin(true)
                        : Number(sateliteReservarVh.srvId) > 0 ?
                            console.log()
                            : setOpenReserva(true);
                })
                .catch((error) => {
                    console.log("entre al catch >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                                Tabla de rentabilidad
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >
                            <Box component={"form"}>
                                <Typography
                                    sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                                    variant="h6"
                                >
                                    <strong> Información general </strong>
                                </Typography>
                                <Grid>
                                    <TextField
                                        name="fecha"
                                        size="small"
                                        margin="normal"
                                        label="Fecha"
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{
                                            mt: 0.5,
                                            mb: 0.5,
                                            marginRight: "1%",
                                            width: "15%",
                                            marginLeft: "10%",
                                        }}
                                        value={new Date(Date.now()).toISOString().substring(0, 10)}
                                    />
                                    <TextField
                                        name="idCotizacion"
                                        size="small"
                                        margin="normal"
                                        label="Record ID CRM"
                                        type="number"
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        value={
                                            parametroPag != "0"
                                                ? params.idcrm
                                                : parametrosCrmData.idCotizacion
                                        }
                                        onChange={dataCrm}
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "15%" }}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "15%" }}
                                        onClick={function (event) {
                                            consultar(
                                                parametroPag != "0"
                                                    ? String(params.idcrm)
                                                    : String(parametrosCrmData.idCotizacion)
                                            );
                                        }}
                                    >
                                        Actualizar CRM
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 0.5, mb: 0.5, mr: "1%", width: "16%" }}
                                        onClick={(e) => {
                                            setEsModeloSinVin(true);
                                            Number(sateliteReservarVh.srvId) > 0
                                                ? setOpenDialogEstaReservado(true)
                                                : setOpen(true)
                                        }
                                        }
                                    >
                                        Seleccionar modelo
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 0.5, mb: 0.5, width: "15%" }}
                                        onClick={(e) => {
                                            setEsModeloSinVin(false);
                                            Number(sateliteReservarVh.srvId) > 0
                                                ? setOpenDialogEstaReservado(true)
                                                : setOpen(true)
                                        }}
                                    >
                                        Seleccionar vin
                                    </Button>
                                    <Modal
                                        open={open}
                                        onClose={(e) => setOpen(false)}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={styleModal}>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                Seleccione un chasis / modelo
                                            </Typography>

                                            <TextField
                                                name="parametro"
                                                size="small"
                                                margin="normal"
                                                label="Modelo"
                                                onChange={dataBusqueda}
                                                sx={{ mt: 1.5, mb: 1.5, width: "66%" }}
                                            ></TextField>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    mt: 1.5,
                                                    mb: 1.5,
                                                    width: "15%",
                                                    marginLeft: "2%",
                                                }}
                                                onClick={consultarMod}
                                            >
                                                Consultar
                                            </Button>

                                            <Divider
                                                sx={{
                                                    marginLeft: "5%",
                                                    marginRight: "5%",
                                                    mt: 2,
                                                    mb: 2,
                                                }}
                                            />

                                            <Box
                                                sx={{
                                                    "& .theme-header": {
                                                        backgroundColor: "rgba(21,57,105,0.75)",
                                                        color: "rgba(255,255,255,1)",
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.id}
                                                    sx={{ height: 500 }}
                                                    editMode="row"
                                                    rows={esModeloSinVin ? rowsModSinVin : rowsMod}
                                                    columns={esModeloSinVin ? columnsModeloSinVin : columnsModelo}
                                                    disableColumnFilter
                                                    disableColumnSelector
                                                    disableDensitySelector
                                                    disableRowSelectionOnClick
                                                    slots={{ toolbar: GridToolbarQuickFilter }}
                                                    slotProps={{
                                                        toolbar: {
                                                            showQuickFilter: true,
                                                        },
                                                        pagination: {
                                                            labelRowsPerPage: "Registros por página:",
                                                        },
                                                    }}
                                                    initialState={{
                                                        pagination: { paginationModel: { pageSize: 10 } },
                                                    }}
                                                    pageSizeOptions={[10, 25, 50]}
                                                    localeText={{
                                                        footerTotalRows: false,
                                                        noRowsLabel: "No se ha encontrado datos.",
                                                        noResultsOverlayLabel:
                                                            "No se ha encontrado ningún resultado",
                                                        toolbarColumns: "Columnas",
                                                        toolbarColumnsLabel: "Seleccionar columnas",
                                                        toolbarFilters: "Filtros",
                                                        toolbarFiltersLabel: "Ver filtros",
                                                        toolbarFiltersTooltipHide: "Quitar filtros",
                                                        toolbarFiltersTooltipShow: "Ver filtros",
                                                        toolbarQuickFilterPlaceholder: "Buscar",
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Modal>
                                    <Modal
                                        open={openDialogAccPorVin}
                                        onClose={function (event) {
                                            //setOpenDialogAccPorVin(false);
                                        }}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={styleModalAccPorVin}>
                                            <Typography
                                                sx={{
                                                    mt: 1,
                                                    mb: 1,
                                                    width: "80%",
                                                    marginLeft: "10%",
                                                    textAlign: "center",
                                                }}
                                                variant="h6"
                                                style={{ display: "inline-block" }}
                                            >
                                                Alerta, este VIN se encuentra accesorizado!
                                            </Typography>
                                            <Box
                                                sx={{
                                                    "& .theme-header": {
                                                        backgroundColor: "rgba(21,57,105,0.75)",
                                                        color: "rgba(255,255,255,1)",
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.id}
                                                    sx={{ height: 350 }}
                                                    editMode="row"
                                                    rows={dataAccPorVin}
                                                    columns={columnsAccPorVin}
                                                    disableColumnFilter
                                                    disableColumnSelector
                                                    disableDensitySelector
                                                    disableRowSelectionOnClick
                                                    // slots={{ toolbar: GridToolbarQuickFilter }}
                                                    slotProps={{
                                                        toolbar: {
                                                            showQuickFilter: true,
                                                        },
                                                        pagination: {
                                                            labelRowsPerPage: "Registros por página:",
                                                        },
                                                    }}
                                                    initialState={{
                                                        pagination: { paginationModel: { pageSize: 5 } },
                                                    }}
                                                    pageSizeOptions={[5, 10, 20]}
                                                    localeText={{
                                                        footerTotalRows: false,
                                                        noRowsLabel: "No se ha encontrado datos.",
                                                        noResultsOverlayLabel:
                                                            "No se ha encontrado ningún resultado",
                                                        toolbarColumns: "Columnas",
                                                        toolbarColumnsLabel: "Seleccionar columnas",
                                                        toolbarFilters: "Filtros",
                                                        toolbarFiltersLabel: "Ver filtros",
                                                        toolbarFiltersTooltipHide: "Quitar filtros",
                                                        toolbarFiltersTooltipShow: "Ver filtros",
                                                        // toolbarQuickFilterPlaceholder: "Buscar",
                                                    }}
                                                />
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    label="Total"
                                                    value={formatter
                                                        .format(totalAccPorVin)
                                                        .replace("$", "$  ")}
                                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                                    sx={{
                                                        mt: 1,
                                                        mb: 0.5,
                                                        width: "20%",
                                                        marginLeft: "80%",
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            style: { textAlign: "right" },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: "30%",
                                                    marginLeft: "35%",
                                                    mt: 2,
                                                    mb: 1,
                                                    backgroundColor: "rgba(21,57,105,0.75)",
                                                }}
                                                onClick={function (event) {
                                                    setOpenDialogAccPorVin(false);
                                                    estaReservado || Number(sateliteReservarVh.srvId) > 0
                                                        ? console.log()
                                                        : setOpenReserva(true);
                                                }}
                                            >
                                                Cerrar
                                            </Button>
                                        </Box>
                                    </Modal>
                                </Grid>
                                <Grid>
                                    <TextField
                                        name="agencia"
                                        size="small"
                                        margin="normal"
                                        label="Agencia"
                                        //onChange={tempDataCrm}
                                        value={data && data.agencia}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{
                                            mt: 1,
                                            mb: 0.5,
                                            marginRight: "2%",
                                            width: "39%",
                                            marginLeft: "10%",
                                        }}
                                    />
                                    <TextField
                                        name="nombreAsesor"
                                        size="small"
                                        margin="normal"
                                        label="Asesor"
                                        // onChange={tempDataCrm}
                                        value={data && data.nombreAsesor}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{ mt: 1, mb: 0.5, width: "39%" }}
                                    />
                                </Grid>
                                <Grid>
                                    <TextField
                                        name="cedulaCliente"
                                        size="small"
                                        margin="normal"
                                        //onChange={tempDataCrm}
                                        label="Cédula del cliente"
                                        value={data && data.cedulaCliente}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{
                                            mt: 0.5,
                                            mb: 0.5,
                                            marginRight: "1%",
                                            width: "19%",
                                            marginLeft: "10%",
                                        }}
                                    />
                                    <TextField
                                        name="nombreCliente"
                                        size="small"
                                        margin="normal"
                                        //onChange={tempDataCrm}
                                        label="Nombre del cliente"
                                        value={data && data.nombreCliente}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{ mt: 0.5, mb: 0.5, width: "60%" }}
                                    />
                                </Grid>
                                <Grid>
                                    <TextField
                                        name="modelo"
                                        size="small"
                                        margin="normal"
                                        label="Vin"
                                        value={data2 && data2.vin}
                                        style={{
                                            backgroundColor:
                                                dataAccPorVin?.length > 0
                                                    ? "rgb(247,202,202)"
                                                    : "rgb(242,242,242)",
                                        }}
                                        onDoubleClick={function (event) {
                                            dataAccPorVin?.length > 0
                                                ? setOpenDialogAccPorVin(true)
                                                : console.log("Vh sin accesorios");
                                        }}
                                        sx={{
                                            mt: 0.5,
                                            mb: 0.5,
                                            marginRight: "1%",
                                            width: "19%",
                                            marginLeft: "10%",
                                        }}
                                    />
                                    <TextField
                                        name="modelo"
                                        size="small"
                                        margin="normal"
                                        label="Modelo"
                                        value={data2 && data2.modelo}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "31%" }}
                                    />
                                    <TextField
                                        name="color"
                                        size="small"
                                        margin="normal"
                                        label="Color"
                                        onChange={dataDatosComplemento}
                                        value={data2 && data2.color}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                                    />
                                    <TextField
                                        name="anioModelo"
                                        size="small"
                                        margin="normal"
                                        label="Año modelo"
                                        value={data2 && data2.anioModelo}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{ mt: 0.5, mb: 0.5, width: "7%", marginRight: "1%" }}
                                    />
                                    <TextField
                                        name="dias"
                                        size="small"
                                        margin="normal"
                                        label="Días Inv"
                                        value={data2 && data2.dias}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{ mt: 0.5, mb: 0.5, width: "7%" }}
                                    />
                                </Grid>
                                <Grid>
                                    <TextField
                                        name="escenario_flota"
                                        size="small"
                                        margin="normal"
                                        value={datosComplemento.escenario_flota}
                                        onChange={dataDatosComplemento}
                                        label="Escenario flota"
                                        sx={{
                                            mt: 0.5,
                                            mb: 0.5,
                                            marginRight: "1%",
                                            width: "19%",
                                            marginLeft: "10%",
                                        }}
                                    />
                                    <TextField
                                        name="escenario_flota_valor"
                                        value={datosComplemento.escenario_flota_valor}
                                        onChange={dataDatosComplemento}
                                        size="small"
                                        margin="normal"
                                        type="number"
                                        fullWidth
                                        label="%"
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "19%" }}
                                    />
                                    <TextField
                                        name="formaPago"
                                        size="small"
                                        margin="normal"
                                        fullWidth
                                        label="Forma pago"
                                        value={data && data.formaPago}
                                        style={{ backgroundColor: "rgb(242,242,242)" }}
                                        sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                    />
                                    <TextField
                                        name="entrada"
                                        size="small"
                                        margin="normal"
                                        //onChange={tempDataCrm}
                                        label="Anticipos"
                                        //type="number"
                                        value={formatter
                                            .format(data && data.entrada)
                                            .replace("$", "$  ")}
                                        style={{
                                            backgroundColor:
                                                Number(data.entrada) > 0
                                                    ? "rgb(242,242,242)"
                                                    : "rgb(247,202,202)",
                                        }}
                                        sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Divider sx={{ mt: 2, mb: 1.5 }} />
                                <Typography
                                    sx={{ mt: 1, mb: 1, width: "40%", marginLeft: "10%" }}
                                    variant="h6"
                                    style={{ display: "inline-block" }}
                                >
                                    <strong> Cálculo Tabla de Rentabilidad </strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 0.5,
                                        mb: 0.5,
                                        width: "21%",
                                        textAlign: "center",
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    <strong>
                                        Fecha de compra:{" "}
                                        {String(data2.fechaCompra).substring(0, 10)}
                                    </strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 0.5,
                                        mb: 0.5,
                                        width: "21%",
                                        textAlign: "center",
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    <strong>VIN: {data2.vin}</strong>
                                </Typography>
                                <Grid>
                                    {data2.marca == "GAC" ? (
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    mt: 0.5,
                                                    mb: 0.5,
                                                    width: "41%",
                                                    marginLeft: "10%",
                                                }}
                                                style={{ display: "inline-block" }}
                                            >
                                                Kit Mandatorio / Complemento &nbsp;(GAC):
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    mt: 0.5,
                                                    mb: 0.5,
                                                    marginRight: "1%",
                                                    width: "19%",
                                                }}
                                                onClick={(e) => setOpenKit(true)}
                                            >
                                                Seleccionar
                                            </Button>
                                            <Modal
                                                open={openKit}
                                                onClose={(e) => setOpenKit(false)}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={styleModal}>
                                                    <Typography
                                                        id="modal-modal-description"
                                                        sx={{ mt: 2 }}
                                                    >
                                                        Seleccione un Kit Mandatorio / Complemento
                                                    </Typography>
                                                    <TextField
                                                        name="parametro"
                                                        size="small"
                                                        margin="normal"
                                                        label="Complemento"
                                                        onChange={dataBusquedaComplemento}
                                                        sx={{ mt: 1.5, mb: 1.5, width: "70%" }}
                                                    ></TextField>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            mt: 1.5,
                                                            mb: 1.5,
                                                            width: "28%",
                                                            marginLeft: "2%",
                                                        }}
                                                        onClick={consultarComplemento}
                                                        style={{ display: "inline-block" }}
                                                    >
                                                        Consultar
                                                    </Button>
                                                    <TableContainer sx={{ maxHeight: 440 }}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Codigo</TableCell>
                                                                    <TableCell>Modelo</TableCell>
                                                                    <TableCell>Original/Alterno</TableCell>
                                                                    <TableCell>PVP</TableCell>
                                                                    <TableCell>Seleccionar</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {complementoRows.map((row) => (
                                                                    <TableRow key={row.id}>
                                                                        <TableCell component="th" scope="row">
                                                                            {row.codigo}
                                                                        </TableCell>
                                                                        <TableCell>{row.modelo}</TableCell>
                                                                        <TableCell>{row.originalAlterno}</TableCell>
                                                                        <TableCell align="right">
                                                                            {row.pvp}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {" "}
                                                                            <Button
                                                                                onClick={function (event) {
                                                                                    AcumularComplemento(row.pvp);
                                                                                }}
                                                                            >
                                                                                ✓
                                                                            </Button>{" "}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TextField
                                                        name="complemento_descripcion"
                                                        size="small"
                                                        margin="normal"
                                                        label="Complemento"
                                                        // value={Number(valorComplementoAcumulado).toFixed(2)}
                                                        // onChange={dataBusqueda}
                                                        sx={{ mt: 4, mb: 2, width: "53%" }}
                                                        InputProps={{
                                                            inputProps: {
                                                                style: { textAlign: "right" },
                                                            },
                                                        }}
                                                    ></TextField>
                                                    <Button
                                                        sx={{
                                                            mt: 4,
                                                            mb: 2,
                                                            marginLeft: "2%",
                                                            width: "10%",
                                                        }}
                                                        onClick={function (event) {
                                                            setValorComplementoAcumulado(0);
                                                            BorrarComplemento();
                                                        }}
                                                    >
                                                        Borrar
                                                    </Button>
                                                    <TextField
                                                        name="parametro"
                                                        size="small"
                                                        margin="normal"
                                                        label="Complemento"
                                                        value={Number(valorComplementoAcumulado).toFixed(2)}
                                                        // onChange={dataBusqueda}
                                                        sx={{
                                                            mt: 4,
                                                            mb: 2,
                                                            marginLeft: "3%",
                                                            width: "15%",
                                                        }}
                                                        InputProps={{
                                                            inputProps: {
                                                                style: { textAlign: "right" },
                                                            },
                                                        }}
                                                    ></TextField>
                                                    <Tooltip title="Incluir valores de COMPLEMENTOS en DEALER NET">
                                                        <Switch
                                                            checked={cheked.complement_incluye}
                                                            onChange={handleCheked}
                                                            name="complement_incluye"
                                                            sx={{ mt: 4, mb: 2, marginLeft: "6%" }}
                                                        />
                                                    </Tooltip>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            mt: 4,
                                                            mb: 2,
                                                            width: "15%",
                                                            marginLeft: "85%",
                                                        }}
                                                        onClick={(e) => setOpenKit(false)}
                                                        style={{ display: "inline-block" }}
                                                    >
                                                        Aceptar
                                                    </Button>
                                                </Box>
                                            </Modal>
                                            <Tooltip title="Lista de precios">
                                                <TextField
                                                    name="complemento"
                                                    size="small"
                                                    margin="normal"
                                                    label="$"
                                                    type="number"
                                                    //defaultValue={data2 && data2.pvp}
                                                    value={Number(valorComplementoAcumulado).toFixed(2)}
                                                    // onChange={dataTablaValores}
                                                    sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                                    InputProps={{
                                                        inputProps: {
                                                            style: { textAlign: "right" },
                                                        },
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    ) : (
                                        <Grid></Grid>
                                    )}

                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            PVP con IVA &nbsp;(Marca):
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            onClick={handleClickOpenPVP}
                                        >
                                            Actualizar PVP
                                        </Button>
                                        <Dialog
                                            open={openReserva}
                                            //onClose={(e) => setOpenReserva(false)}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Reservar VIN en Satélite"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    {"¿Desea reservar este VIN en Satélite?"}
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={(e) => setOpenReserva(false)}
                                                    autoFocus
                                                >
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    onClick={(e) =>
                                                        setReservaVh(
                                                            0,
                                                            String(params.idcrm),
                                                            data2.vin,
                                                            username,
                                                            new Date(),
                                                            "",
                                                            "",
                                                            new Date(),
                                                            "",
                                                            0,
                                                            data2.modelo +
                                                            " - " +
                                                            data2.color +
                                                            " - " +
                                                            String(data2.anioModelo)
                                                        )
                                                    }
                                                    autoFocus
                                                >
                                                    Reservar
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog
                                            open={openDialogPVP}
                                            onClose={handleCloseDialogPVP}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Actualizar PVP"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    <TextField
                                                        name="nuevoPVP"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        onChange={dataNuevoPVP}
                                                        label="PVP"
                                                        defaultValue={data2 && data2.pvp}
                                                        sx={{ mt: 1, mb: 1, width: "100%" }}
                                                    />
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseDialogPVP} autoFocus>
                                                    Aceptar
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Tooltip title="Lista de precios">
                                            <TextField
                                                name="pvp_iva_gm"
                                                size="small"
                                                margin="normal"
                                                label="$"
                                                //type="number"
                                                //defaultValue={data2 && data2.pvp}
                                                value={formatter
                                                    .format(data2 && data2.pvp)
                                                    .replace("$", "$  ")}
                                                onChange={dataTablaValores}
                                                sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                                InputProps={{
                                                    inputProps: {
                                                        style: { textAlign: "right" },
                                                    },
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Dealer NET:
                                        </Typography>
                                        <TextField
                                            name="dealear_net"
                                            size="small"
                                            margin="normal"
                                            label="Sin IVA"
                                            // type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={formatter
                                                .format(data2 && Number(data2.costo))
                                                .replace("$", "$  ")} //{(cheked.complement_incluye ? data2.costo + valorComplementoAcumulado : costoOriginal)}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="dealear_net_iva"
                                            size="small"
                                            margin="normal"
                                            label="Con IVA"
                                            //type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={formatter
                                                .format(
                                                    tablaValoresData &&
                                                    Number(
                                                        (
                                                            tablaValoresData.dealear_net *
                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)
                                                        ).toFixed(2)
                                                    )
                                                )
                                                .replace("$", "$  ")}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Margen bruto:
                                        </Typography>
                                        <TextField
                                            name="margen_bruto_procen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData && tablaValoresData.pvp_iva_gm > 0
                                                    ? Number(
                                                        Number(
                                                            (((tablaValoresData.pvp_iva_gm /
                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                tablaValoresData.dealear_net) *
                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                tablaValoresData.pvp_iva_gm) *
                                                            100
                                                        ).toFixed(2)
                                                    )
                                                    : 0
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="margen_bruto"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={formatter
                                                .format(
                                                    tablaValoresData && tablaValoresData.pvp_iva_gm > 0
                                                        ? Number(
                                                            Number(
                                                                (tablaValoresData.pvp_iva_gm /
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                    tablaValoresData.dealear_net) *
                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)
                                                            ).toFixed(2)
                                                        )
                                                        : 0
                                                )
                                                .replace("$", "$  ")}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Descuento circular retail / flota :
                                        </Typography>
                                        <TextField
                                            name="descuento_circular_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData &&
                                                Number(
                                                    (
                                                        (tablaValoresData.descuento_circular /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                )
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="descuento_circular"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            id="formatted-numberformat-input"
                                            type="number"
                                            value={tablaValoresData.descuento_circular}
                                            // value={
                                            //   tablaValoresData && tablaValoresData.descuento_circular
                                            // }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Descuento adicional Concesionario:
                                        </Typography>
                                        <TextField
                                            name="descuento_adicional_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData &&
                                                Number(
                                                    (
                                                        (tablaValoresData.descuento_adicional /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                )
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="descuento_adicional"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            type="number"
                                            value={tablaValoresData.descuento_adicional}
                                            // value={
                                            //   tablaValoresData && tablaValoresData.descuento_adicional
                                            // }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Total descuentos:
                                        </Typography>
                                        <TextField
                                            name="total_descuentos_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData &&
                                                Number(
                                                    (
                                                        ((Number(tablaValoresData.descuento_circular) +
                                                            Number(tablaValoresData.descuento_adicional) +
                                                            Number(tablaValoresData.descuento_asesor)) /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                )
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="total_descuentos"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={formatter
                                                .format(
                                                    tablaValoresData &&
                                                    Number(tablaValoresData.descuento_circular) +
                                                    Number(tablaValoresData.descuento_adicional) +
                                                    Number(tablaValoresData.descuento_asesor)
                                                )
                                                .replace("$", "$ ")}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Rebate sugerido:
                                        </Typography>
                                        <TextField
                                            name="rebate_gm"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            // type="number"
                                            value={formatter
                                                .format(data2 && data2.rebate)
                                                .replace("$", "$  ")}
                                            onChange={dataTablaValores}
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%", marginLeft: "20%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Rebate Marca de circular retail / flota:
                                        </Typography>
                                        <TextField
                                            name="rebate_gm_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData &&
                                                Number(
                                                    (
                                                        (tablaValoresData.rebate_gm /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                )
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="rebate_gm"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            type="number"
                                            value={tablaValoresData.rebate_gm}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            <strong>Utilidad bruta:</strong>
                                        </Typography>
                                        <TextField
                                            name="utilidad_bruta_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={Number(
                                                Number(
                                                    tablaValoresData.pvp_iva_gm > 0
                                                        ? Number(
                                                            Number(
                                                                (((tablaValoresData.pvp_iva_gm /
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                    (Number(
                                                                        tablaValoresData.descuento_circular
                                                                    ) +
                                                                        Number(
                                                                            tablaValoresData.descuento_adicional
                                                                        ) +
                                                                        Number(tablaValoresData.nc_descuento_gm) +
                                                                        Number(
                                                                            tablaValoresData.nc_descuento_proauto
                                                                        ) +
                                                                        Number(
                                                                            tablaValoresData.descuento_asesor
                                                                        ) +
                                                                        Number(tablaValoresData.regalos) +
                                                                        Number(
                                                                            tablaValoresData.valor_transporte
                                                                        )) /
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                    Number(tablaValoresData.rebate_gm) /
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                    tablaValoresData.dealear_net) *
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                    tablaValoresData.pvp_iva_gm) *
                                                                100
                                                            ).toFixed(2)
                                                        )
                                                        : 0
                                                ).toFixed(2)
                                            )}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        textAlign: "right",
                                                        fontWeight: "bold",
                                                        color:
                                                            Number(
                                                                Number(
                                                                    tablaValoresData.pvp_iva_gm > 0
                                                                        ? Number(
                                                                            Number(
                                                                                (((tablaValoresData.pvp_iva_gm /
                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                    (Number(
                                                                                        tablaValoresData.descuento_circular
                                                                                    ) +
                                                                                        Number(
                                                                                            tablaValoresData.descuento_adicional
                                                                                        ) +
                                                                                        Number(
                                                                                            tablaValoresData.nc_descuento_gm
                                                                                        ) +
                                                                                        Number(
                                                                                            tablaValoresData.nc_descuento_proauto
                                                                                        ) +
                                                                                        Number(
                                                                                            tablaValoresData.descuento_asesor
                                                                                        ) +
                                                                                        Number(tablaValoresData.regalos) +
                                                                                        Number(
                                                                                            tablaValoresData.valor_transporte
                                                                                        )) /
                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                                    Number(tablaValoresData.rebate_gm) /
                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                    tablaValoresData.dealear_net) *
                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                                    tablaValoresData.pvp_iva_gm) *
                                                                                100
                                                                            ).toFixed(2)
                                                                        )
                                                                        : 0
                                                                ).toFixed(2)
                                                            ) > Number(dataNivel.verde)
                                                                ? "green"
                                                                : Number(
                                                                    Number(
                                                                        tablaValoresData.pvp_iva_gm > 0
                                                                            ? Number(
                                                                                Number(
                                                                                    (((tablaValoresData.pvp_iva_gm /
                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                        (Number(
                                                                                            tablaValoresData.descuento_circular
                                                                                        ) +
                                                                                            Number(
                                                                                                tablaValoresData.descuento_adicional
                                                                                            ) +
                                                                                            Number(
                                                                                                tablaValoresData.nc_descuento_gm
                                                                                            ) +
                                                                                            Number(
                                                                                                tablaValoresData.nc_descuento_proauto
                                                                                            ) +
                                                                                            Number(
                                                                                                tablaValoresData.descuento_asesor
                                                                                            ) +
                                                                                            Number(
                                                                                                tablaValoresData.regalos
                                                                                            ) +
                                                                                            Number(
                                                                                                tablaValoresData.valor_transporte
                                                                                            )) /
                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                                        Number(
                                                                                            tablaValoresData.rebate_gm
                                                                                        ) /
                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                        tablaValoresData.dealear_net) *
                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                                        tablaValoresData.pvp_iva_gm) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                            )
                                                                            : 0
                                                                    ).toFixed(2)
                                                                ) < Number(dataNivel.naranja)
                                                                    ? "red"
                                                                    : "orange",
                                                    },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="utilidad_bruta"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            //type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={formatter
                                                .format(
                                                    Number(
                                                        tablaValoresData.pvp_iva_gm > 0
                                                            ? Number(
                                                                Number(
                                                                    (tablaValoresData.pvp_iva_gm /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                        (Number(
                                                                            tablaValoresData.descuento_circular
                                                                        ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_adicional
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.nc_descuento_gm
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.nc_descuento_proauto
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_asesor
                                                                            ) +
                                                                            Number(tablaValoresData.regalos) +
                                                                            Number(
                                                                                tablaValoresData.valor_transporte
                                                                            )) /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                        Number(tablaValoresData.rebate_gm) /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                        tablaValoresData.dealear_net) *
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)
                                                                ).toFixed(2)
                                                            )
                                                            : 0
                                                    )
                                                )
                                                .replace("$", "$  ")}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right", fontWeight: "bold" },
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block", fontStyle: "" }}
                                        >
                                            <strong>Total a facturar:</strong>
                                        </Typography>

                                        <TextField
                                            name="total_factura"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            // type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={formatter
                                                .format(
                                                    cheked.accesorios_incluye &&
                                                        cheked.dispositivos_incluye
                                                        ? tablaValoresData &&
                                                        tablaValoresData.pvp_iva_gm -
                                                        (Number(tablaValoresData.descuento_circular) +
                                                            Number(
                                                                tablaValoresData.descuento_adicional
                                                            ) +
                                                            Number(tablaValoresData.descuento_asesor)) +
                                                        Number(listaAccDispData.total_acc) +
                                                        Number(listaAccDispData.total_disp) +
                                                        Number(
                                                            cheked.mantenimientos_incluye
                                                                ? listaAccDispData.total_mant
                                                                : 0
                                                        )
                                                        : cheked.dispositivos_incluye
                                                            ? tablaValoresData &&
                                                            tablaValoresData.pvp_iva_gm -
                                                            (Number(tablaValoresData.descuento_circular) +
                                                                Number(tablaValoresData.descuento_adicional) +
                                                                Number(tablaValoresData.descuento_asesor)) +
                                                            Number(listaAccDispData.total_disp) +
                                                            Number(
                                                                cheked.mantenimientos_incluye
                                                                    ? listaAccDispData.total_mant
                                                                    : 0
                                                            )
                                                            : cheked.accesorios_incluye
                                                                ? tablaValoresData &&
                                                                tablaValoresData.pvp_iva_gm -
                                                                (Number(tablaValoresData.descuento_circular) +
                                                                    Number(tablaValoresData.descuento_adicional) +
                                                                    Number(tablaValoresData.descuento_asesor)) +
                                                                Number(listaAccDispData.total_acc) +
                                                                Number(
                                                                    cheked.mantenimientos_incluye
                                                                        ? listaAccDispData.total_mant
                                                                        : 0
                                                                )
                                                                : tablaValoresData &&
                                                                tablaValoresData.pvp_iva_gm -
                                                                (Number(tablaValoresData.descuento_circular) +
                                                                    Number(tablaValoresData.descuento_adicional) +
                                                                    Number(tablaValoresData.descuento_asesor)) +
                                                                Number(
                                                                    cheked.mantenimientos_incluye
                                                                        ? listaAccDispData.total_mant
                                                                        : 0
                                                                )
                                                )
                                                .replace("$", "$  ")}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginLeft: "20%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right", fontWeight: "bold" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                                    <Typography
                                        sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                                        variant="h6"
                                    >
                                        <strong> Descuentos fuera de factura</strong>
                                    </Typography>
                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Nota de crédito Concesionario:
                                        </Typography>
                                        <TextField
                                            name="nc_descuento_proauto_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData &&
                                                Number(
                                                    (
                                                        (tablaValoresData.nc_descuento_proauto /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                )
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />

                                        <TextField
                                            name="nc_descuento_proauto"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            type="number"
                                            value={tablaValoresData.nc_descuento_proauto}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Nota de crédito Marca:
                                        </Typography>
                                        <TextField
                                            name="nc_descuento_gm_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData &&
                                                Number(
                                                    (
                                                        (tablaValoresData.nc_descuento_gm /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                )
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="nc_descuento_gm"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            type="number"
                                            value={tablaValoresData.nc_descuento_gm}
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid>
                                        <Typography
                                            sx={{
                                                mt: 0.5,
                                                mb: 0.5,
                                                marginRight: "1%",
                                                width: "40%",
                                                marginLeft: "10%",
                                            }}
                                            style={{ display: "inline-block" }}
                                        >
                                            Valor en obsequios:
                                        </Typography>
                                        <TextField
                                            name="regalos_porcen"
                                            size="small"
                                            margin="normal"
                                            label="%"
                                            type="number"
                                            style={{ backgroundColor: "rgb(242,242,242)" }}
                                            value={
                                                tablaValoresData &&
                                                Number(
                                                    (
                                                        (tablaValoresData.regalos /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                )
                                            }
                                            onChange={dataTablaValores}
                                            sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "19%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        <Tooltip title="Valor en regalos. Ejm: tv, bicicletas, etc">
                                            <TextField
                                                name="regalos"
                                                size="small"
                                                margin="normal"
                                                label="$"
                                                type="number"
                                                value={Number(tablaValoresData.regalos)}
                                                onChange={dataTablaValores}
                                                sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                                InputProps={{
                                                    inputProps: {
                                                        style: { textAlign: "right" },
                                                    },
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 2, mb: 1.5 }} />
                                <Typography
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        marginRight: "1%",
                                        width: "40%",
                                        marginLeft: "10%",
                                    }}
                                    style={{ display: "inline-block" }}
                                    variant="h6"
                                >
                                    <strong> Gastos de transporte / otros</strong>
                                </Typography>
                                <TextField
                                    name="valor_transporte"
                                    size="small"
                                    margin="normal"
                                    label="$"
                                    type="number"
                                    value={tablaValoresData.valor_transporte}
                                    onChange={dataTablaValores}
                                    sx={{ mt: 0.5, mb: 0.5, marginLeft: "20%", width: "19%" }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{
                                        mt: 0.5,
                                        mb: 0.5,
                                        marginRight: "1%",
                                        width: "40%",
                                        marginLeft: "10%",
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Agencia de entrega:
                                </Typography>
                                <TextField
                                    id="comboagencia"
                                    select
                                    size="small"
                                    margin="normal"
                                    value={agencia}
                                    onChange={handleChangeAgencia}
                                    sx={{ mt: 0.5, mb: 0.5, marginLeft: "20%", width: "19%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {dataAgencia.map((option) => (
                                        <MenuItem key={option.bodId} value={option.bodId}>
                                            {option.agencia}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Divider sx={{ mt: 2, mb: 1.5 }} />
                                <Typography
                                    sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                                    variant="h6"
                                >
                                    <strong> Accesorios / Dispositivos / Mant. Prepagados</strong>
                                </Typography>
                                <Grid>
                                    <TextField
                                        name="accesorios"
                                        size="small"
                                        margin="normal"
                                        label="Accesorios"
                                        onChange={dataDatosComplemento}
                                        value={datosComplemento.accesorios}
                                        sx={{
                                            mt: 0.5,
                                            mb: 0.5,
                                            marginRight: "1%",
                                            width: "55%",
                                            marginLeft: "10%",
                                        }}
                                    />
                                    <Tooltip title="Incluir valores de ACCESORIOS en factura">
                                        <Switch
                                            checked={cheked.accesorios_incluye}
                                            onChange={handleCheked}
                                            name="accesorios_incluye"
                                            sx={{ mt: 0.5, mb: 0.5 }}
                                        />
                                    </Tooltip>
                                    <TextField
                                        name="total_acc"
                                        size="small"
                                        margin="normal"
                                        label="$ con IVA"
                                        type="number"
                                        value={listaAccDispData.total_acc}
                                        onChange={dataListaAccDisp}
                                        sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid>
                                    <TextField
                                        name="dispositivos"
                                        size="small"
                                        margin="normal"
                                        value={datosComplemento.dispositivos}
                                        onChange={dataDatosComplemento}
                                        label="Dispositivos"
                                        sx={{
                                            mt: 0.5,
                                            mb: 0.5,
                                            marginRight: "1%",
                                            width: "55%",
                                            marginLeft: "10%",
                                        }}
                                    />
                                    <Tooltip title="Incluir valores de DISPOSITIVOS en factura">
                                        <Switch
                                            checked={cheked.dispositivos_incluye}
                                            onChange={handleCheked}
                                            name="dispositivos_incluye"
                                            sx={{ mt: 0.5, mb: 0.5 }}
                                        />
                                    </Tooltip>
                                    <TextField
                                        name="total_disp"
                                        size="small"
                                        margin="normal"
                                        label="$ con IVA"
                                        type="number"
                                        value={listaAccDispData.total_disp}
                                        onChange={dataListaAccDisp}
                                        sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid>
                                    <TextField
                                        name="prepagados"
                                        size="small"
                                        margin="normal"
                                        value={datosComplemento.prepagados}
                                        onChange={dataDatosComplemento}
                                        label="Mant. Prepagados"
                                        sx={{
                                            mt: 0.5,
                                            mb: 0.5,
                                            marginRight: "1%",
                                            width: "55%",
                                            marginLeft: "10%",
                                        }}
                                    />
                                    <Tooltip title="Incluir valores de MATENIMINETOS PREPAGADOS en factura">
                                        <Switch
                                            checked={cheked.mantenimientos_incluye}
                                            onChange={handleCheked}
                                            name="mantenimientos_incluye"
                                            sx={{ mt: 0.5, mb: 0.5 }}
                                        />
                                    </Tooltip>

                                    <TextField
                                        name="total_mant"
                                        size="small"
                                        margin="normal"
                                        label="$ con IVA"
                                        type="number"
                                        value={listaAccDispData.total_mant}
                                        onChange={dataListaAccDisp}
                                        sx={{ mt: 0.5, mb: 0.5, width: "19%" }}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            },
                                        }}
                                    />
                                </Grid>

                                <Divider sx={{ mt: 2, mb: 1.5 }} />
                                <Grid>
                                    <Tooltip title="Aqui ingrese sus observaciones del negocio">
                                        <TextField
                                            name="observaciones"
                                            size="small"
                                            margin="normal"
                                            label="Observaciones"
                                            value={datosComplemento.observaciones}
                                            onChange={dataDatosComplemento}
                                            inputProps={{ maxLength: 495 }}
                                            sx={{ mt: 2, mb: 1.5, marginLeft: "10%", width: "80%" }}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Box>
                            <Box>
                                <Button variant="contained"
                                    sx={{ mt: 2, mb: 1.5, width: "10%", marginLeft: "58%" }}
                                    disabled={Number(sateliteReservarVh.srvId) > 0 || esModeloSinVin ? true : estaReservado ? true : data2.id > 0 ? false : true}
                                    onClick={(e) => setOpenReserva(true)}>
                                    Reservar VIN
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1.5, width: "10%", marginLeft: "2%" }}
                                    //disabled={ esModeloSinVin ? false : Number(sateliteReservarVh.srvId) > 0 && data2.vin.length > 0 ? false : true}
                                    onClick={
                                        tablaValoresData.pvp_iva_gm > 0 &&
                                            //data2.id > 0 &&
                                            tablaValoresData.dealear_net > 0
                                            ? (e) => setTablaRenta(
                                                false,
                                                Number(params.nuevo),
                                                new Date(Date.now()).toISOString().substring(0, 10),
                                                String(data.id),
                                                data.agencia,
                                                username === 'magaly.quinde@mirasol.com.ec' ? 'MAGALY QUINDE' : data.nombreAsesor,
                                                data.cedulaCliente,
                                                data.nombreCliente,
                                                data2.modelo,
                                                data2.anioModelo,
                                                data2.color,
                                                data2.dias,
                                                datosComplemento.escenario_flota,
                                                datosComplemento.escenario_flota_valor,
                                                data.formaPago,
                                                Number(data.entrada),
                                                Number(tablaValoresData.pvp_iva_gm),
                                                Number(data2.costo), //dealer net
                                                Number(
                                                    (
                                                        tablaValoresData.dealear_net *
                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)
                                                    ).toFixed(2)
                                                ), //dealer net iva
                                                tablaValoresData.pvp_iva_gm > 0
                                                    ? Number(
                                                        Number(
                                                            (((tablaValoresData.pvp_iva_gm /
                                                                ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                    100 +
                                                                    1) -
                                                                tablaValoresData.dealear_net) *
                                                                ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                    100 +
                                                                    1)) /
                                                                tablaValoresData.pvp_iva_gm) *
                                                            100
                                                        ).toFixed(2)
                                                    )
                                                    : 0, //margen bruto porcen
                                                tablaValoresData &&
                                                    tablaValoresData.pvp_iva_gm > 0
                                                    ? Number(
                                                        Number(
                                                            (tablaValoresData.pvp_iva_gm /
                                                                ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                    100 +
                                                                    1) -
                                                                tablaValoresData.dealear_net) *
                                                            ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                100 +
                                                                1)
                                                        ).toFixed(2)
                                                    )
                                                    : 0, //margen_bruto,
                                                Number(
                                                    (
                                                        (tablaValoresData.descuento_circular /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                ), //descuento_circular_porcen,
                                                Number(
                                                    tablaValoresData.descuento_circular
                                                ), //descuento_circular,
                                                Number(
                                                    (
                                                        (tablaValoresData.descuento_adicional /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                ), //descuento_adicional_porcen,
                                                Number(
                                                    tablaValoresData.descuento_adicional
                                                ), //descuento_adicional,
                                                Number(
                                                    (
                                                        ((Number(
                                                            tablaValoresData.descuento_circular
                                                        ) +
                                                            Number(
                                                                tablaValoresData.descuento_adicional
                                                            ) +
                                                            Number(
                                                                tablaValoresData.descuento_asesor
                                                            )) /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                ), //total_descuentos_porcen,
                                                Number(
                                                    tablaValoresData.descuento_circular
                                                ) +
                                                Number(
                                                    tablaValoresData.descuento_adicional
                                                ) +
                                                Number(
                                                    tablaValoresData.descuento_asesor
                                                ), //total_descuentos,
                                                Number(
                                                    (
                                                        (tablaValoresData.rebate_gm /
                                                            tablaValoresData.pvp_iva_gm) *
                                                        100
                                                    ).toFixed(2)
                                                ), //tablaValoresData.rebate_gm_porcen,
                                                Number(tablaValoresData.rebate_gm),
                                                Number(
                                                    Number(
                                                        tablaValoresData.pvp_iva_gm > 0
                                                            ? Number(
                                                                Number(
                                                                    (((tablaValoresData.pvp_iva_gm /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                        (Number(
                                                                            tablaValoresData.descuento_circular
                                                                        ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_adicional
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.nc_descuento_gm
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.nc_descuento_proauto
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_asesor
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.regalos
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.valor_transporte
                                                                            )) /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                        Number(
                                                                            tablaValoresData.rebate_gm
                                                                        ) /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                        tablaValoresData.dealear_net) *
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                        tablaValoresData.pvp_iva_gm) *
                                                                    100
                                                                ).toFixed(2)
                                                            )
                                                            : 0
                                                    ).toFixed(2)
                                                ), //utilidad_bruta_porcen,
                                                Number(
                                                    tablaValoresData.pvp_iva_gm > 0
                                                        ? Number(
                                                            Number(
                                                                (tablaValoresData.pvp_iva_gm /
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                        100 +
                                                                        1) -
                                                                    (Number(
                                                                        tablaValoresData.descuento_circular
                                                                    ) +
                                                                        Number(
                                                                            tablaValoresData.descuento_adicional
                                                                        ) +
                                                                        Number(
                                                                            tablaValoresData.nc_descuento_gm
                                                                        ) +
                                                                        Number(
                                                                            tablaValoresData.nc_descuento_proauto
                                                                        ) +
                                                                        Number(
                                                                            tablaValoresData.descuento_asesor
                                                                        ) +
                                                                        Number(
                                                                            tablaValoresData.regalos
                                                                        ) +
                                                                        Number(
                                                                            tablaValoresData.valor_transporte
                                                                        )) /
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                    Number(
                                                                        tablaValoresData.rebate_gm
                                                                    ) /
                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                    tablaValoresData.dealear_net) *
                                                                ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                    100 +
                                                                    1)
                                                            ).toFixed(2)
                                                        )
                                                        : 0
                                                ), //utilidad_bruta,
                                                Number(
                                                    cheked.accesorios_incluye &&
                                                        cheked.dispositivos_incluye
                                                        ? tablaValoresData &&
                                                        tablaValoresData.pvp_iva_gm -
                                                        (Number(
                                                            tablaValoresData.descuento_circular
                                                        ) +
                                                            Number(
                                                                tablaValoresData.descuento_adicional
                                                            ) +
                                                            Number(
                                                                tablaValoresData.descuento_asesor
                                                            )) +
                                                        Number(
                                                            listaAccDispData.total_acc
                                                        ) +
                                                        Number(
                                                            listaAccDispData.total_disp
                                                        ) +
                                                        Number(
                                                            cheked.mantenimientos_incluye
                                                                ? listaAccDispData.total_mant
                                                                : 0
                                                        )
                                                        : cheked.dispositivos_incluye
                                                            ? tablaValoresData &&
                                                            tablaValoresData.pvp_iva_gm -
                                                            (Number(
                                                                tablaValoresData.descuento_circular
                                                            ) +
                                                                Number(
                                                                    tablaValoresData.descuento_adicional
                                                                ) +
                                                                Number(
                                                                    tablaValoresData.descuento_asesor
                                                                )) +
                                                            Number(
                                                                listaAccDispData.total_disp
                                                            ) +
                                                            Number(
                                                                cheked.mantenimientos_incluye
                                                                    ? listaAccDispData.total_mant
                                                                    : 0
                                                            )
                                                            : cheked.accesorios_incluye
                                                                ? tablaValoresData &&
                                                                tablaValoresData.pvp_iva_gm -
                                                                (Number(
                                                                    tablaValoresData.descuento_circular
                                                                ) +
                                                                    Number(
                                                                        tablaValoresData.descuento_adicional
                                                                    ) +
                                                                    Number(
                                                                        tablaValoresData.descuento_asesor
                                                                    )) +
                                                                Number(
                                                                    listaAccDispData.total_acc
                                                                ) +
                                                                Number(
                                                                    cheked.mantenimientos_incluye
                                                                        ? listaAccDispData.total_mant
                                                                        : 0
                                                                )
                                                                : tablaValoresData &&
                                                                tablaValoresData.pvp_iva_gm -
                                                                (Number(
                                                                    tablaValoresData.descuento_circular
                                                                ) +
                                                                    Number(
                                                                        tablaValoresData.descuento_adicional
                                                                    ) +
                                                                    Number(
                                                                        tablaValoresData.descuento_asesor
                                                                    )) +
                                                                Number(
                                                                    cheked.mantenimientos_incluye
                                                                        ? listaAccDispData.total_mant
                                                                        : 0
                                                                )
                                                ), //tablaValoresData.total_factura,
                                                Number(
                                                    tablaValoresData.nc_descuento_proauto
                                                ),
                                                Number(tablaValoresData.nc_descuento_gm),
                                                Number(tablaValoresData.regalos),
                                                datosComplemento.accesorios,
                                                cheked.accesorios_incluye ? 1 : 0,
                                                Number(listaAccDispData.total_acc),
                                                datosComplemento.dispositivos,
                                                cheked.dispositivos_incluye ? 1 : 0,
                                                Number(listaAccDispData.total_disp),
                                                datosComplemento.observaciones,
                                                '', //row.traNombre, //nombre
                                                '', //row.traCorreo, //correo
                                                username,
                                                0, //enviado
                                                0, //notificado
                                                3, //estado
                                                Number(listaAccDispData.total_mant), //mant prepa
                                                datosComplemento.correo_destino,
                                                data2.orden > 1
                                                    ? "¡Alerta! Existen vehículos con mayor número de días de inventario."
                                                    : "",
                                                data.idBodega, // bodega,//data.idBodega, //bodega
                                                data2.marca === "CHEVROLET"
                                                    ? 1
                                                    : data2.marca === "GAC"
                                                        ? 2
                                                        : 3, //idMarca
                                                Number(
                                                    Number(
                                                        tablaValoresData.pvp_iva_gm > 0
                                                            ? Number(
                                                                Number(
                                                                    (((tablaValoresData.pvp_iva_gm /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                        (Number(
                                                                            tablaValoresData.descuento_circular
                                                                        ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_adicional
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.nc_descuento_gm
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.nc_descuento_proauto
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_asesor
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.regalos
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.valor_transporte
                                                                            )) /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                        Number(
                                                                            tablaValoresData.rebate_gm
                                                                        ) /
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                        tablaValoresData.dealear_net) *
                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                        tablaValoresData.pvp_iva_gm) *
                                                                    100
                                                                ).toFixed(2)
                                                            )
                                                            : 0
                                                    ).toFixed(2)
                                                ) > Number(dataNivel.verde)
                                                    ? 3
                                                    : Number(
                                                        Number(
                                                            tablaValoresData.pvp_iva_gm > 0
                                                                ? Number(
                                                                    Number(
                                                                        (((tablaValoresData.pvp_iva_gm /
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                            (Number(
                                                                                tablaValoresData.descuento_circular
                                                                            ) +
                                                                                Number(
                                                                                    tablaValoresData.descuento_adicional
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.nc_descuento_gm
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.nc_descuento_proauto
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.descuento_asesor
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.regalos
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.valor_transporte
                                                                                )) /
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                            Number(
                                                                                tablaValoresData.rebate_gm
                                                                            ) /
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                            tablaValoresData.dealear_net) *
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                            tablaValoresData.pvp_iva_gm) *
                                                                        100
                                                                    ).toFixed(2)
                                                                )
                                                                : 0
                                                        ).toFixed(2)
                                                    ) < Number(dataNivel.naranja)
                                                        ? 1
                                                        : 2, //nivel
                                                datosComplemento.prepagados, //descrip mantenimiento
                                                cheked.mantenimientos_incluye ? 1 : 0,
                                                data2.fechaCompra,
                                                tablaValoresData.valor_transporte,
                                                '',//dataConCopiaNiv2.traCorreo,
                                                '',//dataConCopiaNiv3.traCorreo,
                                                data2.vin,
                                                "",
                                                0,
                                                agencia,
                                                '',//row.traTelefono,
                                                data.idLinea,
                                                0,
                                                data2.idCotItem,
                                                data.idZona
                                            )
                                            :
                                            (e) => setOpenDialogError(true)
                                    }
                                >
                                    Guardar
                                </Button>


                                <Button
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1.5, width: "10%", marginLeft: "2%" }}
                                    disabled={estaReservado ? false : Number(sateliteReservarVh.srvId) > 0 && data2.vin.length > 0 ? false : true}
                                    onClick={
                                        tablaValoresData.pvp_iva_gm > 0 &&
                                            //data2.id > 0 &&
                                            tablaValoresData.dealear_net > 0
                                            ? (e) => {
                                                setOpenCorreo(true);
                                                getvListaAprueba(
                                                    data.idZona, //zona,//data.idZona,
                                                    data.idBodega, // bodega,//data.idBodega,
                                                    data2.marca === "CHEVROLET"
                                                        ? 1
                                                        : data2.marca === "GAC"
                                                            ? 2
                                                            : 3,

                                                    Number(
                                                        Number(
                                                            tablaValoresData.pvp_iva_gm > 0
                                                                ? Number(
                                                                    Number(
                                                                        (((tablaValoresData.pvp_iva_gm /
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                            (Number(
                                                                                tablaValoresData.descuento_circular
                                                                            ) +
                                                                                Number(
                                                                                    tablaValoresData.descuento_adicional
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.nc_descuento_gm
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.nc_descuento_proauto
                                                                                ) +
                                                                                Number(
                                                                                    tablaValoresData.descuento_asesor
                                                                                ) +
                                                                                Number(tablaValoresData.regalos) +
                                                                                Number(
                                                                                    tablaValoresData.valor_transporte
                                                                                )) /
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                            Number(tablaValoresData.rebate_gm) /
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                            tablaValoresData.dealear_net) *
                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                            tablaValoresData.pvp_iva_gm) *
                                                                        100
                                                                    ).toFixed(2)
                                                                )
                                                                : 0
                                                        ).toFixed(2)
                                                    ) > Number(dataNivel.verde)
                                                        ? 3
                                                        : Number(
                                                            Number(
                                                                tablaValoresData.pvp_iva_gm > 0
                                                                    ? Number(
                                                                        Number(
                                                                            (((tablaValoresData.pvp_iva_gm /
                                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                (Number(
                                                                                    tablaValoresData.descuento_circular
                                                                                ) +
                                                                                    Number(
                                                                                        tablaValoresData.descuento_adicional
                                                                                    ) +
                                                                                    Number(
                                                                                        tablaValoresData.nc_descuento_gm
                                                                                    ) +
                                                                                    Number(
                                                                                        tablaValoresData.nc_descuento_proauto
                                                                                    ) +
                                                                                    Number(
                                                                                        tablaValoresData.descuento_asesor
                                                                                    ) +
                                                                                    Number(
                                                                                        tablaValoresData.regalos
                                                                                    ) +
                                                                                    Number(
                                                                                        tablaValoresData.valor_transporte
                                                                                    )) /
                                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                                Number(
                                                                                    tablaValoresData.rebate_gm
                                                                                ) /
                                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                tablaValoresData.dealear_net) *
                                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                                tablaValoresData.pvp_iva_gm) *
                                                                            100
                                                                        ).toFixed(2)
                                                                    )
                                                                    : 0
                                                            ).toFixed(2)
                                                        ) < Number(dataNivel.naranja)
                                                            ? 1
                                                            : 2,
                                                    data.idLinea
                                                );
                                            }
                                            :
                                            (e) => setOpenDialogError(true)
                                    }
                                >
                                    Enviar
                                </Button>

                                <Modal
                                    open={openCorreo}
                                    onClose={(e) => setOpenCorreo(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleModal}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Seleccione un aprobador
                                        </Typography>
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nombre</TableCell>
                                                        <TableCell>Correo</TableCell>
                                                        <TableCell>Seleccionar</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listaAutorizaRows.map((row) => (
                                                        <TableRow key={row.traId}>
                                                            <TableCell component="th" scope="row">
                                                                {row.traNombre}
                                                            </TableCell>
                                                            <TableCell>{row.traCorreo}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={function (event) {
                                                                        setTablaRenta(
                                                                            true,
                                                                            Number(params.nuevo),
                                                                            new Date(Date.now()).toISOString().substring(0, 10),
                                                                            String(data.id),
                                                                            data.agencia,
                                                                            username === 'magaly.quinde@mirasol.com.ec' ? 'MAGALY QUINDE' : data.nombreAsesor,
                                                                            data.cedulaCliente,
                                                                            data.nombreCliente,
                                                                            data2.modelo,
                                                                            data2.anioModelo,
                                                                            data2.color,
                                                                            data2.dias,
                                                                            datosComplemento.escenario_flota,
                                                                            datosComplemento.escenario_flota_valor,
                                                                            data.formaPago,
                                                                            Number(data.entrada),
                                                                            Number(tablaValoresData.pvp_iva_gm),
                                                                            Number(data2.costo), //dealer net
                                                                            Number(
                                                                                (
                                                                                    tablaValoresData.dealear_net *
                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)
                                                                                ).toFixed(2)
                                                                            ), //dealer net iva
                                                                            tablaValoresData.pvp_iva_gm > 0
                                                                                ? Number(
                                                                                    Number(
                                                                                        (((tablaValoresData.pvp_iva_gm /
                                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                                                100 +
                                                                                                1) -
                                                                                            tablaValoresData.dealear_net) *
                                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                                                100 +
                                                                                                1)) /
                                                                                            tablaValoresData.pvp_iva_gm) *
                                                                                        100
                                                                                    ).toFixed(2)
                                                                                )
                                                                                : 0, //margen bruto porcen
                                                                            tablaValoresData &&
                                                                                tablaValoresData.pvp_iva_gm > 0
                                                                                ? Number(
                                                                                    Number(
                                                                                        (tablaValoresData.pvp_iva_gm /
                                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                                                100 +
                                                                                                1) -
                                                                                            tablaValoresData.dealear_net) *
                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                                            100 +
                                                                                            1)
                                                                                    ).toFixed(2)
                                                                                )
                                                                                : 0, //margen_bruto,
                                                                            Number(
                                                                                (
                                                                                    (tablaValoresData.descuento_circular /
                                                                                        tablaValoresData.pvp_iva_gm) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                            ), //descuento_circular_porcen,
                                                                            Number(
                                                                                tablaValoresData.descuento_circular
                                                                            ), //descuento_circular,
                                                                            Number(
                                                                                (
                                                                                    (tablaValoresData.descuento_adicional /
                                                                                        tablaValoresData.pvp_iva_gm) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                            ), //descuento_adicional_porcen,
                                                                            Number(
                                                                                tablaValoresData.descuento_adicional
                                                                            ), //descuento_adicional,
                                                                            Number(
                                                                                (
                                                                                    ((Number(
                                                                                        tablaValoresData.descuento_circular
                                                                                    ) +
                                                                                        Number(
                                                                                            tablaValoresData.descuento_adicional
                                                                                        ) +
                                                                                        Number(
                                                                                            tablaValoresData.descuento_asesor
                                                                                        )) /
                                                                                        tablaValoresData.pvp_iva_gm) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                            ), //total_descuentos_porcen,
                                                                            Number(
                                                                                tablaValoresData.descuento_circular
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_adicional
                                                                            ) +
                                                                            Number(
                                                                                tablaValoresData.descuento_asesor
                                                                            ), //total_descuentos,
                                                                            Number(
                                                                                (
                                                                                    (tablaValoresData.rebate_gm /
                                                                                        tablaValoresData.pvp_iva_gm) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                            ), //tablaValoresData.rebate_gm_porcen,
                                                                            Number(tablaValoresData.rebate_gm),
                                                                            Number(
                                                                                Number(
                                                                                    tablaValoresData.pvp_iva_gm > 0
                                                                                        ? Number(
                                                                                            Number(
                                                                                                (((tablaValoresData.pvp_iva_gm /
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                                    (Number(
                                                                                                        tablaValoresData.descuento_circular
                                                                                                    ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.descuento_adicional
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.nc_descuento_gm
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.nc_descuento_proauto
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.descuento_asesor
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.regalos
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.valor_transporte
                                                                                                        )) /
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                                                    Number(
                                                                                                        tablaValoresData.rebate_gm
                                                                                                    ) /
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                                    tablaValoresData.dealear_net) *
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                                                    tablaValoresData.pvp_iva_gm) *
                                                                                                100
                                                                                            ).toFixed(2)
                                                                                        )
                                                                                        : 0
                                                                                ).toFixed(2)
                                                                            ), //utilidad_bruta_porcen,
                                                                            Number(
                                                                                tablaValoresData.pvp_iva_gm > 0
                                                                                    ? Number(
                                                                                        Number(
                                                                                            (tablaValoresData.pvp_iva_gm /
                                                                                                ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                                                    100 +
                                                                                                    1) -
                                                                                                (Number(
                                                                                                    tablaValoresData.descuento_circular
                                                                                                ) +
                                                                                                    Number(
                                                                                                        tablaValoresData.descuento_adicional
                                                                                                    ) +
                                                                                                    Number(
                                                                                                        tablaValoresData.nc_descuento_gm
                                                                                                    ) +
                                                                                                    Number(
                                                                                                        tablaValoresData.nc_descuento_proauto
                                                                                                    ) +
                                                                                                    Number(
                                                                                                        tablaValoresData.descuento_asesor
                                                                                                    ) +
                                                                                                    Number(
                                                                                                        tablaValoresData.regalos
                                                                                                    ) +
                                                                                                    Number(
                                                                                                        tablaValoresData.valor_transporte
                                                                                                    )) /
                                                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                                                Number(
                                                                                                    tablaValoresData.rebate_gm
                                                                                                ) /
                                                                                                ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                                tablaValoresData.dealear_net) *
                                                                                            ((data2.porcentajeIva === 0 ? 0 : 15) /
                                                                                                100 +
                                                                                                1)
                                                                                        ).toFixed(2)
                                                                                    )
                                                                                    : 0
                                                                            ), //utilidad_bruta,
                                                                            Number(
                                                                                cheked.accesorios_incluye &&
                                                                                    cheked.dispositivos_incluye
                                                                                    ? tablaValoresData &&
                                                                                    tablaValoresData.pvp_iva_gm -
                                                                                    (Number(
                                                                                        tablaValoresData.descuento_circular
                                                                                    ) +
                                                                                        Number(
                                                                                            tablaValoresData.descuento_adicional
                                                                                        ) +
                                                                                        Number(
                                                                                            tablaValoresData.descuento_asesor
                                                                                        )) +
                                                                                    Number(
                                                                                        listaAccDispData.total_acc
                                                                                    ) +
                                                                                    Number(
                                                                                        listaAccDispData.total_disp
                                                                                    ) +
                                                                                    Number(
                                                                                        cheked.mantenimientos_incluye
                                                                                            ? listaAccDispData.total_mant
                                                                                            : 0
                                                                                    )
                                                                                    : cheked.dispositivos_incluye
                                                                                        ? tablaValoresData &&
                                                                                        tablaValoresData.pvp_iva_gm -
                                                                                        (Number(
                                                                                            tablaValoresData.descuento_circular
                                                                                        ) +
                                                                                            Number(
                                                                                                tablaValoresData.descuento_adicional
                                                                                            ) +
                                                                                            Number(
                                                                                                tablaValoresData.descuento_asesor
                                                                                            )) +
                                                                                        Number(
                                                                                            listaAccDispData.total_disp
                                                                                        ) +
                                                                                        Number(
                                                                                            cheked.mantenimientos_incluye
                                                                                                ? listaAccDispData.total_mant
                                                                                                : 0
                                                                                        )
                                                                                        : cheked.accesorios_incluye
                                                                                            ? tablaValoresData &&
                                                                                            tablaValoresData.pvp_iva_gm -
                                                                                            (Number(
                                                                                                tablaValoresData.descuento_circular
                                                                                            ) +
                                                                                                Number(
                                                                                                    tablaValoresData.descuento_adicional
                                                                                                ) +
                                                                                                Number(
                                                                                                    tablaValoresData.descuento_asesor
                                                                                                )) +
                                                                                            Number(
                                                                                                listaAccDispData.total_acc
                                                                                            ) +
                                                                                            Number(
                                                                                                cheked.mantenimientos_incluye
                                                                                                    ? listaAccDispData.total_mant
                                                                                                    : 0
                                                                                            )
                                                                                            : tablaValoresData &&
                                                                                            tablaValoresData.pvp_iva_gm -
                                                                                            (Number(
                                                                                                tablaValoresData.descuento_circular
                                                                                            ) +
                                                                                                Number(
                                                                                                    tablaValoresData.descuento_adicional
                                                                                                ) +
                                                                                                Number(
                                                                                                    tablaValoresData.descuento_asesor
                                                                                                )) +
                                                                                            Number(
                                                                                                cheked.mantenimientos_incluye
                                                                                                    ? listaAccDispData.total_mant
                                                                                                    : 0
                                                                                            )
                                                                            ), //tablaValoresData.total_factura,
                                                                            Number(
                                                                                tablaValoresData.nc_descuento_proauto
                                                                            ),
                                                                            Number(tablaValoresData.nc_descuento_gm),
                                                                            Number(tablaValoresData.regalos),
                                                                            datosComplemento.accesorios,
                                                                            cheked.accesorios_incluye ? 1 : 0,
                                                                            Number(listaAccDispData.total_acc),
                                                                            datosComplemento.dispositivos,
                                                                            cheked.dispositivos_incluye ? 1 : 0,
                                                                            Number(listaAccDispData.total_disp),
                                                                            datosComplemento.observaciones,
                                                                            row.traNombre, //nombre
                                                                            row.traCorreo, //correo
                                                                            username,
                                                                            0, //enviado
                                                                            0, //notificado
                                                                            0, //estado
                                                                            Number(listaAccDispData.total_mant), //mant prepa
                                                                            datosComplemento.correo_destino,
                                                                            data2.orden > 1
                                                                                ? "¡Alerta! Existen vehículos con mayor número de días de inventario."
                                                                                : "",
                                                                            data.idBodega, // bodega,//data.idBodega, //bodega
                                                                            data2.marca === "CHEVROLET"
                                                                                ? 1
                                                                                : data2.marca === "GAC"
                                                                                    ? 2
                                                                                    : 3, //idMarca
                                                                            Number(
                                                                                Number(
                                                                                    tablaValoresData.pvp_iva_gm > 0
                                                                                        ? Number(
                                                                                            Number(
                                                                                                (((tablaValoresData.pvp_iva_gm /
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                                    (Number(
                                                                                                        tablaValoresData.descuento_circular
                                                                                                    ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.descuento_adicional
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.nc_descuento_gm
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.nc_descuento_proauto
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.descuento_asesor
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.regalos
                                                                                                        ) +
                                                                                                        Number(
                                                                                                            tablaValoresData.valor_transporte
                                                                                                        )) /
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                                                    Number(
                                                                                                        tablaValoresData.rebate_gm
                                                                                                    ) /
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                                    tablaValoresData.dealear_net) *
                                                                                                    ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                                                    tablaValoresData.pvp_iva_gm) *
                                                                                                100
                                                                                            ).toFixed(2)
                                                                                        )
                                                                                        : 0
                                                                                ).toFixed(2)
                                                                            ) > Number(dataNivel.verde)
                                                                                ? 3
                                                                                : Number(
                                                                                    Number(
                                                                                        tablaValoresData.pvp_iva_gm > 0
                                                                                            ? Number(
                                                                                                Number(
                                                                                                    (((tablaValoresData.pvp_iva_gm /
                                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                                        (Number(
                                                                                                            tablaValoresData.descuento_circular
                                                                                                        ) +
                                                                                                            Number(
                                                                                                                tablaValoresData.descuento_adicional
                                                                                                            ) +
                                                                                                            Number(
                                                                                                                tablaValoresData.nc_descuento_gm
                                                                                                            ) +
                                                                                                            Number(
                                                                                                                tablaValoresData.nc_descuento_proauto
                                                                                                            ) +
                                                                                                            Number(
                                                                                                                tablaValoresData.descuento_asesor
                                                                                                            ) +
                                                                                                            Number(
                                                                                                                tablaValoresData.regalos
                                                                                                            ) +
                                                                                                            Number(
                                                                                                                tablaValoresData.valor_transporte
                                                                                                            )) /
                                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) +
                                                                                                        Number(
                                                                                                            tablaValoresData.rebate_gm
                                                                                                        ) /
                                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1) -
                                                                                                        tablaValoresData.dealear_net) *
                                                                                                        ((data2.porcentajeIva === 0 ? 0 : 15) / 100 + 1)) /
                                                                                                        tablaValoresData.pvp_iva_gm) *
                                                                                                    100
                                                                                                ).toFixed(2)
                                                                                            )
                                                                                            : 0
                                                                                    ).toFixed(2)
                                                                                ) < Number(dataNivel.naranja)
                                                                                    ? 1
                                                                                    : 2, //nivel
                                                                            datosComplemento.prepagados, //descrip mantenimiento
                                                                            cheked.mantenimientos_incluye ? 1 : 0,
                                                                            data2.fechaCompra,
                                                                            tablaValoresData.valor_transporte,
                                                                            dataConCopiaNiv2.traCorreo,
                                                                            dataConCopiaNiv3.traCorreo,
                                                                            data2.vin,
                                                                            "",
                                                                            0,
                                                                            agencia,
                                                                            row.traTelefono,
                                                                            data.idLinea,
                                                                            0,
                                                                            data2.idCotItem,
                                                                            data.idZona
                                                                        )
                                                                    }}
                                                                >
                                                                    ✓
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Modal>

                                <Dialog
                                    open={openDialog}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Correcto"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Datos guardados correctamente
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            href={
                                                String(params.idcrm) != "0"
                                                    ? `/ventas/index/${params.idcrm}`
                                                    : `/ventas/index/${params.idcrm}`
                                            } //  "/listadotablarenta"
                                            onClick={handleCloseDialog}
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={openDialogReservaOk}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Correcto"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Reserva realizada correctamente!
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={(e) => setOpenDialogReservaOk(false)}
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={openDialogEstaReservado}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Vehículo reservado"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Este negocio tiene una reserva.
                                        </DialogContentText>
                                        <DialogContentText id="alert-dialog-description">
                                            <strong>Modelo:</strong> {sateliteReservarVh.srvModelo}
                                        </DialogContentText>
                                        <DialogContentText id="alert-dialog-description">
                                            <strong>VIN:</strong> {sateliteReservarVh.srvVin}
                                        </DialogContentText>
                                        <DialogContentText id="alert-dialog-description">
                                            <strong>Usuario:</strong>{" "}
                                            {sateliteReservarVh.srvCorreoReserva}
                                        </DialogContentText>
                                        <DialogContentText id="alert-dialog-description">
                                            <strong>Fecha:</strong>{" "}
                                            {sateliteReservarVh.srvFechaReserva
                                                ?.toString()
                                                .substring(0, 10)}
                                        </DialogContentText>
                                        <DialogContentText id="alert-dialog-description">
                                            <strong>Hora:</strong>{" "}
                                            {sateliteReservarVh.srvFechaReserva
                                                ?.toString()
                                                .substring(11, 16)}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={(e) => setOpenDialogEstaReservado(false)}>
                                            Cancelar
                                        </Button>
                                        <Button onClick={(e) => setOpenDialogAnularReserva(true)}>
                                            Anular reserva
                                        </Button>
                                        <Button
                                            onClick={(e) =>
                                                getModeloReservado(sateliteReservarVh.srvVin)
                                            }
                                        >
                                            Cargar VIN
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={openDialogAnularReserva}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Anular reserva"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            ¿Esta seguro de anular la reserva?
                                        </DialogContentText>
                                    </DialogContent>
                                    <TextField
                                        name="srvObservacionAnula"
                                        size="small"
                                        margin="normal"
                                        label="Motivo de anulación"
                                        // type="number"
                                        //value={sateliteReservarVh.srvObservacionAnula}
                                        onChange={dataReservaVh}
                                        sx={{ mt: 0.5, mb: 0.5, marginLeft: 2, width: 400, mr: 2 }}
                                    />
                                    <DialogActions>
                                        <Button onClick={(e) => setOpenDialogAnularReserva(false)}>
                                            No
                                        </Button>

                                        <Button
                                            disabled={
                                                String(sateliteReservarVh.srvObservacionAnula).length >
                                                    15
                                                    ? false
                                                    : true
                                            }
                                            onClick={(e) =>
                                                setReservaVh(
                                                    Number(sateliteReservarVh.srvId),
                                                    sateliteReservarVh.idCrm,
                                                    sateliteReservarVh.srvVin,
                                                    sateliteReservarVh.srvCorreoReserva,
                                                    sateliteReservarVh.srvFechaReserva,
                                                    sateliteReservarVh.srvObservacionReserva,
                                                    username,
                                                    new Date(),
                                                    String(sateliteReservarVh.srvObservacionAnula),
                                                    1,
                                                    sateliteReservarVh.srvModelo
                                                )
                                            }
                                        >
                                            Si
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={openDialogError}
                                    //onClose={handleCloseDialogError}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Advertencia"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Ingrese todos los datos.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={(e) => setOpenDialogError(false)} autoFocus>
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                {/* modal para correo autoriza */}

                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid
                    //container
                    alignItems={"center"}
                    justifyContent={"center"}
                //sx={{ minHeight: "90vh" }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            mb: 1.5,

                            width: "20%",
                            marginLeft: "40%",
                        }}
                        onClick={() => handleLogin("redirect")}
                    //href="/listadotablarenta"
                    >
                        Inicia sesión
                    </Button>
                </Grid>
            </Container>
        );
    }
};
