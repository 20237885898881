import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  bodegaType,
  SateliteTestDriveRuta,
  VSateliteTestDriveRuta,
} from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "@emotion/styled";



const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


export const RutasTestDrivePage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getVRutas();
      getBodegas();
    } else {
      setName("");
    }
  }, [account]);
  const [vrutas, setVRutas] = useState<VSateliteTestDriveRuta[]>([]);
  const [bodegas, setBodegas] = useState<bodegaType[]>([]);
  const [agencia, setAgencia] = React.useState(0);
  const [esNuevo, setEsNuevo] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sateliteRuta, setSateliteRuta] = useState<SateliteTestDriveRuta>({
    sruId: 0,
    idBodega: 0,
    //idZona: 0,
    // agencia: "",
    // zona: "",
    sruDescripcion: "",
    sruMetrosDistancia: 0,
    sruObservacion: "",
    sruMetrosDistanciaTolerancia: 0,
    sruMinutos: 0,
    sruMinutosTolerancia: 0,
        sruIdGrupoWpp: ""
  });

  const handleChangeRuta = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    setSateliteRuta({
      ...sateliteRuta,
      [e.target.name]: e.target.value,
    });

  };

  function seteoRuta(
    sruId: number,
    idBodega: number,
    sruDescripcion: string,
    sruMetrosDistancia: number,
    sruObservacion: string,
    sruMetrosDistanciaTolerancia: number,
    sruMinutos: number,
    sruMinutosTolerancia: number,
        sruIdGrupoWpp: string,
  ) {

    if (esNuevo) {
      const nuevoRegistro: SateliteTestDriveRuta = {
        idBodega: idBodega,
        sruDescripcion: sruDescripcion,
        sruMetrosDistancia: sruMetrosDistancia,
        sruObservacion: sruObservacion,
        sruMetrosDistanciaTolerancia: sruMetrosDistanciaTolerancia,
        sruMinutos: sruMinutos,
        sruMinutosTolerancia: sruMinutosTolerancia,
        sruIdGrupoWpp: sruIdGrupoWpp,
      };
      console.log(
        "ruta a guardar >> ",
        JSON.stringify(nuevoRegistro, null, 4)
      );
      postGuardarRegistro(nuevoRegistro);
    } else {
      const nuevoRegistro: SateliteTestDriveRuta = {
        sruId: sruId,
        idBodega: idBodega,
        sruDescripcion: sruDescripcion,
        sruMetrosDistancia: sruMetrosDistancia,
        sruObservacion: sruObservacion,
        sruMetrosDistanciaTolerancia: sruMetrosDistanciaTolerancia,
        sruMinutos: sruMinutos,
        sruMinutosTolerancia: sruMinutosTolerancia,
        sruIdGrupoWpp: sruIdGrupoWpp
      };
      console.log(
        "usuario a guardar >> ",
        JSON.stringify(nuevoRegistro, null, 4)
      );
      postGuardarRegistro(nuevoRegistro);
    }


  }

  async function postGuardarRegistro(registro: SateliteTestDriveRuta) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post("https://apisatelite.azurewebsites.net/testdriveruta", registro, {
          headers: headers,
        })
        .then((response) => {
          console.log("id guardado es >> ", response);
          getVRutas()

        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getRutaEdit(_id: number) {
    try {
      await axios
        .get<SateliteTestDriveRuta>(
          "https://apisatelite.azurewebsites.net/testdriveruta/buscar/" +
          String(_id)
        )
        .then((response) => {
          console.log("mi ruta seleccionada es >>", response.data);
          setSateliteRuta(response.data);
          setAgencia(response.data.idBodega);
          setOpenDialog(true);
          setEsNuevo(false);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getVRutas() {
    try {
      await axios
        .get<VSateliteTestDriveRuta[]>(
          "https://apisatelite.azurewebsites.net/vsatelitetestdriveruta/todos"
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setVRutas(response.data);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega"
        )
        .then((response) => {
          setBodegas(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "zona",
      headerName: "Zona",
      editable: false,
      width: 80,
    },
    {
      headerClassName: "theme-header",
      field: "agencia",
      headerName: "Agencia",
      editable: false,
      width: 175,
    },
    {
      headerClassName: "theme-header",
      field: "sruDescripcion",
      headerName: "Descripcion",
      editable: false,
      width: 225,
    },
    {
      headerClassName: "theme-header",
      field: "sruMetrosDistancia_metros",
      headerName: "Metros distancia",
      editable: false,
      width: 115,
      valueGetter: ({ row }) => {
        return String(Number(row.sruMetrosDistancia)) + " Metros";
      },
    },
    // {
    //   headerClassName: "theme-header",
    //   field: "sruMetrosDistancia_metros_tolerancia",
    //   headerName: "Metros tolerancia",
    //   editable: false,
    //   width: 125,
    //   valueGetter: ({ row }) => {
    //     return String(Number(row.sruMetrosDistanciaTolerancia)) + " Metros";
    //   },
    // },
    {
      headerClassName: "theme-header",
      field: "sruMetrosDistancia_km",
      headerName: "KM distancia",
      editable: false,
      width: 115,
      valueGetter: ({ row }) => {
        return String(Number(Number(row.sruMetrosDistancia) / 1000)) + " KM";
      },
    },
    // {
    //   headerClassName: "theme-header",
    //   field: "sruMetrosDistancia_km_tolerancia",
    //   headerName: "KM tolerancia",
    //   editable: false,
    //   width: 125,
    //   valueGetter: ({ row }) => {
    //     return String(Number(Number(row.sruMetrosDistanciaTolerancia) / 1000)) + " KM";
    //   },
    // },
    {
      headerClassName: "theme-header",
      field: "sruMinutos",
      headerName: "Minutos",
      editable: false,
      width: 115,
      valueGetter: ({ row }) => {
        return String(Number(row.sruMinutos)) + " minutos";
      },
    },
    // {
    //   headerClassName: "theme-header",
    //   field: "sruMinutosTolerancia",
    //   headerName: "Minutos tolerancia",
    //   editable: false,
    //   width: 125,
    //   valueGetter: ({ row }) => {
    //     return String(Number(row.sruMinutosTolerancia)) + " minutos";
    //   },
    // },
    {
      headerClassName: "theme-header",
      field: "sruObservacion",
      headerName: "Observaciones",
      editable: false,
      width: 200,
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            onClick={function (event) {
              getRutaEdit(params.row.sruId);
            }}
          >
            Abrir
          </Button>
        );
      },
    },
  ];

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Rutas de Test Drive
              </Typography>
            </Paper>

            {/* insertar validacion para mostrar */}
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >

              <>
                <Box
                  sx={{
                    "& .theme-header": {
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "rgba(255,255,255,1)",
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.sruId}
                    sx={{ height: 650 }}
                    editMode="row"
                    rows={vrutas}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbarQuickFilter }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={{
                      footerTotalRows: false,
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                      toolbarQuickFilterPlaceholder: "Buscar",
                    }}
                  />
                </Box>
                <Button variant="contained" sx={{ ml: "80%", width: "12%", mt: 2 }} onClick={(e) => {
                  setEsNuevo(true); setOpenDialog(true); setAgencia(0); setSateliteRuta({
                    ...sateliteRuta,
                    sruId: 0,
                    idBodega: 0,
                    sruDescripcion: "",
                    sruMetrosDistancia: 0,
                    sruObservacion: "",
                  });
                }}>Nuevo</Button>
              </>
            </Paper>

            {/* modal */}
            <Modal
              open={openDialog}
              onClose={function (event) {
                setOpenDialog(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                {/* Datos */}
                <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    width: "20%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Agencia:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  select
                  value={agencia}
                  onChange={(e) => setAgencia(Number(e.target.value))}
                  size="small"
                  margin="normal"
                  sx={{ mt: 1, mb: 1, width: "80%" }}
                >
                  {bodegas.map((option) => (
                    <MenuItem key={option.bodId} value={option.bodId}>
                      {option.agencia}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    width: "20%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Descripción:
                </Typography>
                <TextField
                  name="sruDescripcion"
                  size="small"
                  margin="normal"
                  value={sateliteRuta.sruDescripcion}
                  onChange={handleChangeRuta}
                  sx={{ mt: 1, mb: 1, width: "80%" }}
                />
                <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    width: "30%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Distancia en metros:
                </Typography>
                <TextField
                  name="sruMetrosDistancia"
                  size="small"
                  margin="normal"
                  value={sateliteRuta.sruMetrosDistancia}
                  onChange={handleChangeRuta}
                  sx={{ mt: 1, mb: 1, width: "15%" }}
                />
                <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    ml: "10%",
                    width: "30%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Tolerancia en metros:
                </Typography>
                <TextField
                  name="sruMetrosDistanciaTolerancia"
                  size="small"
                  margin="normal"
                  value={sateliteRuta.sruMetrosDistanciaTolerancia}
                  onChange={handleChangeRuta}
                  sx={{ mt: 1, mb: 1, width: "15%" }}
                />
                <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    width: "30%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Tiempo en minutos:
                </Typography>
                <TextField
                  name="sruMinutos"
                  size="small"
                  margin="normal"
                  value={sateliteRuta.sruMinutos}
                  onChange={handleChangeRuta}
                  sx={{ mt: 1, mb: 1, width: "15%" }}
                />
                <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    ml: "10%",
                    width: "30%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Tolerancia en minutos:
                </Typography>
                <TextField
                  name="sruMinutosTolerancia"
                  size="small"
                  margin="normal"
                  value={sateliteRuta.sruMinutosTolerancia}
                  onChange={handleChangeRuta}
                  sx={{ mt: 1, mb: 1, width: "15%" }}
                />
                  <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    width: "30%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  ID Grupo:
                </Typography>
                <TextField
                  name="sruIdGrupoWpp"
                  size="small"
                  margin="normal"
                  value={sateliteRuta.sruIdGrupoWpp}
                  onChange={handleChangeRuta}
                  sx={{ mt: 1, mb: 1, width: "70%" }}
                />
                 <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                    width: "20%"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Observación:
                </Typography>
                <TextField
                  name="sruObservacion"
                  size="small"
                  margin="normal"
                  label="Observaciones"
                  value={sateliteRuta.sruObservacion}
                  onChange={handleChangeRuta}
                  sx={{ mt: 1, mb: 1, width: "80%" }}
                />
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 1,
                    marginLeft: "40%",
                    width: "20%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  onClick={function (event) {
                    seteoRuta(Number(sateliteRuta.sruId), agencia, sateliteRuta.sruDescripcion, sateliteRuta.sruMetrosDistancia, sateliteRuta.sruObservacion, sateliteRuta.sruMetrosDistanciaTolerancia, sateliteRuta.sruMinutos, sateliteRuta.sruMinutosTolerancia, sateliteRuta.sruIdGrupoWpp)
                  }}
                >
                  Guardar
                </Button>

              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
